.root {
  display: inline-flex;
  flex-direction: column;
  position: relative;
  width: auto;
  height: auto;
  justify-content: center;
  align-items: center;
}
.dragHandle {
  width: auto;
  height: auto;
  display: flex;
  flex-direction: column;
}
.dragHandle > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: calc(0px - 0px);
  height: calc(100% + 0px);
}
.dragHandle > :global(.__wab_flex-container) > *,
.dragHandle > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.dragHandle > :global(.__wab_flex-container) > picture > img,
.dragHandle
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 0px;
}
.upButton {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  align-self: auto;
  min-width: 0;
  padding: 0px;
}
.upButtonshowUpDown {
  display: flex;
}
.root:hover .upButton {
  display: flex;
}
.up {
  object-fit: cover;
  color: #5978e8;
  width: 20px;
  height: 0.5em;
  display: block;
  flex-shrink: 0;
}
.handle {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 0px;
}
.img {
  object-fit: cover;
  max-width: 100%;
  width: 1.25em;
  height: 1em;
  display: block !important;
  transform: scaleX(1.4) scaleY(1.4) scaleZ(1);
  flex-shrink: 0;
}
.img > picture > img {
  object-fit: cover;
}
.downButton {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 0px;
}
.downButtonshowUpDown {
  display: flex;
}
.root:hover .downButton {
  display: flex;
}
.down {
  object-fit: cover;
  color: #5978e8;
  width: 20px;
  height: 0.5em;
  display: block;
  flex-shrink: 0;
}
