.plyr--audio .plyr__controls {
  border-radius: 20px;
}

/*
.sth .plyr--audio .plyr__controls{
  border-radius: 20px;
}*/

/* For Chrome */
.plyr__progress > input[type='range']::-webkit-slider-runnable-track,
.plyr__controls__item > input[type='range']::-webkit-slider-runnable-track {
  -webkit-appearance: none;
  color: var(--plyr-slider-track-color, #5978e8);
}

/* For firefox */
.plyr__progress > input[type='range']::-moz-range-track,
.plyr__controls__item > input[type='range']::-moz-range-track {
  background-color: var(--plyr-slider-track-color, #5978e8);
}
