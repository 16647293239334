.root {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 40px;
  height: 40px;
  transform: none;
  flex-shrink: 0;
  flex-grow: 0;
  padding: 0px;
  border-width: 1px;
  border-style: none;
}
.root > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.rootselected {
  padding-top: 0px;
  padding-bottom: 0px;
  width: 40px;
  height: 50px;
  display: flex;
  flex-direction: column;
  transform: none;
  border-width: 0px;
}
.rootselected > :global(.__wab_flex-container) {
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-left: calc(0px - 0px);
  width: calc(100% + 0px);
  margin-top: calc(0px - 4px);
  height: calc(100% + 4px);
}
.rootselected > :global(.__wab_flex-container) > *,
.rootselected > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.rootselected > :global(.__wab_flex-container) > picture > img,
.rootselected
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 0px;
  margin-top: 4px;
}
.img___40Pro {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 40px;
  height: 40px;
  flex-shrink: 0;
  display: none !important;
}
.img___40Pro > picture > img {
  object-fit: cover;
}
.imgselected___40PrOaaKyR {
  display: none !important;
}
.imgcolor_red___40PrOvoG0O {
  display: block !important;
}
.img__oArLh {
  object-fit: cover;
  width: 40px;
  height: 40px;
  flex-shrink: 0;
}
.img__oArLh > picture > img {
  object-fit: cover;
}
.imgselected__oArLHaaKyR {
  width: 40px;
  margin-top: calc(0px + 4px) !important;
  transform: scaleX(1.3) scaleY(1.3) scaleZ(1.3);
  transform-origin: 50% 0%;
  margin-left: calc(0px + 0px) !important;
  align-self: stretch;
  transform-style: preserve-3d;
  display: block !important;
}
.imgselected__oArLHaaKyR * {
  transform-style: preserve-3d;
}
.imgcolor_red__oArLHvoG0O {
  display: none !important;
}
.freeBox {
  position: relative;
  width: 20px;
  height: 3px;
  max-width: 100%;
  background: #2141b3;
  flex-shrink: 0;
  display: none;
  border-radius: 8px;
  padding: 0px;
}
.freeBoxselected {
  display: block;
}
