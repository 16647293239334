.root {
  display: inline-flex;
  flex-direction: column;
  position: relative;
  width: auto;
  height: auto;
}
.buttonDefault {
  filter: drop-shadow(0px 2px 3px rgba(0, 0, 0, 0.25))
    drop-shadow(0px 1px 10px rgba(63, 97, 220, 1));
  width: auto;
  height: 1.5em;
  display: flex;
  overflow: hidden;
  flex-direction: row;
  flex-shrink: 0;
  border-radius: 0.37em;
  padding: 0.37em 0.8em;
  border: 0.12em solid var(--token-kgwkYLWcU);
}
.buttonDefault > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: calc(0px - 10px);
  width: calc(100% + 10px);
}
.buttonDefault > :global(.__wab_flex-container) > *,
.buttonDefault > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.buttonDefault > :global(.__wab_flex-container) > picture > img,
.buttonDefault
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 10px;
}
.signUp {
  text-shadow: 0px 1px 25px rgba(63, 98, 220, 1),
    0px 0px 2px rgba(77, 109, 223, 1);
  display: block;
  font-size: 0.62em;
  text-align: right;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  letter-spacing: 0px;
  line-height: 1.25em;
  color: var(--token-pMUHtTtb1);
  position: relative;
  white-space: pre;
}
