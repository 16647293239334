.root {
  display: inline-flex;
  flex-direction: column;
  position: relative;
  width: auto;
  height: auto;
  justify-content: flex-start;
  align-items: center;
}
.container {
  background: linear-gradient(
      258deg,
      rgba(52, 48, 49, 1) 33%,
      rgba(42, 37, 38, 1) 78%
    )
    0% 0% / 100% 100% no-repeat;
  filter: drop-shadow(0px 2.773px 4.1605px rgba(0, 0, 0, 0.25));
  width: auto;
  height: auto;
  display: flex;
  overflow: hidden;
  flex-direction: row;
  max-height: 92px;
  border-radius: 8.321px;
  padding: 6.934px 8.321px 5.547px 12.482px;
}
.container > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: calc(0px - 4px);
  width: calc(100% + 4px);
}
.container > :global(.__wab_flex-container) > *,
.container > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.container > :global(.__wab_flex-container) > picture > img,
.container
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 4px;
}
.sources {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: 100%;
  max-width: 100%;
  overflow: hidden;
  min-width: 0;
  min-height: 0;
  padding: 4px;
}
.sources > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  flex-wrap: wrap;
  min-width: 0;
  min-height: 0;
  margin-left: calc(0px - 4px);
  width: calc(100% + 4px);
  margin-top: calc(0px - 4px);
  height: calc(100% + 4px);
}
.sources > :global(.__wab_flex-container) > *,
.sources > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.sources > :global(.__wab_flex-container) > picture > img,
.sources
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 4px;
  margin-top: 4px;
}
.source___90QqF:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.source__pXtRg:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.source__hcw9J:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.source___5YDVi:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.source__tpt7S:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.source__wb11G:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.source__syvra:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.source__isCg:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.source__oh0Dx:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.source__pfGba:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.source__gQlwa:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.source__qEsRv:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.source__aekbZ:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.source__oSajW:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.source__mrPh:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.source___0Xcg5:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.source__a8Ih:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.source__cCo18:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.arrow {
  display: block;
  position: relative;
  width: 26px;
  height: 29px;
  flex-shrink: 0;
}
.svg {
  position: absolute;
  object-fit: cover;
  max-width: 100%;
  color: #fff;
  transform: scale(1.000000499999875, 0.999999500000375)
    rotate(-179.94270423958343deg) skew(0.05729570311883569deg, 0deg);
  width: 26.351px;
  height: 29.125px;
  display: block;
  transform-origin: top left;
  left: 26px;
  top: 29px;
}
