.root {
  filter: drop-shadow(0px 2px 3px rgba(0, 0, 0, 0.25));
  transform: translate(0.2400000000000091px, 0.40500000000000114px);
  width: 2.75em;
  height: 2.75em;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-width: 0px;
  border-style: none;
}
.rootsmall {
  height: 0.75em;
  width: 0.9em;
  padding: 0px;
}
.rootlarge {
  width: 1.75em;
  height: 1.85em;
}
.rootmedium {
  width: 1.1em;
  height: 1.1em;
  padding: 0.125em;
}
.rootscale_small {
  transform: scaleX(0.36) scaleY(0.36) scaleZ(1);
}
.rootscale_medium {
  transform: scaleX(0.42) scaleY(0.42) scaleZ(1);
}
.rootscale_large {
  transform: scaleX(0.7) scaleY(0.72) scaleZ(1);
}
.buttonContent {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  max-width: 100%;
  top: auto;
  left: auto;
  bottom: auto;
  right: auto;
  z-index: 2;
  min-width: 0;
  min-height: 0;
  padding: 0px;
}
.svg__civLs {
  position: fixed;
  object-fit: cover;
  width: 100%;
  height: 100%;
  color: var(--token-yt3rDB0KD);
  display: block;
  top: 0;
  left: 0;
  z-index: 1;
  right: 0;
  bottom: 0;
  min-width: 0;
  min-height: 0;
}
.svgselected__civLsBIuB {
  color: var(--token-kgwkYLWcU);
  border-radius: 50%;
  border: 4px solid var(--token-zkLtEhfWu);
}
.svgcolor_logoBlue__civLssuPhd {
  color: var(--token-TO56GTcVY);
}
.svgcolor_eggshell__civLsdzMy7 {
  color: var(--token-kgwkYLWcU);
}
.svgcolor_red__civLsKLzAh {
  color: var(--token-6Pfaiv2lJ);
}
.svg__pJ9G {
  object-fit: cover;
  width: 100%;
  height: 100%;
  color: var(--token-kgwkYLWcU);
  display: block;
  max-width: 78%;
  max-height: 78%;
  z-index: 3;
  flex-grow: 0;
  position: relative;
  left: auto;
  top: auto;
  min-width: 0;
  min-height: 0;
}
.svgsmall__pJ9G0Znm {
  width: 0.4em;
  height: 0.4em;
  flex-grow: 1;
  flex-shrink: 0;
}
.svglarge__pJ9Gr328 {
  max-width: none;
  max-height: none;
}
.svgmedium__pJ9GJHq4B {
  left: auto;
  top: auto;
  position: relative;
  flex-grow: 1;
  width: 1em;
  height: 1em;
  flex-shrink: 0;
}
.svgcolor_eggshell__pJ9GdzMy7 {
  color: var(--token-V3GUThiKR);
}
.svg__duhK8 {
  position: relative;
  object-fit: cover;
  width: 12px;
  height: 9px;
  color: #2141b3;
  display: block;
  left: auto;
  top: auto;
  flex-shrink: 0;
}
.svgselected__duhK8BIuB {
  left: auto;
  top: auto;
  width: auto;
  height: 1em;
  z-index: 1;
  position: relative;
  right: auto;
  bottom: auto;
}
