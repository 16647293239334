.closeBtn {
  display: flex;
  width: 18px;
  height: 18px;
  max-width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 1.3px 6.5px 0px #0000004d;
  position: relative;
  background: #41414138;
  border-radius: 50%;
  padding: 0px;
  border: 0.1px none #444444;
}
.svg {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  left: auto;
  top: auto;
  color: #ffffff;
  width: 18px;
  height: 18px;
  z-index: 1;
  box-shadow: none;
  flex-shrink: 0;
  border-radius: 50%;
}
