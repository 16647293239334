.root {
  display: inline-flex;
  flex-direction: column;
  position: relative;
  width: auto;
  height: auto;
}
.attentionSelection {
  width: 9em;
  height: 1.5em;
  display: flex;
  flex-direction: row;
  padding-right: 0.13em;
  background: rgba(252, 250, 246, 1);
  flex-shrink: 0;
  border-radius: 0.38em;
}
.attentionSelection > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: calc(0px - 0.63em);
  width: calc(100% + 0.63em);
}
.attentionSelection > :global(.__wab_flex-container) > *,
.attentionSelection > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.attentionSelection > :global(.__wab_flex-container) > picture > img,
.attentionSelection
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 0.63em;
}
.attentionPriorityLevels {
  width: 7.3em;
  height: 1.25em;
  display: block;
  position: relative;
  align-self: flex-end;
  flex-shrink: 0;
}
.text__z1FFd {
  display: block;
  font-size: 0.06em;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  letter-spacing: 0px;
  color: rgba(87, 87, 87, 1);
  position: absolute;
  left: 8px;
  top: 0px;
  text-align: left;
}
.text__h39Cg {
  display: block;
  font-size: 0.06em;
  text-align: left;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  letter-spacing: 0px;
  color: rgba(87, 87, 87, 1);
  position: absolute;
  left: 33px;
  top: 0px;
}
.text__qvhLl {
  display: block;
  font-size: 0.06em;
  text-align: left;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  letter-spacing: 0px;
  color: rgba(87, 87, 87, 1);
  position: absolute;
  top: 0px;
  right: 5px;
}
.bars {
  transform: scale(1, -1)
    translate(-0.11100000000000065px, 0.23499999999999943px);
  width: auto;
  height: 0.25em;
  display: flex;
  transform-origin: top left;
  position: absolute;
  top: 15px;
  left: 8px;
  flex-direction: row;
}
.bars > :global(.__wab_flex-container) {
  flex-direction: row;
  margin-left: calc(0px - 3px);
  width: calc(100% + 3px);
}
.bars > :global(.__wab_flex-container) > *,
.bars > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.bars > :global(.__wab_flex-container) > picture > img,
.bars > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-left: 3px;
}
.grey:global(.__wab_instance) {
  position: relative;
  height: 1em;
  flex-shrink: 0;
}
.yellow:global(.__wab_instance) {
  position: relative;
  height: 1em;
  flex-shrink: 0;
}
.orange:global(.__wab_instance) {
  position: relative;
  height: 1em;
  flex-shrink: 0;
}
.red:global(.__wab_instance) {
  position: relative;
  height: 1em;
  flex-shrink: 0;
}
.purple:global(.__wab_instance) {
  position: relative;
  height: 1em;
  flex-shrink: 0;
}
.infoButton {
  position: relative;
  object-fit: cover;
  width: 0.5em;
  height: 0.5em;
  color: var(--token-P44QyWYsh);
  display: block;
  flex-shrink: 0;
}
.text__pSyEi {
  position: relative;
}
.textsaveButton__pSyEia2HFy {
  font-size: 8px;
  text-align: center;
  text-decoration-line: underline;
  color: var(--token-yt3rDB0KD);
  margin-top: 6px;
  align-self: center;
}
