.root {
  display: flex;
  flex-direction: row;
  position: relative;
  width: 100%;
  height: auto;
  justify-content: space-between;
  align-items: center;
  align-content: unset;
  min-width: 0;
}
.leftContainer {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  height: auto;
  width: auto;
  border-width: 0px;
  border-style: none;
}
.svg {
  object-fit: cover;
  width: 1.5em;
  height: 1.5em;
  flex-shrink: 1;
  flex-grow: 0;
}
.svgview_seeAllView {
  color: var(--token-kgwkYLWcU);
}
.svgview_videoView {
  color: var(--token-kgwkYLWcU);
}
.svgview_readerView {
  color: var(--token-kgwkYLWcU);
}
.headerMenu:global(.__wab_instance) {
  position: relative;
}
.headerMenuview_readerView:global(.__wab_instance) {
  display: none;
}
