.root {
  transform: translate(0.43600000000000705px, 0px);
  width: 20px;
  height: 4px;
  display: block;
  transform-origin: top left;
  position: relative;
}
.highlighter {
  box-shadow: 0px 1.46px 7.3px 0px rgba(0, 0, 0, 0.25),
    0px 0px 0px 1.2px rgba(33, 65, 179, 1);
  transform: scale(1.000000499999875, 0.999999500000375)
    rotate(-90.0572957604145deg) skew(-0.05729570311883569deg, 0deg)
    translate(0px, -0.4450000000000003px);
  width: 0.2em;
  height: 1.25em;
  display: block;
  transform-origin: top left;
  position: absolute;
  top: 4px;
  left: 0%;
  background: rgba(252, 250, 246, 1);
  border-radius: 3.555px;
}
.root:hover .highlighter {
  width: 1em;
}
.bar {
  box-shadow: inset 0px 0px 0px 0.699px rgba(252, 250, 246, 1);
  transform: scale(1.000000499999875, 0.999999500000375)
    rotate(-90.0572957604145deg) skew(-0.05729570311883569deg, 0deg)
    translate(0px, -0.4450000000000003px);
  width: 0.2em;
  height: 1.25em;
  display: block;
  transform-origin: top left;
  position: absolute;
  top: 4px;
  left: 0%;
  background: rgba(116, 0, 190, 1);
  border-radius: 3.555px;
}
.barcolors_red {
  background: var(--token-6Pfaiv2lJ);
}
.barcolors_orange {
  background: var(--token-shm-KHMSB);
}
.barcolors_yellow {
  background: var(--token-T2PG8Zppp);
}
.barcolors_dark {
  background: var(--token-P44QyWYsh);
}
.bardeselected {
  box-shadow: none;
}
.root:hover .bar {
  width: 1em;
}
.root:not(:active) .bar {
  box-shadow: none;
}
