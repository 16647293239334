.root {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: auto;
  justify-content: center;
  align-items: center;
  padding-right: 0.66em;
  padding-left: 0.66em;
  min-width: 0;
}
.darkActive {
  background: linear-gradient(261deg, #2b282a 23%, #221f20 81%) 0% 0% / 100%
    100% no-repeat;
  box-shadow: inset 0px 4px 4px 0px rgba(0, 0, 0, 0.219);
  filter: drop-shadow(0px 1px 2px rgba(255, 255, 255, 0.1));
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  min-width: 0;
  border-radius: 0.25em;
  padding: 0.2em 0.3em;
}
.darkActive > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - 0.3em);
  width: calc(100% + 0.3em);
}
.darkActive > :global(.__wab_flex-container) > *,
.darkActive > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.darkActive > :global(.__wab_flex-container) > picture > img,
.darkActive
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 0.3em;
}
.svg__li5Um {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  color: #585858;
  width: 9.73px;
  height: 8.922px;
  flex-shrink: 0;
  display: none;
}
.svgvariant_active__li5UmIuyoo {
  display: none;
}
.searchInput:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__opBoG {
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
  max-width: 80%;
}
.svg__i1Ck3 {
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
  max-width: 80%;
  margin: 2px 0px 0px;
}
.svg__xvzpU {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  color: #979797;
  width: 10.239px;
  height: 10.17px;
  flex-shrink: 0;
  display: none;
}
