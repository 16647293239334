.root {
  display: inline-flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: auto;
  height: auto;
  max-width: 100%;
  padding: 4px;
}
.svg {
  object-fit: cover;
  max-width: 100%;
  color: var(--token-pMUHtTtb1);
  height: 1em;
}
.root:hover .svg {
  transform: scaleX(1.2) scaleY(1.2) scaleZ(1);
}
