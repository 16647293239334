.outerContainer {
  border-radius: 1em;
  background: black;
  height: 90%;
  width: 80%;
  overflow-y: auto;
}

.container {
  display: flex;
  flex-direction: column;
  gap: 2.5em;
  align-items: center;
  justify-content: center;
  padding: 2.5em;
  border-radius: 1em;
  position: relative;
}

.interestTagContainer {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1em;
}

.interestTag {
  background-color: #585858;
  color: white;
  padding: 6px 10px;
  border-radius: 8px;
  font-size: 14px;
}

.interestTagIncluded {
  background-color: #2042b3;
}

.closeBtn {
  position: absolute;
  top: 1em;
  right: 1.5em;
  color: white;
}

.form {
  color: white;
  display: flex;
  flex-direction: column;
  gap: 1.5em;
  width: 100%;
  max-width: 400px;
  input {
    border: 1px solid white;
    border-radius: 4px;
    padding: 3px 6px;
    width: 100%;
    color: black; /* Add this line to change the text color */
    background: white; /* Optional: to ensure background is white */
  }

  input[type='checkbox'] {
    width: 20px;
  }
}


.inputContainer {
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
}

.inputContainerCheckbox {
  display: flex;
  gap: 12px;
  width: 100%;
  justify-content: space-between;
}

.submitBtn {
  background-color: whitesmoke;
  color: black;
  padding: 10px 16px;
  border-radius: 8px;
  font-weight: 600;
  &:disabled {
    background: #f5f5f554;
  }
}
