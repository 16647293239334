.root {
  display: inline-flex;
  flex-direction: column-reverse;
  position: relative;
  width: auto;
  height: auto;
  background: #353132b3;
  max-width: var(--token-a9MdTTayw);
  transform: translateX(0px) translateY(0px) translateZ(0px);
  box-shadow: 0px 4px 16px 0px #00000033;
  border-radius: 10px;
  padding: 8px 12px;
}
.root > :global(.__wab_flex-container) {
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
  margin-top: calc(0px - 5px);
  height: calc(100% + 5px);
}
.root > :global(.__wab_flex-container) > *,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.root > :global(.__wab_flex-container) > picture > img,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-top: 5px;
}
.freeBox {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  height: auto;
  max-width: 100%;
  margin-bottom: -6px;
  align-self: flex-end;
  min-width: 0;
  padding: 0px;
}
.closeButton {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: auto;
  height: auto;
  max-width: 100%;
  align-self: flex-end;
  padding: 0px;
}
.close {
  object-fit: cover;
  max-width: 100%;
  color: var(--token-kgwkYLWcU);
  height: 1em;
  flex-shrink: 0;
}
.deleteSectionButton {
  display: flex;
  position: relative;
  flex-direction: row;
  width: auto;
  height: auto;
  max-width: 100%;
  margin-top: calc(0px + 5px) !important;
  padding: 0px;
  border-width: 1px;
  border-style: none;
}
.deleteSectionButton > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: calc(0px - 4px);
  width: calc(100% + 4px);
}
.deleteSectionButton > :global(.__wab_flex-container) > *,
.deleteSectionButton
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > *,
.deleteSectionButton > :global(.__wab_flex-container) > picture > img,
.deleteSectionButton
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 4px;
}
.deleteSectionButtonhasAdminPrivilage {
  margin-top: calc(0px + 5px) !important;
}
.text__cYV {
  width: auto;
  height: auto;
  max-width: 800px;
  text-align: right;
  font-size: 0.75em;
  color: var(--token-6Pfaiv2lJ);
}
.texthasAdminPrivilage__cYVAXm5Q {
  font-size: 0.75em;
}
.svg {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  color: var(--token-6Pfaiv2lJ);
  height: 1em;
}
.group232 {
  filter: drop-shadow(0px 2px 3px rgba(255, 255, 255, 0.15));
  width: 197px;
  height: 41px;
  flex-shrink: 0;
  display: none;
}
.group267 {
  width: 108px;
  height: 41px;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;
}
.img___2C0Zh {
  position: absolute;
  object-fit: cover;
  max-width: 100%;
  filter: drop-shadow(0px 2px 3px rgba(0, 0, 0, 0.25));
  width: 108px;
  height: 41px;
  display: block !important;
  top: 0%;
  left: 0%;
}
.img___2C0Zh > picture > img {
  object-fit: cover;
}
.darkMode {
  position: absolute;
  top: 0%;
  left: 19.53%;
  width: 50%;
  height: 101.71%;
  transform: translate(-0.1670000000000016px, 0px);
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.darkMode2 {
  display: block;
  font-size: 10.076px;
  text-align: center;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  letter-spacing: 0px;
  line-height: 26.871px;
  color: rgba(250, 247, 238, 1);
}
.group266 {
  filter: drop-shadow(0px 2px 3px rgba(0, 0, 0, 0.25));
  transform: translate(0.3709999999999951px, 0px);
  width: 105px;
  height: 41px;
  display: block;
  position: absolute;
  top: 0px;
  left: 92px;
}
.img__kuOfo {
  position: absolute;
  object-fit: cover;
  max-width: 100%;
  width: 105px;
  height: 41px;
  display: block !important;
  top: 0%;
  left: 0%;
}
.img__kuOfo > picture > img {
  object-fit: cover;
}
.eggshellMode {
  position: absolute;
  top: 17.36%;
  left: 23.04%;
  width: 67.74%;
  height: 66.98%;
  transform: translate(0.19200000000000728px, -0.2820000000000107px);
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.text__junuW {
  display: block;
  font-size: 10.076px;
  text-align: center;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  letter-spacing: 0px;
  line-height: 26.871px;
  color: rgba(64, 60, 60, 1);
}
.divider {
  position: relative;
  width: 100%;
  height: 0.5px;
  max-width: 100%;
  background: #585151;
  min-width: 0;
  flex-shrink: 0;
  display: none;
  padding: 0px;
}
.dividerhasAdminPrivilage {
  display: block;
}
.addInterestModalBtn {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  font-size: 0.75em;
  color: var(--token-kgwkYLWcU);
  min-width: 0;
  display: none;
  border-width: 0px;
  border-style: none;
}
.addInterestModalBtnhasAdminPrivilage {
  display: block;
}
