@import 'components/global.scss';

.app {
  padding: $scale2;
  min-height: 100%;
  background: #231f20;

  @media (min-width: $mobile) {
    padding-left: $scale7 + $scale1;
  }

  &::before {
    content: '';
    position: fixed;
    inset: 0;
    background: url(../../../assets/DarkNoise.png) repeat;
    background-size: 50px auto;
    opacity: 0.7;
  }
}
