.root {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 19em;
  height: auto;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
  max-width: 100%;
  border-radius: 16px;
  border: 1px solid #303031;
}
.titleImageContainer {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: 14em;
  align-self: flex-end;
  min-width: 0;
  flex-shrink: 0;
}
.img {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 100%;
  margin-left: 0;
  margin-top: 0;
  opacity: 1;
  height: 100%;
}
.img > picture > img {
  object-fit: cover;
}
.textContainer {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  border-top-width: 1px;
  border-right-width: 0px;
  border-bottom-width: 0px;
  border-left-width: 0px;
  min-width: 0;
  padding: 8px;
  border-style: solid;
  border-color: #303031;
}
.textContainer > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - 3px);
  height: calc(100% + 3px);
}
.textContainer > :global(.__wab_flex-container) > *,
.textContainer > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.textContainer > :global(.__wab_flex-container) > picture > img,
.textContainer
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 3px;
}
.url {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  color: #71767b;
  font-size: 12px;
  white-space: pre;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-right: 25px;
  min-width: 0;
}
.titleContainer {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  display: flex;
  flex-direction: row;
  min-width: 0;
}
.slotTargetTitle {
  font-size: 15px;
  color: var(--token-kgwkYLWcU);
  white-space: pre;
}
.slotTargetTitle > :global(.__wab_text),
.slotTargetTitle > :global(.__wab_expr_html_text),
.slotTargetTitle > :global(.__wab_slot-string-wrapper),
.slotTargetTitle > :global(.__wab_slot) > :global(.__wab_text),
.slotTargetTitle > :global(.__wab_slot) > :global(.__wab_expr_html_text),
.slotTargetTitle > :global(.__wab_slot) > :global(.__wab_slot-string-wrapper),
.slotTargetTitle
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_text),
.slotTargetTitle
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_expr_html_text),
.slotTargetTitle
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot-string-wrapper),
.slotTargetTitle
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_text),
.slotTargetTitle
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_expr_html_text),
.slotTargetTitle
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot-string-wrapper) {
  text-overflow: ellipsis;
}
.slotTargetTitle > *,
.slotTargetTitle > :global(.__wab_slot) > *,
.slotTargetTitle > :global(.__wab_slot) > :global(.__wab_slot) > *,
.slotTargetTitle
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > *,
.slotTargetTitle > picture > img,
.slotTargetTitle > :global(.__wab_slot) > picture > img,
.slotTargetTitle > :global(.__wab_slot) > :global(.__wab_slot) > picture > img,
.slotTargetTitle
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > picture
  > img {
  overflow: hidden;
}
.descriptionContainer {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  align-self: center;
  display: flex;
  flex-direction: row;
  min-width: 0;
}
.slotTargetDescription {
  font-size: 12px;
  color: #71767b;
  white-space: pre-wrap;
  text-transform: none;
}
