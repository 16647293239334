.menuContainer {
  display: inline-flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: auto;
  height: auto;
  max-width: 100%;
  padding: 8px;
}
.menu {
  box-shadow: 0px 2px 6px 2px var(--token-Dl_IO0P9f);
  display: flex;
  flex-direction: column;
  width: auto;
  height: auto;
  justify-content: flex-start;
  align-items: center;
  background: #353132b3;
  backdrop-filter: blur(4px);
  overflow: hidden;
  -webkit-backdrop-filter: blur(4px);
  border-radius: 1em;
}
.menutype_websites {
  overflow: hidden;
}
.menuexpanded_websites {
  box-shadow: 0px 2px 6px 2px var(--token-Dl_IO0P9f);
  overflow: hidden;
  filter: blur(0px);
  background: #353132b3;
}
.menuexpanded_twitter {
  box-shadow: 0px 2px 6px 2px var(--token-Dl_IO0P9f);
  overflow: hidden;
  filter: blur(0px);
  background: #353132b3;
}
.menuexpanded_youtube {
  box-shadow: 0px 2px 6px 2px var(--token-Dl_IO0P9f);
  overflow: hidden;
  filter: blur(0px);
  background: #353132b3;
}
.menuexpanded_spotify {
  box-shadow: 0px 2px 6px 2px var(--token-Dl_IO0P9f);
  overflow: hidden;
  filter: blur(0px);
  background: #353132b3;
}
.menuhidden {
  display: none;
}
.showMoreButton:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.freeBox__nsHhs {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 1em;
  height: 1em;
  max-width: 1em;
  max-height: 1em;
  padding: 0px;
}
.icon3 {
  object-fit: cover;
  max-width: 100%;
  color: var(--token-kgwkYLWcU);
  width: 100%;
  position: relative;
  left: auto;
  top: auto;
  min-width: 0;
  height: 1em;
}
.expandedStack {
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: var(--token-V3GUThiKR);
  backdrop-filter: none;
  filter: blur(0px);
  min-width: 0;
  display: none;
  -webkit-backdrop-filter: none;
  padding: 0.5em 0.8em;
}
.expandedStack > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
}
.expandedStackexpanded_websites {
  display: flex;
}
.expandedStackexpanded_twitter {
  padding-top: 0.3em;
  padding-bottom: 0.3em;
  display: flex;
}
.expandedStackexpanded_twitter > :global(.__wab_flex-container) {
  margin-left: calc(0px - 0px);
  width: calc(100% + 0px);
  margin-top: calc(0px - 2px);
  height: calc(100% + 2px);
}
.expandedStackexpanded_twitter > :global(.__wab_flex-container) > *,
.expandedStackexpanded_twitter
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > *,
.expandedStackexpanded_twitter > :global(.__wab_flex-container) > picture > img,
.expandedStackexpanded_twitter
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 0px;
  margin-top: 2px;
}
.expandedStackexpanded_youtube {
  display: flex;
}
.expandedStackexpanded_spotify {
  flex-grow: 1;
  display: flex;
}
.freeBox__bspMe {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 16em;
  flex-wrap: wrap;
  min-width: 0;
  padding: 0px;
}
.freeBoxexpanded_youtube__bspMeCGgNx {
  padding-bottom: 4px;
}
.freeBoxexpanded_spotify__bspMeIn6M {
  padding-bottom: 4px;
  height: 100%;
  min-height: 0;
}
.titleContainer {
  width: 100%;
  height: 100%;
  max-height: 3em;
  display: flex;
  flex-direction: row;
  min-width: 0;
  min-height: 0;
}
.titleContainerexpanded_twitter {
  display: none;
}
.titleContainerexpanded_youtube {
  max-height: none;
}
.titleContainerexpanded_spotify {
  max-height: none;
}
.slotTargetTitle {
  font-size: 0.8em;
  color: var(--token-kgwkYLWcU);
  line-height: 1.6em;
  white-space: pre-wrap;
}
.author {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: 0.6em;
  color: var(--token-7YSTg8Ece);
  min-width: 0;
}
.authorexpanded_websites {
  display: none;
}
.authorexpanded_twitter {
  display: none;
}
.authorexpanded_youtube {
  display: none;
}
.authorexpanded_spotify {
  display: none;
}
.date {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: 0.4em;
  color: var(--token-7YSTg8Ece);
  padding-top: 2px;
  padding-bottom: 2px;
  min-width: 0;
}
.dateexpanded_websites {
  display: none;
}
.dateexpanded_twitter {
  display: none;
}
.dateexpanded_youtube {
  display: none;
}
.dateexpanded_spotify {
  display: none;
}
.freeBox__mcZ1U {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 0px;
}
.freeBox__mcZ1U > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  min-width: 0;
  margin-left: calc(0px - 10px);
  width: calc(100% + 10px);
}
.freeBox__mcZ1U > :global(.__wab_flex-container) > *,
.freeBox__mcZ1U > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__mcZ1U > :global(.__wab_flex-container) > picture > img,
.freeBox__mcZ1U
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 10px;
}
.freeBox__gmscV {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
.freeBox__gmscV > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
}
.freeBoxexpanded_websites__gmscVJcYhO > :global(.__wab_flex-container) {
  margin-left: calc(0px - 0px);
  width: calc(100% + 0px);
  margin-top: calc(0px - 2px);
  height: calc(100% + 2px);
}
.freeBoxexpanded_websites__gmscVJcYhO > :global(.__wab_flex-container) > *,
.freeBoxexpanded_websites__gmscVJcYhO
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > *,
.freeBoxexpanded_websites__gmscVJcYhO
  > :global(.__wab_flex-container)
  > picture
  > img,
.freeBoxexpanded_websites__gmscVJcYhO
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 0px;
  margin-top: 2px;
}
.freeBoxexpanded_twitter__gmscVpLf1K {
  padding: 0px;
}
.freeBoxexpanded_twitter__gmscVpLf1K > :global(.__wab_flex-container) {
  justify-content: space-between;
  align-items: center;
  margin-left: calc(0px - 0px);
  width: calc(100% + 0px);
  margin-top: calc(0px - 4px);
  height: calc(100% + 4px);
}
.freeBoxexpanded_twitter__gmscVpLf1K > :global(.__wab_flex-container) > *,
.freeBoxexpanded_twitter__gmscVpLf1K
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > *,
.freeBoxexpanded_twitter__gmscVpLf1K
  > :global(.__wab_flex-container)
  > picture
  > img,
.freeBoxexpanded_twitter__gmscVpLf1K
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 0px;
  margin-top: 4px;
}
.freeBox__lGw3B {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 0px;
}
.freeBox__lGw3B > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
  min-width: 0;
}
.freeBoxexpanded_youtube__lGw3BcGgNx > :global(.__wab_flex-container) {
  align-items: flex-end;
  justify-content: flex-start;
  margin-left: calc(0px - 16px);
  width: calc(100% + 16px);
  margin-top: calc(0px - 0px);
  height: calc(100% + 0px);
}
.freeBoxexpanded_youtube__lGw3BcGgNx > :global(.__wab_flex-container) > *,
.freeBoxexpanded_youtube__lGw3BcGgNx
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > *,
.freeBoxexpanded_youtube__lGw3BcGgNx
  > :global(.__wab_flex-container)
  > picture
  > img,
.freeBoxexpanded_youtube__lGw3BcGgNx
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 16px;
  margin-top: 0px;
}
.freeBoxexpanded_spotify__lGw3BIn6M > :global(.__wab_flex-container) {
  margin-left: calc(0px - 16px);
  width: calc(100% + 16px);
  margin-top: calc(0px - 0px);
  height: calc(100% + 0px);
}
.freeBoxexpanded_spotify__lGw3BIn6M > :global(.__wab_flex-container) > *,
.freeBoxexpanded_spotify__lGw3BIn6M
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > *,
.freeBoxexpanded_spotify__lGw3BIn6M
  > :global(.__wab_flex-container)
  > picture
  > img,
.freeBoxexpanded_spotify__lGw3BIn6M
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 16px;
  margin-top: 0px;
}
.text__nUwD {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: 0.4em;
  color: var(--token-7YSTg8Ece);
  padding-top: 2px;
  min-width: 0;
}
.textexpanded_youtube__nUwDCGgNx {
  width: auto;
}
.textexpanded_spotify__nUwDIn6M {
  width: auto;
}
.publishedDate {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: 0.4em;
  color: var(--token-7YSTg8Ece);
  padding-top: 2px;
  min-width: 0;
}
.publishedDateexpanded_websites {
  font-size: 0.5em;
  color: var(--token-kgwkYLWcU);
  font-weight: 700;
}
.publishedDateexpanded_twitter {
  font-weight: 700;
  color: var(--token-kgwkYLWcU);
}
.publishedDateexpanded_youtube {
  font-size: 0.5em;
  font-weight: 700;
  color: var(--token-kgwkYLWcU);
}
.publishedDateexpanded_spotify {
  font-size: 0.5em;
  font-weight: 700;
  color: var(--token-kgwkYLWcU);
}
.freeBox___0JPcX {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 0px;
}
.freeBoxexpanded_twitter___0JPcXpLf1K {
  display: none;
}
.freeBoxexpanded_youtube___0JPcXcGgNx {
  display: none;
}
.freeBoxexpanded_spotify___0JPcXIn6M {
  display: none;
}
.by {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: 0.4em;
  color: var(--token-7YSTg8Ece);
  padding-top: 2px;
  min-width: 0;
}
.byexpanded_twitter {
  height: auto;
  display: none;
}
.byexpanded_youtube {
  align-self: auto;
}
.byexpanded_spotify {
  align-self: auto;
}
.openOriginalLinkButtonForWebsites {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
.openOriginalLinkButtonForWebsitesexpanded_websites {
  align-self: auto;
  padding: 0px;
}
.menuOption__x3TnV:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.menuOptionexpanded_websites__x3TnVJcYhO:global(.__wab_instance) {
  display: none;
}
.menuOptionexpanded_twitter__x3TnVpLf1K:global(.__wab_instance) {
  display: none;
}
.menuOptionexpanded_youtube__x3TnVcGgNx:global(.__wab_instance) {
  display: none;
}
.menuOptionexpanded_spotify__x3TnVIn6M:global(.__wab_instance) {
  display: none;
}
.freeBox__buPbA {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 1em;
  height: 1em;
  max-width: 1em;
  max-height: 1em;
  padding: 0px;
}
.icon4 {
  object-fit: cover;
  max-width: 100%;
  color: var(--token-kgwkYLWcU);
  width: 100%;
  position: relative;
  left: auto;
  top: auto;
  min-width: 0;
  height: 1em;
}
.smallPlayButton:global(.__wab_instance) {
  position: relative;
  width: auto;
  height: auto;
}
.smallPlayButtonexpanded_twitter:global(.__wab_instance) {
  margin-right: -0.5em;
}
.freeBox__gYdDm {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 0px;
}
.text__ux1Uq {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  font-size: 0.5em;
}
.textexpanded_websites__ux1UqJcYhO {
  display: none;
}
.textexpanded_twitter__ux1UqPLf1K {
  display: none;
}
.textexpanded_youtube__ux1UqCGgNx {
  display: none;
}
.textexpanded_spotify__ux1UqIn6M {
  display: none;
}
.source:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.sourceexpanded_twitter:global(.__wab_instance) {
  align-self: flex-end;
}
.freeBox__xnoRg {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
.freeBox__xnoRg > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
}
.freeBoxexpanded_websites__xnoRgJcYhO {
  padding: 3px;
}
.freeBoxexpanded_websites__xnoRgJcYhO > :global(.__wab_flex-container) {
  margin-left: calc(0px - 0px);
  width: calc(100% + 0px);
  margin-top: calc(0px - 6px);
  height: calc(100% + 6px);
}
.freeBoxexpanded_websites__xnoRgJcYhO > :global(.__wab_flex-container) > *,
.freeBoxexpanded_websites__xnoRgJcYhO
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > *,
.freeBoxexpanded_websites__xnoRgJcYhO
  > :global(.__wab_flex-container)
  > picture
  > img,
.freeBoxexpanded_websites__xnoRgJcYhO
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 0px;
  margin-top: 6px;
}
.freeBoxexpanded_twitter__xnoRgPLf1K {
  padding: 3px;
}
.freeBoxexpanded_twitter__xnoRgPLf1K > :global(.__wab_flex-container) {
  margin-left: calc(0px - 0px);
  width: calc(100% + 0px);
  margin-top: calc(0px - 6px);
  height: calc(100% + 6px);
}
.freeBoxexpanded_twitter__xnoRgPLf1K > :global(.__wab_flex-container) > *,
.freeBoxexpanded_twitter__xnoRgPLf1K
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > *,
.freeBoxexpanded_twitter__xnoRgPLf1K
  > :global(.__wab_flex-container)
  > picture
  > img,
.freeBoxexpanded_twitter__xnoRgPLf1K
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 0px;
  margin-top: 6px;
}
.freeBoxexpanded_youtube__xnoRgCGgNx {
  padding: 3px;
}
.freeBoxexpanded_youtube__xnoRgCGgNx > :global(.__wab_flex-container) {
  margin-left: calc(0px - 0px);
  width: calc(100% + 0px);
  margin-top: calc(0px - 6px);
  height: calc(100% + 6px);
}
.freeBoxexpanded_youtube__xnoRgCGgNx > :global(.__wab_flex-container) > *,
.freeBoxexpanded_youtube__xnoRgCGgNx
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > *,
.freeBoxexpanded_youtube__xnoRgCGgNx
  > :global(.__wab_flex-container)
  > picture
  > img,
.freeBoxexpanded_youtube__xnoRgCGgNx
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 0px;
  margin-top: 6px;
}
.freeBoxexpanded_spotify__xnoRgIn6M {
  padding: 3px;
}
.freeBoxexpanded_spotify__xnoRgIn6M > :global(.__wab_flex-container) {
  margin-left: calc(0px - 0px);
  width: calc(100% + 0px);
  margin-top: calc(0px - 6px);
  height: calc(100% + 6px);
}
.freeBoxexpanded_spotify__xnoRgIn6M > :global(.__wab_flex-container) > *,
.freeBoxexpanded_spotify__xnoRgIn6M
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > *,
.freeBoxexpanded_spotify__xnoRgIn6M
  > :global(.__wab_flex-container)
  > picture
  > img,
.freeBoxexpanded_spotify__xnoRgIn6M
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 0px;
  margin-top: 6px;
}
.openOriginalLinkButton:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.openOriginalLinkButtonexpanded_websites:global(.__wab_instance) {
  display: none;
}
.freeBox__oSlMc {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 1em;
  height: 1em;
  max-width: 1em;
  max-height: 1em;
  padding: 0px;
}
.icon {
  object-fit: cover;
  max-width: 100%;
  color: var(--token-kgwkYLWcU);
  width: 100%;
  position: relative;
  left: auto;
  top: auto;
  min-width: 0;
  height: 1em;
}
.iconexpanded_websites {
  color: var(--token-pMUHtTtb1);
  transform: scaleX(0.9) scaleY(0.9) scaleZ(1);
}
.iconexpanded_twitter {
  transform: scaleX(1.1) scaleY(1.1) scaleZ(1);
  color: var(--token-QkOxHiuRM);
}
.iconexpanded_youtube {
  color: var(--token-6Pfaiv2lJ);
}
.iconexpanded_spotify {
  color: var(--token-eXgRgh_S6);
}
.menuOption__hOgSk:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.freeBox__yhOHd {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 1em;
  height: 1em;
  max-width: 1em;
  max-height: 1em;
  padding: 0px;
}
.icon11 {
  object-fit: cover;
  max-width: 100%;
  color: var(--token-kgwkYLWcU);
  width: 100%;
  position: relative;
  left: auto;
  top: auto;
  min-width: 0;
  height: 1em;
}
.icon11expanded_websites {
  transform: scaleX(1.1) scaleY(1.1) scaleZ(1);
}
.icon11expanded_twitter {
  transform: scaleX(1.1) scaleY(1.1) scaleZ(1);
}
.icon11expanded_youtube {
  transform: scaleX(1.1) scaleY(1.1) scaleZ(1);
}
.icon11expanded_spotify {
  transform: scaleX(1.1) scaleY(1.1) scaleZ(1);
}
.textexpanded_websites__bkmkLJcYhO {
  padding-left: 0px;
}
.readAloudButton:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.readAloudButtontype_twitter:global(.__wab_instance) {
  display: none;
}
.readAloudButtontype_youtube:global(.__wab_instance) {
  display: none;
}
.readAloudButtontype_spotify:global(.__wab_instance) {
  display: none;
}
.readAloudButtonexpanded_youtube:global(.__wab_instance) {
  display: none;
}
.readAloudButtonexpanded_spotify:global(.__wab_instance) {
  display: none;
}
.icon5 {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  color: var(--token-kgwkYLWcU);
  transform: scaleX(1.1) scaleY(1.1) scaleZ(1);
  height: 1em;
}
.playNextButton:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.playNextButtontype_twitter:global(.__wab_instance) {
  display: none;
}
.playNextButtonexpanded_twitter:global(.__wab_instance) {
  display: none;
}
.freeBox__jRRt {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 1em;
  height: 1em;
  max-width: 1em;
  max-height: 1em;
  padding: 0px;
}
.icon6 {
  object-fit: cover;
  max-width: 100%;
  color: var(--token-IG9ne-3Mr);
  width: 100%;
  position: relative;
  left: auto;
  top: auto;
  transform: scaleX(0.94) scaleY(0.94) scaleZ(1);
  filter: unset;
  min-width: 0;
  height: 1em;
}
.playLaterButton:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.playLaterButtontype_twitter:global(.__wab_instance) {
  display: none;
}
.playLaterButtonexpanded_twitter:global(.__wab_instance) {
  display: none;
}
.freeBox__ouWhi {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 1em;
  height: 1em;
  max-width: 1em;
  max-height: 1em;
  padding: 0px;
}
.icon7 {
  object-fit: cover;
  max-width: 100%;
  color: var(--token-kgwkYLWcU);
  width: 100%;
  position: relative;
  left: auto;
  top: auto;
  transform: none;
  filter: unset;
  min-width: 0;
  height: 1em;
}
.saveForLaterButton:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.icon8 {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  color: var(--token-kgwkYLWcU);
  transform: scaleX(0.9) scaleY(0.9) scaleZ(1);
  height: 1em;
}
.archiveButton:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.archiveButtonhideArchiveButton:global(.__wab_instance) {
  display: none;
}
.icon9 {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  color: var(--token-kgwkYLWcU);
  transform: scaleX(0.9) scaleY(0.9) scaleZ(1);
  height: 1em;
}
.removeButton:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.textexpanded_websites__jpGuBJcYhO {
  filter: none;
  backdrop-filter: blur(0px);
  -webkit-backdrop-filter: blur(0px);
}
.textexpanded_twitter__jpGuBpLf1K {
  filter: none;
  backdrop-filter: blur(0px);
  -webkit-backdrop-filter: blur(0px);
}
.textexpanded_youtube__jpGuBcGgNx {
  filter: none;
  backdrop-filter: blur(0px);
  -webkit-backdrop-filter: blur(0px);
}
.textexpanded_spotify__jpGuBIn6M {
  filter: none;
  backdrop-filter: blur(0px);
  -webkit-backdrop-filter: blur(0px);
}
.svg__gvI9 {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  color: var(--token-kgwkYLWcU);
  transform: scaleX(1.1) scaleY(1.1) scaleZ(1);
  height: 1em;
}
