.interestTilePanel {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  position: relative;
  min-width: 0;
  padding: 0px 10px;
}
.arrowLeftContainer {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 16px;
  height: auto;
  max-width: 100%;
  align-self: center;
  margin-right: 0px;
  flex-shrink: 0;
  padding: 0px;
}
.interestArrowLeft {
  object-fit: cover;
  max-width: 100%;
  width: 1em;
  color: var(--token-kgwkYLWcU);
  flex-shrink: 0;
  height: 1em;
}
.interestStack {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  align-self: flex-start;
  transition-property: all, background;
  transition-duration: 1s, 1s;
  overflow: hidden;
  min-width: 0;
  -webkit-transition-property: all, background;
  -webkit-transition-duration: 1s, 1s;
  padding: 4px 0px 0px;
}
.interestStack > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  flex-wrap: wrap;
  min-width: 0;
  margin-top: calc(0px - 0.25em);
  height: calc(100% + 0.25em);
}
.interestStack > :global(.__wab_flex-container) > *,
.interestStack > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.interestStack > :global(.__wab_flex-container) > picture > img,
.interestStack
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 0.25em;
}
.arrowRightContainer {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 16px;
  height: auto;
  max-width: 100%;
  align-self: center;
  margin-right: 0px;
  flex-shrink: 0;
  padding: 0px;
}
.interestArrowRight {
  object-fit: cover;
  max-width: 100%;
  width: 14px;
  color: var(--token-kgwkYLWcU);
  flex-shrink: 0;
  height: 1em;
}
