@import url("https://fonts.googleapis.com/css2?family=Open+Sans%3Aital%2Cwght%400%2C300%3B0%2C400%3B0%2C500%3B0%2C600%3B0%2C700%3B0%2C800%3B1%2C300%3B1%2C400%3B1%2C500%3B1%2C600%3B1%2C700%3B1%2C800&family=Inter%3Aital%2Cwght%400%2C300%3B0%2C400%3B0%2C500%3B0%2C600%3B0%2C700%3B0%2C900&family=Inconsolata%3Aital%2Cwght%400%2C300%3B0%2C400%3B0%2C500%3B0%2C600%3B0%2C700%3B0%2C900&family=Source+Sans+Pro%3Aital%2Cwght%400%2C300%3B0%2C400%3B0%2C600%3B0%2C700%3B0%2C900%3B1%2C300%3B1%2C400%3B1%2C600%3B1%2C700%3B1%2C900&family=Lora%3Aital%2Cwght%400%2C400%3B0%2C500%3B0%2C600%3B0%2C700%3B1%2C400%3B1%2C500%3B1%2C600%3B1%2C700&family=Rubik%3Aital%2Cwght%400%2C300%3B0%2C400%3B0%2C500%3B0%2C600%3B0%2C700%3B0%2C900%3B1%2C300%3B1%2C400%3B1%2C500%3B1%2C600%3B1%2C700%3B1%2C900&family=Oldenburg%3Aital%2Cwght%400%2C400&family=Literata%3Aital%2Cwght%400%2C300%3B0%2C400%3B0%2C500%3B0%2C600%3B0%2C700%3B0%2C900%3B1%2C300%3B1%2C400%3B1%2C500%3B1%2C600%3B1%2C700%3B1%2C900&family=Poppins%3Aital%2Cwght%400%2C300%3B0%2C400%3B0%2C500%3B0%2C600%3B0%2C700%3B0%2C900%3B1%2C300%3B1%2C400%3B1%2C500%3B1%2C600%3B1%2C700%3B1%2C900&family=Karla%3Aital%2Cwght%400%2C300%3B0%2C400%3B0%2C500%3B0%2C600%3B0%2C700%3B0%2C800%3B1%2C300%3B1%2C400%3B1%2C500%3B1%2C600%3B1%2C700%3B1%2C800&display=swap");

.plasmic_tokens {
  --token-kgwkYLWcU: #fcfaf6;
  --plasmic-token-eggshell: var(--token-kgwkYLWcU);
  --token-yt3rDB0KD: #2141b3;
  --plasmic-token-mountain-blue: var(--token-yt3rDB0KD);
  --token-WkUIkx5-J: #535353;
  --plasmic-token-dark-grey: var(--token-WkUIkx5-J);
  --token-zkLtEhfWu: #465377;
  --plasmic-token-background-blue: var(--token-zkLtEhfWu);
  --token-Dl_IO0P9f: #00000033;
  --plasmic-token-button-shadow: var(--token-Dl_IO0P9f);
  --token-6Pfaiv2lJ: #d6455b;
  --plasmic-token-red: var(--token-6Pfaiv2lJ);
  --token-pMUHtTtb1: #5978e8;
  --plasmic-token-logo-blue: var(--token-pMUHtTtb1);
  --token-P44QyWYsh: #353132;
  --plasmic-token-dark: var(--token-P44QyWYsh);
  --token-7YSTg8Ece: var(--token-B8u26uCIS);
  --plasmic-token-medium-grey: var(--token-7YSTg8Ece);
  --token-O7e_v4mKD: #7500bf;
  --plasmic-token-purple: var(--token-O7e_v4mKD);
  --token-shm-KHMSB: #eb8442;
  --plasmic-token-orange: var(--token-shm-KHMSB);
  --token-T2PG8Zppp: #f9c43c;
  --plasmic-token-yellow: var(--token-T2PG8Zppp);
  --token-FmckP9B6G: #707070;
  --plasmic-token-707070: var(--token-FmckP9B6G);
  --token-V3GUThiKR: #231f20;
  --plasmic-token-dark-background: var(--token-V3GUThiKR);
  --token-0Kei2aO9v: #3f3c3d;
  --plasmic-token-dark-l: var(--token-0Kei2aO9v);
  --token-IG9ne-3Mr: #fdfdfc;
  --plasmic-token-almost-white: var(--token-IG9ne-3Mr);
  --token-9YopChMg6: #413d3d;
  --plasmic-token-darker-grey: var(--token-9YopChMg6);
  --token-moETPK9D_: #a2a1a7;
  --plasmic-token-body-grey: var(--token-moETPK9D_);
  --token-QkOxHiuRM: #2280cc;
  --plasmic-token-twitter-blue: var(--token-QkOxHiuRM);
  --token-NbV8yISxh: #626262;
  --plasmic-token-626262: var(--token-NbV8yISxh);
  --token-B8u26uCIS: #bebebe;
  --plasmic-token-section-header-text: var(--token-B8u26uCIS);
  --token-TO56GTcVY: #4163dd;
  --plasmic-token-landing-page-blue: var(--token-TO56GTcVY);
  --token-U0sHWprB5: #ffffff;
  --plasmic-token-unnamed-style-token-2: var(--token-U0sHWprB5);
  --token-eXgRgh_S6: #1dd35e;
  --plasmic-token-spotify-green: var(--token-eXgRgh_S6);
  --token-ptkV0FmyR: #e60023;
  --plasmic-token-pinterest-red: var(--token-ptkV0FmyR);
  --token-LoWUr1Jcm: #f1815e;
  --plasmic-token-instagram: var(--token-LoWUr1Jcm);
  --token-a9MdTTayw: 300px;
  --plasmic-token-dropdown: var(--token-a9MdTTayw);
  --token-60J6bTxrK: 1px;
  --plasmic-token-unnamed-style-token: var(--token-60J6bTxrK);
  --token-cjuSSwg_PYBQ: #ffffff;
  --plasmic-token-dark-linear: var(--token-cjuSSwg_PYBQ);
}

.plasmic_tokens:where(.global_theme_dark) {
  --token-pMUHtTtb1: #5978e8;
  --plasmic-token-logo-blue: var(--token-pMUHtTtb1);
  --token-P44QyWYsh: #353132;
  --plasmic-token-dark: var(--token-P44QyWYsh);
  --token-7YSTg8Ece: #c4c4c4;
  --plasmic-token-medium-grey: var(--token-7YSTg8Ece);
  --token-0Kei2aO9v: #343031;
  --plasmic-token-dark-l: var(--token-0Kei2aO9v);
  --token-moETPK9D_: #a2a1a7;
  --plasmic-token-body-grey: var(--token-moETPK9D_);
  --token-B8u26uCIS: #bebebe;
  --plasmic-token-section-header-text: var(--token-B8u26uCIS);
}

.plasmic_tokens {
  --plsmc-standard-width: 800px;
  --plsmc-wide-width: 1280px;
  --plsmc-viewport-gap: 16px;
  --plsmc-wide-chunk: calc(
    ((var(--plsmc-wide-width) - var(--plsmc-standard-width)) / 2) -
      var(--plsmc-viewport-gap)
  );
}

.plasmic_default_styles,
.plasmic_default_styles:where(.global_theme_dark),
.plasmic_default_styles:where(.global_theme_light),
.plasmic_default_styles:where(.global_theme_light2),
.plasmic_default_styles:where(.global_segment_variation),
.plasmic_default_styles:where(.global_experiment_variation),
.plasmic_default_styles:where(.global_schedule_variation),
.plasmic_default_styles:where(.global_segment2_variation),
.plasmic_default_styles:where(.global_segment3_override) {
  --mixin-Ilmtqws9nv6H_font-family: "Open Sans", sans-serif;
  --mixin-Ilmtqws9nv6H_font-size: 16px;
  --mixin-Ilmtqws9nv6H_font-weight: 400;
  --mixin-Ilmtqws9nv6H_font-style: normal;
  --mixin-Ilmtqws9nv6H_color: #535353;
  --mixin-Ilmtqws9nv6H_text-align: left;
  --mixin-Ilmtqws9nv6H_text-transform: none;
  --mixin-Ilmtqws9nv6H_line-height: 1.5;
  --mixin-Ilmtqws9nv6H_letter-spacing: normal;
  --mixin-Ilmtqws9nv6H_white-space: pre-wrap;
  --mixin-Ilmtqws9nv6H_user-select: text;
  --mixin-Ilmtqws9nv6H_text-decoration-line: none;
  --mixin-Ilmtqws9nv6H_text-overflow: clip;
  --mixin-K3INbtq1M_font-family: "Inter", sans-serif;
  --mixin-K3INbtq1M_color: #000000;
  --mixin-K3INbtq1M_font-size: 72px;
  --mixin-K3INbtq1M_font-weight: 900;
  --mixin-K3INbtq1M_letter-spacing: -4px;
  --mixin-K3INbtq1M_line-height: 1;
  --mixin-K3INbtq1M_white-space: pre-wrap;
  --mixin-2VaTUoPo7S_font-family: "Inter", sans-serif;
  --mixin-2VaTUoPo7S_color: #000000;
  --mixin-2VaTUoPo7S_font-size: 48px;
  --mixin-2VaTUoPo7S_font-weight: 700;
  --mixin-2VaTUoPo7S_letter-spacing: -1px;
  --mixin-2VaTUoPo7S_line-height: 1.1;
  --mixin-2VaTUoPo7S_white-space: pre-wrap;
  --mixin-fq2okTq9i2_font-family: "Inter", sans-serif;
  --mixin-fq2okTq9i2_color: #000000;
  --mixin-fq2okTq9i2_font-size: 32px;
  --mixin-fq2okTq9i2_font-weight: 600;
  --mixin-fq2okTq9i2_letter-spacing: -0.8px;
  --mixin-fq2okTq9i2_line-height: 1.2;
  --mixin-fq2okTq9i2_white-space: pre-wrap;
  --mixin-f3qa2v0NdZ_font-family: "Inter", sans-serif;
  --mixin-f3qa2v0NdZ_color: #000000;
  --mixin-f3qa2v0NdZ_font-size: 24px;
  --mixin-f3qa2v0NdZ_font-weight: 600;
  --mixin-f3qa2v0NdZ_letter-spacing: -0.5px;
  --mixin-f3qa2v0NdZ_line-height: 1.3;
  --mixin-f3qa2v0NdZ_white-space: pre-wrap;
  --mixin-ndRwZZkKQC_font-family: "Inter", sans-serif;
  --mixin-ndRwZZkKQC_color: #000000;
  --mixin-ndRwZZkKQC_font-size: 20px;
  --mixin-ndRwZZkKQC_font-weight: 600;
  --mixin-ndRwZZkKQC_letter-spacing: -0.3px;
  --mixin-ndRwZZkKQC_line-height: 1.5;
  --mixin-ndRwZZkKQC_white-space: pre-wrap;
  --mixin-Y2xpalwQpE_font-family: "Inter", sans-serif;
  --mixin-Y2xpalwQpE_color: #000000;
  --mixin-Y2xpalwQpE_font-size: 16px;
  --mixin-Y2xpalwQpE_font-weight: 600;
  --mixin-Y2xpalwQpE_line-height: 1.5;
  --mixin-Y2xpalwQpE_white-space: pre-wrap;
  --mixin-3f1PXqMMJi_color: #0070f3;
  --mixin-3f1PXqMMJi_white-space: pre-wrap;
  --mixin-kmxd3hJYRr_color: #3291ff;
  --mixin-kmxd3hJYRr_white-space: pre-wrap;
  --mixin-x2yQ-MNsgG_border-left-color: #dddddd;
  --mixin-x2yQ-MNsgG_border-left-style: solid;
  --mixin-x2yQ-MNsgG_border-left-width: 3px;
  --mixin-x2yQ-MNsgG_color: #888888;
  --mixin-x2yQ-MNsgG_padding-left: 10px;
  --mixin-x2yQ-MNsgG_white-space: pre-wrap;
  --mixin-H31s8NkQ1j_background: linear-gradient(#f8f8f8, #f8f8f8);
  --mixin-H31s8NkQ1j_border-bottom-color: #dddddd;
  --mixin-H31s8NkQ1j_border-bottom-style: solid;
  --mixin-H31s8NkQ1j_border-bottom-width: 1px;
  --mixin-H31s8NkQ1j_border-left-color: #dddddd;
  --mixin-H31s8NkQ1j_border-left-style: solid;
  --mixin-H31s8NkQ1j_border-left-width: 1px;
  --mixin-H31s8NkQ1j_border-right-color: #dddddd;
  --mixin-H31s8NkQ1j_border-right-style: solid;
  --mixin-H31s8NkQ1j_border-right-width: 1px;
  --mixin-H31s8NkQ1j_border-top-color: #dddddd;
  --mixin-H31s8NkQ1j_border-top-style: solid;
  --mixin-H31s8NkQ1j_border-top-width: 1px;
  --mixin-H31s8NkQ1j_border-bottom-left-radius: 3px;
  --mixin-H31s8NkQ1j_border-bottom-right-radius: 3px;
  --mixin-H31s8NkQ1j_border-top-left-radius: 3px;
  --mixin-H31s8NkQ1j_border-top-right-radius: 3px;
  --mixin-H31s8NkQ1j_font-family: "Inconsolata";
  --mixin-H31s8NkQ1j_padding-bottom: 1px;
  --mixin-H31s8NkQ1j_padding-left: 4px;
  --mixin-H31s8NkQ1j_padding-right: 4px;
  --mixin-H31s8NkQ1j_padding-top: 1px;
  --mixin-H31s8NkQ1j_white-space: pre-wrap;
  --mixin-SYO62_7uDi_background: linear-gradient(#f8f8f8, #f8f8f8);
  --mixin-SYO62_7uDi_border-bottom-color: #dddddd;
  --mixin-SYO62_7uDi_border-bottom-style: solid;
  --mixin-SYO62_7uDi_border-bottom-width: 1px;
  --mixin-SYO62_7uDi_border-left-color: #dddddd;
  --mixin-SYO62_7uDi_border-left-style: solid;
  --mixin-SYO62_7uDi_border-left-width: 1px;
  --mixin-SYO62_7uDi_border-right-color: #dddddd;
  --mixin-SYO62_7uDi_border-right-style: solid;
  --mixin-SYO62_7uDi_border-right-width: 1px;
  --mixin-SYO62_7uDi_border-top-color: #dddddd;
  --mixin-SYO62_7uDi_border-top-style: solid;
  --mixin-SYO62_7uDi_border-top-width: 1px;
  --mixin-SYO62_7uDi_border-bottom-left-radius: 3px;
  --mixin-SYO62_7uDi_border-bottom-right-radius: 3px;
  --mixin-SYO62_7uDi_border-top-left-radius: 3px;
  --mixin-SYO62_7uDi_border-top-right-radius: 3px;
  --mixin-SYO62_7uDi_font-family: "Inconsolata";
  --mixin-SYO62_7uDi_padding-bottom: 3px;
  --mixin-SYO62_7uDi_padding-left: 6px;
  --mixin-SYO62_7uDi_padding-right: 6px;
  --mixin-SYO62_7uDi_padding-top: 3px;
  --mixin-SYO62_7uDi_white-space: pre-wrap;
  --mixin-Jaom7dq7Ue_display: flex;
  --mixin-Jaom7dq7Ue_flex-direction: column;
  --mixin-Jaom7dq7Ue_align-items: stretch;
  --mixin-Jaom7dq7Ue_justify-content: flex-start;
  --mixin-Jaom7dq7Ue_list-style-position: outside;
  --mixin-Jaom7dq7Ue_padding-left: 40px;
  --mixin-Jaom7dq7Ue_position: relative;
  --mixin-Jaom7dq7Ue_list-style-type: decimal;
  --mixin-Jaom7dq7Ue_white-space: pre-wrap;
  --mixin-M1-2pAozMH_display: flex;
  --mixin-M1-2pAozMH_flex-direction: column;
  --mixin-M1-2pAozMH_align-items: stretch;
  --mixin-M1-2pAozMH_justify-content: flex-start;
  --mixin-M1-2pAozMH_list-style-position: outside;
  --mixin-M1-2pAozMH_padding-left: 40px;
  --mixin-M1-2pAozMH_position: relative;
  --mixin-M1-2pAozMH_list-style-type: disc;
  --mixin-M1-2pAozMH_white-space: pre-wrap;
}

.plasmic_mixins,
.plasmic_mixins:where(.global_theme_dark),
.plasmic_mixins:where(.global_theme_light),
.plasmic_mixins:where(.global_theme_light2),
.plasmic_mixins:where(.global_segment_variation),
.plasmic_mixins:where(.global_experiment_variation),
.plasmic_mixins:where(.global_schedule_variation),
.plasmic_mixins:where(.global_segment2_variation),
.plasmic_mixins:where(.global_segment3_override) {
  --mixin-fsFF5kTLM_box-shadow: 0px 2px 6px 2px var(--token-Dl_IO0P9f);
  --plasmic-mixin-button-shadow_box-shadow: var(--mixin-fsFF5kTLM_box-shadow);
  --mixin-fsFF5kTLM_white-space: pre-wrap;
  --plasmic-mixin-button-shadow_white-space: var(--mixin-fsFF5kTLM_white-space);
  --mixin-H84bfjwLB_box-shadow: 0px 2px 7px 0px rgba(255, 255, 255, 0.1);
  --plasmic-mixin-shadow_box-shadow: var(--mixin-H84bfjwLB_box-shadow);
  --mixin-H84bfjwLB_white-space: pre-wrap;
  --plasmic-mixin-shadow_white-space: var(--mixin-H84bfjwLB_white-space);
  --mixin-F8junUq2e_white-space: pre-wrap;
  --plasmic-mixin-dark-linear-old_white-space: var(
    --mixin-F8junUq2e_white-space
  );
  --mixin-T2WvnZCwF_white-space: pre-wrap;
  --plasmic-mixin-dark-bg_white-space: var(--mixin-T2WvnZCwF_white-space);
  --mixin-bJKUwTIV4_white-space: pre-wrap;
  --plasmic-mixin-dark-linear-new_white-space: var(
    --mixin-bJKUwTIV4_white-space
  );
  --mixin-cAXDBqYvm_white-space: pre-wrap;
  --plasmic-mixin-lp-dark-bg_white-space: var(--mixin-cAXDBqYvm_white-space);
  --mixin-ReGtjdM6R_white-space: pre-wrap;
  --plasmic-mixin-plur_white-space: var(--mixin-ReGtjdM6R_white-space);
  --mixin-Akp5tzCqF_white-space: pre-wrap;
  --plasmic-mixin-light-blue-text-shadow_white-space: var(
    --mixin-Akp5tzCqF_white-space
  );
  --mixin-SI6S6_Mid_white-space: pre-wrap;
  --plasmic-mixin-purple-glow_white-space: var(--mixin-SI6S6_Mid_white-space);
  --mixin-v2cZbhsK2mzy_white-space: pre-wrap;
  --plasmic-mixin-dark-linear_white-space: var(
    --mixin-v2cZbhsK2mzy_white-space
  );
}

:where(.all) {
  display: block;
  white-space: inherit;
  grid-row: auto;
  grid-column: auto;
  position: relative;
  background: none;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  box-shadow: none;
  box-sizing: border-box;
  text-decoration-line: none;
  margin: 0;
  border-width: 0px;
}
:where(.__wab_expr_html_text *) {
  white-space: inherit;
  grid-row: auto;
  grid-column: auto;
  background: none;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  box-shadow: none;
  box-sizing: border-box;
  text-decoration-line: none;
  margin: 0;
  border-width: 0px;
}

:where(.img) {
  display: inline-block;
}
:where(.__wab_expr_html_text img) {
  white-space: inherit;
}

:where(.li) {
  display: list-item;
}
:where(.__wab_expr_html_text li) {
  white-space: inherit;
}

:where(.span) {
  display: inline;
  position: static;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
}
:where(.__wab_expr_html_text span) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
}

:where(.input) {
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  background-image: linear-gradient(#ffffff, #ffffff);
  padding: 2px;
  border: 1px solid lightgray;
}
:where(.__wab_expr_html_text input) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  background-image: linear-gradient(#ffffff, #ffffff);
  padding: 2px;
  border: 1px solid lightgray;
}

:where(.textarea) {
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  padding: 2px;
  border: 1px solid lightgray;
}
:where(.__wab_expr_html_text textarea) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  padding: 2px;
  border: 1px solid lightgray;
}

:where(.button) {
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  background-image: none;
  align-items: flex-start;
  text-align: center;
  padding: 2px 6px;
  border: 1px solid lightgray;
}
:where(.__wab_expr_html_text button) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  background-image: none;
  align-items: flex-start;
  text-align: center;
  padding: 2px 6px;
  border: 1px solid lightgray;
}

:where(.code) {
  font-family: inherit;
  line-height: inherit;
}
:where(.__wab_expr_html_text code) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
}

:where(.pre) {
  font-family: inherit;
  line-height: inherit;
}
:where(.__wab_expr_html_text pre) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
}

:where(.p) {
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
}
:where(.__wab_expr_html_text p) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
}

:where(.h1) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.__wab_expr_html_text h1) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.h2) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.__wab_expr_html_text h2) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.h3) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.__wab_expr_html_text h3) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.h4) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.__wab_expr_html_text h4) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.h5) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.__wab_expr_html_text h5) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.h6) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.__wab_expr_html_text h6) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.address) {
  font-style: inherit;
}
:where(.__wab_expr_html_text address) {
  white-space: inherit;
  font-style: inherit;
}

:where(.a) {
  color: inherit;
}
:where(.__wab_expr_html_text a) {
  white-space: inherit;
  color: inherit;
}

:where(.ol) {
  list-style-type: none;
  padding: 0;
}
:where(.__wab_expr_html_text ol) {
  white-space: inherit;
  list-style-type: none;
  padding: 0;
}

:where(.ul) {
  list-style-type: none;
  padding: 0;
}
:where(.__wab_expr_html_text ul) {
  white-space: inherit;
  list-style-type: none;
  padding: 0;
}

:where(.select) {
  padding: 2px 6px;
}
:where(.__wab_expr_html_text select) {
  white-space: inherit;
  padding: 2px 6px;
}

.plasmic_default__component_wrapper {
  display: grid;
}
.plasmic_default__inline {
  display: inline;
}
.plasmic_page_wrapper {
  display: flex;
  width: 100%;
  min-height: 100vh;
  align-items: stretch;
  align-self: start;
}
.plasmic_page_wrapper > * {
  height: auto !important;
}
.__wab_expr_html_text {
  white-space: normal;
}
:where(.root_reset) {
  font-family: var(--mixin-Ilmtqws9nv6H_font-family);
  font-size: var(--mixin-Ilmtqws9nv6H_font-size);
  font-weight: var(--mixin-Ilmtqws9nv6H_font-weight);
  font-style: var(--mixin-Ilmtqws9nv6H_font-style);
  color: var(--mixin-Ilmtqws9nv6H_color);
  text-align: var(--mixin-Ilmtqws9nv6H_text-align);
  text-transform: var(--mixin-Ilmtqws9nv6H_text-transform);
  line-height: var(--mixin-Ilmtqws9nv6H_line-height);
  letter-spacing: var(--mixin-Ilmtqws9nv6H_letter-spacing);
  white-space: var(--mixin-Ilmtqws9nv6H_white-space);
}

:where(.root_reset) h1:where(.h1),
h1:where(.root_reset.h1),
:where(.root_reset .__wab_expr_html_text) h1,
:where(.root_reset_tags) h1,
h1:where(.root_reset_tags) {
  font-family: var(--mixin-K3INbtq1M_font-family);
  color: var(--mixin-K3INbtq1M_color);
  font-size: var(--mixin-K3INbtq1M_font-size);
  font-weight: var(--mixin-K3INbtq1M_font-weight);
  letter-spacing: var(--mixin-K3INbtq1M_letter-spacing);
  line-height: var(--mixin-K3INbtq1M_line-height);
}

:where(.root_reset) h2:where(.h2),
h2:where(.root_reset.h2),
:where(.root_reset .__wab_expr_html_text) h2,
:where(.root_reset_tags) h2,
h2:where(.root_reset_tags) {
  font-family: var(--mixin-2VaTUoPo7S_font-family);
  color: var(--mixin-2VaTUoPo7S_color);
  font-size: var(--mixin-2VaTUoPo7S_font-size);
  font-weight: var(--mixin-2VaTUoPo7S_font-weight);
  letter-spacing: var(--mixin-2VaTUoPo7S_letter-spacing);
  line-height: var(--mixin-2VaTUoPo7S_line-height);
}

:where(.root_reset) h3:where(.h3),
h3:where(.root_reset.h3),
:where(.root_reset .__wab_expr_html_text) h3,
:where(.root_reset_tags) h3,
h3:where(.root_reset_tags) {
  font-family: var(--mixin-fq2okTq9i2_font-family);
  color: var(--mixin-fq2okTq9i2_color);
  font-size: var(--mixin-fq2okTq9i2_font-size);
  font-weight: var(--mixin-fq2okTq9i2_font-weight);
  letter-spacing: var(--mixin-fq2okTq9i2_letter-spacing);
  line-height: var(--mixin-fq2okTq9i2_line-height);
}

:where(.root_reset) h4:where(.h4),
h4:where(.root_reset.h4),
:where(.root_reset .__wab_expr_html_text) h4,
:where(.root_reset_tags) h4,
h4:where(.root_reset_tags) {
  font-family: var(--mixin-f3qa2v0NdZ_font-family);
  color: var(--mixin-f3qa2v0NdZ_color);
  font-size: var(--mixin-f3qa2v0NdZ_font-size);
  font-weight: var(--mixin-f3qa2v0NdZ_font-weight);
  letter-spacing: var(--mixin-f3qa2v0NdZ_letter-spacing);
  line-height: var(--mixin-f3qa2v0NdZ_line-height);
}

:where(.root_reset) h5:where(.h5),
h5:where(.root_reset.h5),
:where(.root_reset .__wab_expr_html_text) h5,
:where(.root_reset_tags) h5,
h5:where(.root_reset_tags) {
  font-family: var(--mixin-ndRwZZkKQC_font-family);
  color: var(--mixin-ndRwZZkKQC_color);
  font-size: var(--mixin-ndRwZZkKQC_font-size);
  font-weight: var(--mixin-ndRwZZkKQC_font-weight);
  letter-spacing: var(--mixin-ndRwZZkKQC_letter-spacing);
  line-height: var(--mixin-ndRwZZkKQC_line-height);
}

:where(.root_reset) h6:where(.h6),
h6:where(.root_reset.h6),
:where(.root_reset .__wab_expr_html_text) h6,
:where(.root_reset_tags) h6,
h6:where(.root_reset_tags) {
  font-family: var(--mixin-Y2xpalwQpE_font-family);
  color: var(--mixin-Y2xpalwQpE_color);
  font-size: var(--mixin-Y2xpalwQpE_font-size);
  font-weight: var(--mixin-Y2xpalwQpE_font-weight);
  line-height: var(--mixin-Y2xpalwQpE_line-height);
}

:where(.root_reset) a:where(.a),
a:where(.root_reset.a),
:where(.root_reset .__wab_expr_html_text) a,
:where(.root_reset_tags) a,
a:where(.root_reset_tags) {
  color: var(--mixin-3f1PXqMMJi_color);
}

:where(.root_reset) a:where(.a):hover,
a:where(.root_reset.a):hover,
:where(.root_reset .__wab_expr_html_text) a:hover,
:where(.root_reset_tags) a:hover,
a:where(.root_reset_tags):hover {
  color: var(--mixin-kmxd3hJYRr_color);
}

:where(.root_reset) blockquote:where(.blockquote),
blockquote:where(.root_reset.blockquote),
:where(.root_reset .__wab_expr_html_text) blockquote,
:where(.root_reset_tags) blockquote,
blockquote:where(.root_reset_tags) {
  color: var(--mixin-x2yQ-MNsgG_color);
  padding-left: var(--mixin-x2yQ-MNsgG_padding-left);
  border-left: var(--mixin-x2yQ-MNsgG_border-left-width)
    var(--mixin-x2yQ-MNsgG_border-left-style)
    var(--mixin-x2yQ-MNsgG_border-left-color);
}

:where(.root_reset) code:where(.code),
code:where(.root_reset.code),
:where(.root_reset .__wab_expr_html_text) code,
:where(.root_reset_tags) code,
code:where(.root_reset_tags) {
  background: #f8f8f8;
  font-family: var(--mixin-H31s8NkQ1j_font-family);
  border-radius: var(--mixin-H31s8NkQ1j_border-top-left-radius)
    var(--mixin-H31s8NkQ1j_border-top-right-radius)
    var(--mixin-H31s8NkQ1j_border-bottom-right-radius)
    var(--mixin-H31s8NkQ1j_border-bottom-left-radius);
  padding: var(--mixin-H31s8NkQ1j_padding-top)
    var(--mixin-H31s8NkQ1j_padding-right) var(--mixin-H31s8NkQ1j_padding-bottom)
    var(--mixin-H31s8NkQ1j_padding-left);
  border-top: var(--mixin-H31s8NkQ1j_border-top-width)
    var(--mixin-H31s8NkQ1j_border-top-style)
    var(--mixin-H31s8NkQ1j_border-top-color);
  border-right: var(--mixin-H31s8NkQ1j_border-right-width)
    var(--mixin-H31s8NkQ1j_border-right-style)
    var(--mixin-H31s8NkQ1j_border-right-color);
  border-bottom: var(--mixin-H31s8NkQ1j_border-bottom-width)
    var(--mixin-H31s8NkQ1j_border-bottom-style)
    var(--mixin-H31s8NkQ1j_border-bottom-color);
  border-left: var(--mixin-H31s8NkQ1j_border-left-width)
    var(--mixin-H31s8NkQ1j_border-left-style)
    var(--mixin-H31s8NkQ1j_border-left-color);
}

:where(.root_reset) pre:where(.pre),
pre:where(.root_reset.pre),
:where(.root_reset .__wab_expr_html_text) pre,
:where(.root_reset_tags) pre,
pre:where(.root_reset_tags) {
  background: #f8f8f8;
  font-family: var(--mixin-SYO62_7uDi_font-family);
  border-radius: var(--mixin-SYO62_7uDi_border-top-left-radius)
    var(--mixin-SYO62_7uDi_border-top-right-radius)
    var(--mixin-SYO62_7uDi_border-bottom-right-radius)
    var(--mixin-SYO62_7uDi_border-bottom-left-radius);
  padding: var(--mixin-SYO62_7uDi_padding-top)
    var(--mixin-SYO62_7uDi_padding-right) var(--mixin-SYO62_7uDi_padding-bottom)
    var(--mixin-SYO62_7uDi_padding-left);
  border-top: var(--mixin-SYO62_7uDi_border-top-width)
    var(--mixin-SYO62_7uDi_border-top-style)
    var(--mixin-SYO62_7uDi_border-top-color);
  border-right: var(--mixin-SYO62_7uDi_border-right-width)
    var(--mixin-SYO62_7uDi_border-right-style)
    var(--mixin-SYO62_7uDi_border-right-color);
  border-bottom: var(--mixin-SYO62_7uDi_border-bottom-width)
    var(--mixin-SYO62_7uDi_border-bottom-style)
    var(--mixin-SYO62_7uDi_border-bottom-color);
  border-left: var(--mixin-SYO62_7uDi_border-left-width)
    var(--mixin-SYO62_7uDi_border-left-style)
    var(--mixin-SYO62_7uDi_border-left-color);
}

:where(.root_reset) ol:where(.ol),
ol:where(.root_reset.ol),
:where(.root_reset .__wab_expr_html_text) ol,
:where(.root_reset_tags) ol,
ol:where(.root_reset_tags) {
  display: var(--mixin-Jaom7dq7Ue_display);
  flex-direction: var(--mixin-Jaom7dq7Ue_flex-direction);
  align-items: var(--mixin-Jaom7dq7Ue_align-items);
  justify-content: var(--mixin-Jaom7dq7Ue_justify-content);
  list-style-position: var(--mixin-Jaom7dq7Ue_list-style-position);
  padding-left: var(--mixin-Jaom7dq7Ue_padding-left);
  position: var(--mixin-Jaom7dq7Ue_position);
  list-style-type: var(--mixin-Jaom7dq7Ue_list-style-type);
  flex-column-gap: var(--mixin-Jaom7dq7Ue_flex-column-gap);
}

:where(.root_reset) ul:where(.ul),
ul:where(.root_reset.ul),
:where(.root_reset .__wab_expr_html_text) ul,
:where(.root_reset_tags) ul,
ul:where(.root_reset_tags) {
  display: var(--mixin-M1-2pAozMH_display);
  flex-direction: var(--mixin-M1-2pAozMH_flex-direction);
  align-items: var(--mixin-M1-2pAozMH_align-items);
  justify-content: var(--mixin-M1-2pAozMH_justify-content);
  list-style-position: var(--mixin-M1-2pAozMH_list-style-position);
  padding-left: var(--mixin-M1-2pAozMH_padding-left);
  position: var(--mixin-M1-2pAozMH_position);
  list-style-type: var(--mixin-M1-2pAozMH_list-style-type);
  flex-column-gap: var(--mixin-M1-2pAozMH_flex-column-gap);
}
