.root {
  display: inline-block;
  font-size: 0.8em;
  text-align: right;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 600;
  letter-spacing: 0px;
  color: var(--token-yt3rDB0KD);
  transform: translate(-0.3269999999999982px, 0px);
  width: auto;
  position: relative;
}
.rootglobal_theme_dark {
  filter: drop-shadow(0px 0px 6px #4062dd);
  color: var(--token-pMUHtTtb1);
  box-shadow: none;
}
.rootvariant__default {
  width: auto;
  display: inline-block;
}
.rootvariant_editMode {
  height: auto;
  width: auto;
  color: var(--token-zkLtEhfWu);
  display: inline-block;
}
.rootvariant_sectionSettings {
  height: auto;
  color: var(--token-6Pfaiv2lJ);
}
.rootglobal_theme_dark_variant_sectionSettings {
  filter: drop-shadow(0px 0px 6px #d6455bb3);
  height: auto;
  line-height: 1.25em;
}
.rootvariant_editingTitle {
  height: auto;
  color: var(--token-6Pfaiv2lJ);
}
.rootglobal_theme_dark_variant_editingTitle {
  filter: drop-shadow(0px 0px 6px #d6455bb3);
  height: auto;
  line-height: 1.25em;
}
.rootvariant_creating {
  color: var(--token-6Pfaiv2lJ);
}
.rootglow_blue {
  width: auto;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 0.8em;
  font-weight: 600;
  color: var(--token-pMUHtTtb1);
  text-align: right;
  letter-spacing: 0px;
  box-shadow: none;
  backdrop-filter: blur(0px);
  filter: drop-shadow(0px 0px 6px #4062dd);
  display: inline-block;
  -webkit-backdrop-filter: blur(0px);
}
.rootglow_red {
  width: auto;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 0.8em;
  font-weight: 600;
  color: var(--token-6Pfaiv2lJ);
  text-align: right;
  letter-spacing: 0px;
  box-shadow: none;
  backdrop-filter: none;
  filter: drop-shadow(0px 0px 6px #dc333399);
  display: inline-block;
  -webkit-backdrop-filter: none;
}
