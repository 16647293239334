.root {
  display: inline-flex;
  flex-direction: column;
  position: relative;
  width: auto;
  height: auto;
  align-content: unset;
  align-items: stretch;
  flex-shrink: 1;
}
.articleContent {
  height: auto;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  background: rgba(252, 252, 251, 1);
  align-self: auto;
  width: 20.75em;
  border-radius: 10px;
  border: 1px solid #c4c4c433;
}
.articleContent > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: unset;
  justify-content: space-between;
  align-content: center;
  flex-wrap: wrap;
  margin-top: calc(0px - 16px);
  height: calc(100% + 16px);
}
.articleContent > :global(.__wab_flex-container) > *,
.articleContent > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.articleContent > :global(.__wab_flex-container) > picture > img,
.articleContent
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 16px;
}
.articleContentglobal_theme_dark {
  background: linear-gradient(193deg, var(--token-0Kei2aO9v) 117%, #2a2526 232%)
    0% 0% / 100% 100% no-repeat padding-box border-box scroll;
  border-width: 0.2px;
  border-color: #4d4d4d;
}
.articleContentcomposition_full {
  width: 20.75em;
}
.articleContentcomposition_hideImage {
  padding-top: 10px;
}
.articleContentcomposition_hideSnippet > :global(.__wab_flex-container) {
  margin-left: calc(0px - 0px);
  width: calc(100% + 0px);
  margin-top: calc(0px - 10px);
  height: calc(100% + 10px);
}
.articleContentcomposition_hideSnippet > :global(.__wab_flex-container) > *,
.articleContentcomposition_hideSnippet
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > *,
.articleContentcomposition_hideSnippet
  > :global(.__wab_flex-container)
  > picture
  > img,
.articleContentcomposition_hideSnippet
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 0px;
  margin-top: 10px;
}
.root:hover .articleContent {
  transform: scaleX(1.03) scaleY(1.03) scaleZ(1);
}
.freeBox__nKzNe {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.freeBox__nKzNe > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - 4px);
  height: calc(100% + 4px);
}
.freeBox__nKzNe > :global(.__wab_flex-container) > *,
.freeBox__nKzNe > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__nKzNe > :global(.__wab_flex-container) > picture > img,
.freeBox__nKzNe
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 4px;
}
.freeBoxcomposition_titleOnly__nKzNeYnz76 {
  margin-top: calc(10px + 16px) !important;
}
.freeBox__vnck5 {
  display: flex;
  flex-direction: column;
  height: auto;
  overflow: hidden;
  min-height: 2em;
  max-height: 4em;
  width: 19.44em;
  padding-top: 0.5em;
  padding-left: 0.2em;
}
.freeBoxcomposition_hideSnippet__vnck5TAz0Z {
  padding-top: 4px;
}
.freeBoxcomposition_titleOnly__vnck5Ynz76 {
  width: 310px;
}
.titleContainer {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  display: flex;
  flex-direction: row;
  max-height: 3.31em;
  min-width: 0;
}
.titleContainercomposition_full {
  height: 3px;
  flex-shrink: 0;
}
.slotTargetTitle {
  font-size: 1.1em;
  font-weight: 600;
}
.slotTargetTitleglobal_theme_dark {
  color: var(--token-kgwkYLWcU);
}
.metadataContainer {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 0.625em;
  width: 18.6em;
  height: 2em;
  align-self: center;
  padding-top: 0.32em;
  flex-shrink: 0;
}
.sourceContainer {
  position: relative;
  width: auto;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 50%;
}
.slotTargetSource {
  font-family: "Open Sans", sans-serif;
  font-size: 0.66em;
  font-weight: 600;
  color: rgba(87, 87, 87, 1);
  line-height: 1.8;
}
.slotTargetSourceglobal_theme_dark {
  color: var(--token-7YSTg8Ece);
}
.metadata {
  width: auto;
  height: auto;
  display: flex;
  position: relative;
  overflow: hidden;
  flex-direction: row;
}
.metadata > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: calc(0px - 9.539px);
  width: calc(100% + 9.539px);
}
.metadata > :global(.__wab_flex-container) > *,
.metadata > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.metadata > :global(.__wab_flex-container) > picture > img,
.metadata
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 9.539px;
}
.readingTimeContainer {
  position: relative;
  width: auto;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.slotTargetReadingTime {
  font-family: "Open Sans", sans-serif;
  font-size: 0.66em;
  font-weight: 600;
  color: rgba(87, 87, 87, 1);
  text-align: right;
  line-height: 1.8;
}
.slotTargetReadingTimeglobal_theme_dark {
  color: var(--token-7YSTg8Ece);
}
.dateContainer {
  position: relative;
  width: auto;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.slotTargetDate {
  font-family: "Open Sans", sans-serif;
  font-size: 0.66em;
  font-weight: 600;
  color: rgba(87, 87, 87, 1);
  text-align: right;
  line-height: 1.8;
}
.slotTargetDateglobal_theme_dark {
  color: var(--token-7YSTg8Ece);
}
.snippetContainer {
  display: flex;
  position: relative;
  flex-direction: column;
  margin-left: 20px;
  margin-right: 10px;
  width: 19em;
  height: auto;
}
.slotTargetSnippet {
  font-family: "Lora", serif;
  font-size: 0.8em;
  font-weight: 400;
  color: rgba(111, 111, 111, 1);
  line-height: 1.8em;
}
.slotTargetSnippetglobal_theme_dark {
  color: #a3a2a8;
}
.titleImage {
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: unset;
  overflow: hidden;
  width: 20.75em;
  height: auto;
  align-self: center;
}
.image {
  position: relative;
  object-fit: cover;
  width: 100%;
  min-width: 0;
}
.image > picture > img {
  object-fit: cover;
}
.imageContainer {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  overflow: hidden;
  min-height: 11em;
  background: #faf8f0;
  min-width: 0;
  padding: 0px;
}
.imageContainerglobal_theme_dark {
  background: var(--token-V3GUThiKR);
}
