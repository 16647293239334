.modalBackdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 15;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(6px);
  margin: 0 !important;
}
