.switch {
  align-items: center;
  background: linear-gradient(210.55deg, #484545 -3.02%, #272525 93.08%);
  border-radius: 9999px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  padding: 3px 4px;
  position: relative;
  width: 40px;
}

.switch-inner {
  align-items: center;
  background: linear-gradient(210.55deg, #353132 -3.02%, #2b2627 93.08%);
  border-radius: 9999px;
  display: flex;
  height: 16px;
  justify-content: space-between;
  padding: 1px 3px;
  position: relative;
  width: 33px;
}

.switch-thumb {
  background: linear-gradient(66.56deg, #3960ef 11.23%, #7690ed 159.88%);
  border-radius: 9999px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  box-shadow: 0px 1px 20px #4062dd, inset 0.5px -1.5px 2px rgba(0, 0, 0, 0.25);
  display: inline-block;
  height: 14px;
  left: 1px;
  position: absolute;
  transition: 0.3s width, 0.1s border-radius, transform 0.3s;
  width: 14px;
}

.switch-thumb[data-state='checked'] {
  border-radius: 1.25em;
  transform: translateX(20px);
  width: 0.5em;
}

.switch-icon {
  opacity: 1;
  transition: 0.1s all;
}

.switch[data-state='unchecked'] .switch-icon-grid,
.switch[data-state='checked'] .switch-icon-list {
  opacity: 0;
  transform: scale(0.8);
}
