.menuButton {
  display: inline-flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: auto;
  height: auto;
  max-width: 100%;
  opacity: 1;
  padding: 8px;
}
.menuButtoncolor_light {
  justify-content: center;
  align-items: center;
}
.localHeaderSubmenu {
  object-fit: contain;
  max-width: 100%;
  width: 1.25em;
  height: auto;
  max-height: 1.56em;
  flex-shrink: 0;
}
.localHeaderSubmenu > picture > img {
  object-fit: contain;
}
.localHeaderSubmenuinactive {
  opacity: 0;
}
.localHeaderSubmenucolor_light {
  display: none !important;
}
.localHeaderSubmenucolor_inactive {
  opacity: 0;
}
.svg {
  position: relative;
  object-fit: cover;
  color: var(--token-7YSTg8Ece);
  height: 1.1em;
  width: 1.1em;
  flex-shrink: 0;
  display: none;
}
.svgcolor_light {
  display: block;
}
