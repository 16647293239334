.root {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: auto;
  justify-content: flex-start;
  align-items: center;
  padding-left: 1em;
  padding-right: 0.5em;
  padding-bottom: 0.5em;
  min-width: 0;
}
.sourcePanel {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  max-height: 4.5em;
  overflow: hidden;
  min-width: 0;
  margin: 0.2em;
}
.sourcePanel > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  flex-wrap: wrap;
  min-width: 0;
  margin-left: calc(0px - 6px);
  width: calc(100% + 6px);
}
.sourcePanel > :global(.__wab_flex-container) > *,
.sourcePanel > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.sourcePanel > :global(.__wab_flex-container) > picture > img,
.sourcePanel
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 6px;
}
.buttonPanel {
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-end;
  width: 100%;
  height: auto;
  max-width: 100%;
  flex-wrap: wrap;
  max-height: 7em;
  align-content: flex-start;
  margin-top: -2.4em;
  min-width: 0;
  display: none;
  padding: 0.2em 0.5em;
}
.buttonPanelshowButtonPanel {
  display: flex;
}
.freeBox {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: auto;
  height: auto;
  max-width: 100%;
  backdrop-filter: blur(0px) blur(1px);
  filter: none;
  -webkit-backdrop-filter: blur(0px) blur(1px);
  border-radius: 20px;
  padding: 0.5em 0.2em;
}
.showMoreSourcesButton:global(.__wab_instance) {
  max-width: 100%;
}
.svg__kU8Ee {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text {
  font-size: 0.8em;
}
.svg {
  position: relative;
  object-fit: cover;
  width: 14px;
  height: 14px;
}
