.container {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: 1rem /* 16px */;
  padding-left: 1rem /* 16px */;
}
@media (min-width: 640px) {
  .container {
    max-width: 640px;
    padding-right: 2rem /* 32px */;
    padding-left: 2rem /* 32px */;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}
@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
    padding-right: 8rem /* 128px */;
    padding-left: 8rem /* 128px */;
  }
}
@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
    padding-right: 16rem /* 256px */;
    padding-left: 16rem /* 256px */;
  }
}
@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
    padding-right: 32rem /* 512px */;
    padding-left: 32rem /* 512px */;
  }
}
