.root {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: auto;
  justify-content: flex-start;
  align-items: center;
  max-width: 22.6em;
  min-width: 0;
}
.roottype_episode {
  max-height: 8em;
}
.rootavailableWidth_list {
  max-width: none;
}
.root:hover {
  transform: scaleX(1.03) scaleY(1.03) scaleZ(1);
}
.card {
  background: linear-gradient(
      231deg,
      rgba(52, 48, 49, 1) 72%,
      rgba(42, 37, 38, 1) 108%
    )
    0% 0% / 100% 100% no-repeat;
  filter: drop-shadow(0px 2px 3px rgba(0, 0, 0, 0.25));
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-width: 0;
  min-height: 0;
  border-radius: 10px;
}
.cardtype_episode {
  background: none;
  align-items: center;
  justify-content: center;
  height: 8em;
  flex-shrink: 0;
}
.roottype_episode:hover .cardtype_episode {
  background: var(--token-P44QyWYsh);
  height: 8em;
  flex-shrink: 0;
}
.freeBox___9U2Dl {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: auto;
  height: auto;
  max-width: 100%;
  top: auto;
  left: auto;
  padding: 0px;
}
.freeBoxtype_episode___9U2Dlstgjw {
  position: relative;
  left: auto;
  top: auto;
}
.root:hover .freeBox___9U2Dl {
  align-items: center;
  justify-content: center;
  height: 100%;
  left: auto;
  top: auto;
  min-height: 0;
}
.imageContainer {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 4.6em;
  min-width: 0;
}
.imageContainertype_episode {
  width: 8em;
  max-width: none;
  height: 100%;
  flex-shrink: 0;
  min-height: 0;
}
.imageContaineravailableWidth_list {
  width: 4.6em;
  max-width: none;
  flex-shrink: 0;
}
.roottype_episode_availableWidth_list:hover
  .imageContainertype_episode_availableWidth_list {
  width: 8em;
  flex-shrink: 0;
}
.image {
  object-fit: fill;
  max-width: none;
  width: 100%;
  height: 100%;
  min-width: 0;
  min-height: 0;
}
.image > picture > img {
  object-fit: fill;
}
.svg__qt4Gc {
  object-fit: cover;
  max-width: 100%;
  color: var(--token-kgwkYLWcU);
  min-width: 1.4em;
  min-height: 1.4em;
  margin-top: 2px;
  position: absolute;
  left: 22px;
  top: 21px;
  height: 1em;
  display: none;
}
.root:hover .svg__qt4Gc {
  top: 1.45em;
  left: 1.62em;
  display: block;
}
.roottype_episode:hover .svgtype_episode__qt4Gcstgjw {
  top: 2.96em;
  left: 3.27em;
  min-width: 2em;
  min-height: 2em;
}
.roottype_track_availableWidth_list:hover
  .svgtype_track_availableWidth_list__qt4GcdbLavRQvKy {
  left: 31px;
  top: 28px;
}
.data {
  height: 100%;
  display: flex;
  position: relative;
  flex-grow: 1;
  overflow: hidden;
  flex-direction: column;
  margin-right: 0px;
  min-height: 3.2em;
  padding: 0px 4px 0px 8px;
}
.data > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
}
.datatype_episode {
  height: 100%;
  flex-shrink: 1;
  flex-grow: 1;
  max-height: 95%;
  min-height: 0;
  padding: 0px 0px 0px 12px;
}
.datatype_episode > :global(.__wab_flex-container) {
  justify-content: space-between;
  min-height: 0;
  margin-left: calc(0px - 0px);
  width: calc(100% + 0px);
  margin-top: calc(0px - 2px);
  height: calc(100% + 2px);
}
.datatype_episode > :global(.__wab_flex-container) > *,
.datatype_episode > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.datatype_episode > :global(.__wab_flex-container) > picture > img,
.datatype_episode
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 0px;
  margin-top: 2px;
}
.roottype_episode:hover .datatype_episode {
  max-height: 90%;
}
.roottype_episode:hover .datatype_episode > :global(.__wab_flex-container) {
  justify-content: space-around;
}
.freeBox__fr1HO {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  align-self: stretch;
  min-height: 3.2em;
  min-width: 0;
  padding: 2px 6px 3px;
}
.freeBox__fr1HO > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  min-width: 0;
}
.freeBoxtype_episode__fr1HOstgjw {
  min-height: 2.8em;
  padding: 0px;
}
.freeBoxtype_episode__fr1HOstgjw > :global(.__wab_flex-container) {
  margin-left: calc(0px - 0px);
  width: calc(100% + 0px);
  margin-top: calc(0px - 4px);
  height: calc(100% + 4px);
}
.freeBoxtype_episode__fr1HOstgjw > :global(.__wab_flex-container) > *,
.freeBoxtype_episode__fr1HOstgjw
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > *,
.freeBoxtype_episode__fr1HOstgjw
  > :global(.__wab_flex-container)
  > picture
  > img,
.freeBoxtype_episode__fr1HOstgjw
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 0px;
  margin-top: 4px;
}
.freeBoxavailableWidth_list__fr1HOrQvKy {
  min-height: 3.8em;
}
.roottype_episode_availableWidth_list:hover
  .freeBoxtype_episode_availableWidth_list__fr1HOstgjwRQvKy {
  max-width: 98%;
}
.roottype_episode_availableWidth_list:hover
  .freeBoxtype_episode_availableWidth_list__fr1HOstgjwRQvKy
  > :global(.__wab_flex-container) {
  justify-content: flex-start;
  align-items: flex-start;
}
.nameContainer {
  display: flex;
  overflow: hidden;
  flex-direction: row;
  width: 15em;
  height: 1.4em;
  flex-shrink: 0;
}
.nameContainertype_episode {
  height: auto;
  max-height: 2.8em;
  min-height: 1em;
}
.nameContaineravailableWidth_list {
  width: 100%;
  min-width: 0;
}
.slotTargetName {
  font-family: "Open Sans", sans-serif;
  font-size: 0.8em;
  font-weight: 600;
  color: rgba(255, 255, 255, 1);
  line-height: 19.346px;
  letter-spacing: 0px;
  white-space: pre;
}
.slotTargetName > :global(.__wab_text),
.slotTargetName > :global(.__wab_expr_html_text),
.slotTargetName > :global(.__wab_slot-string-wrapper),
.slotTargetName > :global(.__wab_slot) > :global(.__wab_text),
.slotTargetName > :global(.__wab_slot) > :global(.__wab_expr_html_text),
.slotTargetName > :global(.__wab_slot) > :global(.__wab_slot-string-wrapper),
.slotTargetName
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_text),
.slotTargetName
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_expr_html_text),
.slotTargetName
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot-string-wrapper),
.slotTargetName
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_text),
.slotTargetName
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_expr_html_text),
.slotTargetName
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot-string-wrapper) {
  text-overflow: ellipsis;
}
.slotTargetName > *,
.slotTargetName > :global(.__wab_slot) > *,
.slotTargetName > :global(.__wab_slot) > :global(.__wab_slot) > *,
.slotTargetName
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > *,
.slotTargetName > picture > img,
.slotTargetName > :global(.__wab_slot) > picture > img,
.slotTargetName > :global(.__wab_slot) > :global(.__wab_slot) > picture > img,
.slotTargetName
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > picture
  > img {
  overflow: hidden;
}
.slotTargetNametype_episode {
  font-size: 0.8em;
  white-space: pre-wrap;
  line-height: 22px;
}
.authorContainer {
  display: flex;
  align-self: stretch;
  position: relative;
  overflow: hidden;
  flex-direction: row;
  padding-left: 0px;
  padding-right: 0px;
}
.authorContainertype_episode {
  margin-bottom: 5px;
  display: none;
}
.slotTargetAuthor {
  font-family: "Open Sans", sans-serif;
  font-size: 0.6em;
  font-weight: 600;
  color: rgba(150, 150, 150, 1);
  line-height: 19.346px;
  letter-spacing: 0px;
  white-space: pre;
}
.slotTargetAuthor > :global(.__wab_text),
.slotTargetAuthor > :global(.__wab_expr_html_text),
.slotTargetAuthor > :global(.__wab_slot-string-wrapper),
.slotTargetAuthor > :global(.__wab_slot) > :global(.__wab_text),
.slotTargetAuthor > :global(.__wab_slot) > :global(.__wab_expr_html_text),
.slotTargetAuthor > :global(.__wab_slot) > :global(.__wab_slot-string-wrapper),
.slotTargetAuthor
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_text),
.slotTargetAuthor
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_expr_html_text),
.slotTargetAuthor
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot-string-wrapper),
.slotTargetAuthor
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_text),
.slotTargetAuthor
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_expr_html_text),
.slotTargetAuthor
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot-string-wrapper) {
  text-overflow: ellipsis;
}
.slotTargetAuthortype_episode {
  font-size: 0.5em;
  color: var(--token-B8u26uCIS);
}
.descriptionContainer {
  display: flex;
  align-self: stretch;
  position: relative;
  padding-right: 4px;
  flex-direction: row;
}
.descriptionContainertype_episode {
  height: auto;
  overflow: hidden;
  max-height: 3em;
  padding-bottom: 0px;
  align-items: flex-start;
  justify-content: flex-start;
  display: flex;
  flex-direction: column;
}
.roottype_episode:hover .descriptionContainertype_episode {
  max-height: 2.4em;
}
.slotTargetDescription {
  font-family: "Open Sans", sans-serif;
  font-size: 0.5em;
  font-weight: 600;
  color: var(--token-B8u26uCIS);
  line-height: 12px;
  letter-spacing: 0px;
  white-space: pre-wrap;
}
.slotTargetDescriptiontype_episode {
  font-size: 0.55em;
  line-height: 12px;
}
.freeBox___3I15C {
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  display: none;
  padding: 0px;
}
.freeBox___3I15C > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - 20px);
  width: calc(100% + 20px);
}
.freeBox___3I15C > :global(.__wab_flex-container) > *,
.freeBox___3I15C > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox___3I15C > :global(.__wab_flex-container) > picture > img,
.freeBox___3I15C
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 20px;
}
.freeBoxtype_episode___3I15Cstgjw {
  display: flex;
}
.datePublished {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  font-size: 0.5em;
  color: var(--token-7YSTg8Ece);
  font-weight: 700;
}
.duration {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  font-size: 0.5em;
  color: var(--token-7YSTg8Ece);
  font-weight: 700;
}
.freeBox__auK8G {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: auto;
  height: auto;
  max-width: 100%;
  margin-left: 0px;
  margin-right: 5px;
  padding: 0px;
}
.freeBoxtype_episode__auK8Gstgjw {
  display: none;
}
.roottype_episode:hover .freeBoxtype_episode__auK8Gstgjw {
  width: auto;
  padding-left: 0px;
  display: flex;
}
.roottype_track_availableWidth_list:hover
  .freeBoxtype_track_availableWidth_list__auK8GdbLavRQvKy {
  width: auto;
}
.roottype_episode_availableWidth_list:hover
  .freeBoxtype_episode_availableWidth_list__auK8GstgjwRQvKy {
  align-self: flex-end;
  display: block;
  width: 1em;
  flex-shrink: 0;
  margin: 0em;
}
.freeBox__wZzb2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: auto;
  height: auto;
  max-width: 100%;
  padding: 0px;
}
.roottype_track_availableWidth_list:hover
  .freeBoxtype_track_availableWidth_list__wZzb2DbLavRQvKy {
  width: auto;
  padding: 0px;
}
.roottype_episode_availableWidth_list:hover
  .freeBoxtype_episode_availableWidth_list__wZzb2StgjwRQvKy {
  width: 16em;
  justify-content: flex-end;
  align-items: center;
  display: block;
  height: 2em;
  position: absolute;
  left: -84px;
  top: -37px;
  padding: 0px;
}
.iconStack {
  display: flex;
  flex-direction: row;
  width: auto;
  height: auto;
  max-width: 100%;
  padding: 8px 8px 8px 0px;
}
.iconStack > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
}
.iconStacktype_episode {
  align-self: flex-start;
  margin-right: -8px;
  padding: 8px;
}
.roottype_episode:hover .iconStacktype_episode {
  width: auto;
}
.roottype_track_availableWidth_list:hover
  .iconStacktype_track_availableWidth_list
  > :global(.__wab_flex-container) {
  align-items: center;
  justify-content: center;
  margin-left: calc(0px - 0px);
  width: calc(100% + 0px);
  margin-top: calc(0px - 0px);
  height: calc(100% + 0px);
}
.roottype_track_availableWidth_list:hover
  .iconStacktype_track_availableWidth_list
  > :global(.__wab_flex-container)
  > *,
.roottype_track_availableWidth_list:hover
  .iconStacktype_track_availableWidth_list
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > *,
.roottype_track_availableWidth_list:hover
  .iconStacktype_track_availableWidth_list
  > :global(.__wab_flex-container)
  > picture
  > img,
.roottype_track_availableWidth_list:hover
  .iconStacktype_track_availableWidth_list
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 0px;
  margin-top: 0px;
}
.roottype_episode_availableWidth_list:hover
  .iconStacktype_episode_availableWidth_list {
  position: absolute;
  left: -8px;
  top: -12px;
}
.roottype_episode_availableWidth_list:hover
  .iconStacktype_episode_availableWidth_list
  > :global(.__wab_flex-container) {
  align-items: center;
  justify-content: center;
  margin-left: calc(0px - 20px);
  width: calc(100% + 20px);
  margin-top: calc(0px - 0px);
  height: calc(100% + 0px);
}
.roottype_episode_availableWidth_list:hover
  .iconStacktype_episode_availableWidth_list
  > :global(.__wab_flex-container)
  > *,
.roottype_episode_availableWidth_list:hover
  .iconStacktype_episode_availableWidth_list
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > *,
.roottype_episode_availableWidth_list:hover
  .iconStacktype_episode_availableWidth_list
  > :global(.__wab_flex-container)
  > picture
  > img,
.roottype_episode_availableWidth_list:hover
  .iconStacktype_episode_availableWidth_list
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 20px;
  margin-top: 0px;
}
.deleteButton {
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  display: none;
  padding: 8px;
}
.roottype_track_availableWidth_list:hover
  .deleteButtontype_track_availableWidth_list {
  display: flex;
}
.roottype_episode_availableWidth_list:hover
  .deleteButtontype_episode_availableWidth_list {
  width: 100%;
  max-width: 100%;
  height: 2.5em;
  min-width: 0;
  display: flex;
  padding: 8px;
}
.svg__uMhKb {
  position: relative;
  object-fit: cover;
  color: var(--token-kgwkYLWcU);
  height: 1.5em;
  width: 1.4em;
  flex-shrink: 0;
  display: none;
}
.roottype_track_availableWidth_list:hover
  .svgtype_track_availableWidth_list__uMhKbDbLavRQvKy {
  display: block;
}
.roottype_episode_availableWidth_list:hover
  .svgtype_episode_availableWidth_list__uMhKbStgjwRQvKy {
  display: block;
}
.archiveButton {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
.roottype_track_availableWidth_list:hover
  .archiveButtontype_track_availableWidth_list {
  display: flex;
}
.roottype_episode_availableWidth_list:hover
  .archiveButtontype_episode_availableWidth_list {
  width: 100%;
  max-width: none;
  height: 2.5em;
  min-width: 0;
  display: flex;
  padding: 8px;
}
.svg__nYMho {
  position: relative;
  object-fit: cover;
  color: var(--token-kgwkYLWcU);
  height: 1.4em;
  width: 1.4em;
  flex-shrink: 0;
  display: none;
}
.roottype_track_availableWidth_list:hover
  .svgtype_track_availableWidth_list__nYMhoDbLavRQvKy {
  display: block;
}
.roottype_episode_availableWidth_list:hover
  .svgtype_episode_availableWidth_list__nYMhoStgjwRQvKy {
  display: block;
}
.saveForLaterButton {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
.roottype_track_availableWidth_list:hover
  .saveForLaterButtontype_track_availableWidth_list {
  display: flex;
}
.roottype_episode_availableWidth_list:hover
  .saveForLaterButtontype_episode_availableWidth_list {
  width: 100%;
  max-width: none;
  height: 2.5em;
  min-width: 0;
  display: flex;
  padding: 8px;
}
.svg__uBwfM {
  position: relative;
  object-fit: cover;
  color: var(--token-kgwkYLWcU);
  height: 1.4em;
  width: 1.2em;
  flex-shrink: 0;
  display: none;
}
.roottype_track_availableWidth_list:hover
  .svgtype_track_availableWidth_list__uBwfMdbLavRQvKy {
  display: block;
}
.roottype_episode_availableWidth_list:hover
  .svgtype_episode_availableWidth_list__uBwfMstgjwRQvKy {
  display: block;
}
.menuButton {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: auto;
  height: auto;
  padding: 8px;
}
.menuButtontype_track {
  padding-right: 0px;
}
.menuButtontype_episode {
  padding-left: 8px;
}
.roottype_episode:hover .menuButtontype_episode {
  padding-left: 0px;
  padding-right: 0px;
}
.roottype_track_availableWidth_list:hover
  .menuButtontype_track_availableWidth_list {
  padding-left: 8px;
  margin-top: calc(4px + 0px) !important;
}
.roottype_episode_availableWidth_list:hover
  .menuButtontype_episode_availableWidth_list {
  width: 100%;
  max-width: none;
  margin-top: calc(3px + 0px) !important;
  height: 2em;
  min-width: 0;
}
.svg__vFBn {
  position: relative;
  object-fit: cover;
  height: 1em;
  width: 1em;
  flex-shrink: 0;
}
.roottype_track_availableWidth_list:hover
  .svgtype_track_availableWidth_list__vFBnDbLavRQvKy {
  color: var(--token-kgwkYLWcU);
}
.roottype_episode_availableWidth_list:hover
  .svgtype_episode_availableWidth_list__vFBnStgjwRQvKy {
  color: var(--token-kgwkYLWcU);
}
