.root {
  display: inline-flex;
  flex-direction: column;
  position: relative;
  width: auto;
  height: auto;
}
.rootdone {
  display: block;
  width: 238px;
  height: 28px;
}
.checklistItemContainer {
  filter: drop-shadow(0px 2px 3px rgba(0, 0, 0, 0.25));
  width: 238px;
  height: 28px;
  display: flex;
  background: linear-gradient(251deg, #474243 36%, rgba(52, 49, 49, 1) 82%) 0%
    0% / 100% 100% no-repeat padding-box border-box scroll;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-left: 0.5em;
  padding-right: 0.5em;
  flex-shrink: 0;
  border-radius: 5px;
}
.checklistItemContainerdone {
  overflow: hidden;
  position: absolute;
  left: 0px;
  top: 0px;
  background: none;
  display: block;
}
.root:hover .checklistItemContainer {
  background: linear-gradient(
    74deg,
    #fcfaf6 5%,
    var(--token-kgwkYLWcU) 59%,
    #c8cced 90%
  );
}
.freeBox__yBgv {
  display: flex;
  position: relative;
  top: auto;
  left: auto;
  width: 100%;
  min-width: 0;
}
.freeBoxdone__yBgv1KLpF {
  position: absolute;
  left: 8px;
  top: 4px;
}
.slotTargetText {
  font-size: 11px;
  color: #faf7ee;
  line-height: 19.333px;
  letter-spacing: 0px;
}
.slotTargetTextdisabled {
  color: var(--token-FmckP9B6G);
  font-style: italic;
}
.slotTargetTextdisabled > :global(.__wab_text),
.slotTargetTextdisabled > :global(.__wab_expr_html_text),
.slotTargetTextdisabled > :global(.__wab_slot-string-wrapper),
.slotTargetTextdisabled > :global(.__wab_slot) > :global(.__wab_text),
.slotTargetTextdisabled > :global(.__wab_slot) > :global(.__wab_expr_html_text),
.slotTargetTextdisabled
  > :global(.__wab_slot)
  > :global(.__wab_slot-string-wrapper),
.slotTargetTextdisabled
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_text),
.slotTargetTextdisabled
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_expr_html_text),
.slotTargetTextdisabled
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot-string-wrapper),
.slotTargetTextdisabled
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_text),
.slotTargetTextdisabled
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_expr_html_text),
.slotTargetTextdisabled
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot-string-wrapper) {
  text-decoration-line: none;
}
.slotTargetTextdone {
  color: var(--token-FmckP9B6G);
}
.slotTargetTextdone > :global(.__wab_text),
.slotTargetTextdone > :global(.__wab_expr_html_text),
.slotTargetTextdone > :global(.__wab_slot-string-wrapper),
.slotTargetTextdone > :global(.__wab_slot) > :global(.__wab_text),
.slotTargetTextdone > :global(.__wab_slot) > :global(.__wab_expr_html_text),
.slotTargetTextdone
  > :global(.__wab_slot)
  > :global(.__wab_slot-string-wrapper),
.slotTargetTextdone
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_text),
.slotTargetTextdone
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_expr_html_text),
.slotTargetTextdone
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot-string-wrapper),
.slotTargetTextdone
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_text),
.slotTargetTextdone
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_expr_html_text),
.slotTargetTextdone
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot-string-wrapper) {
  text-decoration-line: line-through;
}
.root:hover .slotTargetText {
  color: var(--token-WkUIkx5-J);
}
.buttonDefault {
  filter: drop-shadow(0px 1px 2px rgba(88, 119, 231, 0.6));
  width: 2.75em;
  height: 1.25em;
  display: flex;
  position: relative;
  top: auto;
  right: auto;
  overflow: hidden;
  flex-direction: row;
  background: rgba(33, 65, 179, 1);
  flex-shrink: 1;
  border-radius: 3.176px;
  padding: 4.765px 2.457px;
}
.buttonDefault > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: calc(0px - 3.685px);
  width: calc(100% + 3.685px);
}
.buttonDefault > :global(.__wab_flex-container) > *,
.buttonDefault > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.buttonDefault > :global(.__wab_flex-container) > picture > img,
.buttonDefault
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 3.685px;
}
.buttonDefaultdone {
  position: absolute;
  left: 0;
  top: 0;
  bottom: auto;
  right: auto;
}
.box {
  position: relative;
  width: 12px;
  height: 14px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
}
.buttonText {
  display: block;
  font-size: 7.942px;
  text-align: center;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  letter-spacing: 0px;
  line-height: 12.707px;
  color: rgba(250, 247, 238, 1);
}
.freeBox__z3LVu {
  display: block;
  position: relative;
  width: 14.756742000000003px;
  height: 14.756742000000003px;
  flex-shrink: 0;
}
.arrowIcon {
  position: absolute;
  object-fit: cover;
  width: 14.742px;
  height: 14.742px;
  color: #fdfbf7;
  transform: scale(1.000000499999875, 1.0000004999998748)
    rotate(90.0572957604145deg);
  display: block;
  transform-origin: top left;
  overflow: hidden;
  left: 14.756742000000003px;
  top: 0.014742000000000033px;
}
.svg__iq43T {
  position: relative;
  object-fit: cover;
  width: 26.141px;
  height: 26.141px;
  left: auto;
  top: auto;
  flex-shrink: 0;
}
.svgdone__iq43T1KLpF {
  width: 21px;
  height: 21px;
  top: 4px;
  left: 199px;
  color: var(--token-kgwkYLWcU);
  position: absolute;
}
.svg__bPTiC {
  width: 21px;
  height: 21px;
  position: relative;
  object-fit: cover;
  left: auto;
  top: auto;
  transition-property: all;
  transition-duration: 1s;
  flex-shrink: 0;
  -webkit-transition-property: all;
  -webkit-transition-duration: 1s;
}
.svgdone__bPTiC1KLpF {
  left: 199px;
  top: 4px;
  position: absolute;
  display: block;
}
.freeBox___3Xg0Z {
  display: block;
  position: relative;
  left: auto;
  top: auto;
}
.freeBoxdone___3Xg0Z1KLpF {
  width: 16px;
  height: 16px;
  left: 0;
  top: 0;
  box-shadow: 0px 2px 20px 5px #5978e899;
  position: absolute;
  bottom: auto;
  right: auto;
  background: none;
  border-radius: 200px;
}
.freeBox__nn6Nm {
  display: block;
  position: relative;
  left: auto;
  top: auto;
}
.freeBoxdone__nn6Nm1KLpF {
  width: 16px;
  height: 16px;
  left: 201px;
  top: 6px;
  box-shadow: 0px 2px 20px 5px #5978e866;
  position: absolute;
  background: none;
  border-radius: 200px;
}
