.root {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 40px;
  height: 40px;
  padding: 0px 0px 0px 1px;
  border-width: 1px;
  border-style: none;
}
.root > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.rootselected {
  width: 40px;
  height: 50px;
  transform: none;
}
.rootselected > :global(.__wab_flex-container) {
  align-items: center;
  justify-content: flex-end;
  margin-left: calc(0px - 0px);
  width: calc(100% + 0px);
  margin-top: calc(0px - 8px);
  height: calc(100% + 8px);
}
.rootselected > :global(.__wab_flex-container) > *,
.rootselected > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.rootselected > :global(.__wab_flex-container) > picture > img,
.rootselected
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 0px;
  margin-top: 8px;
}
.img___8Qfct {
  position: relative;
  object-fit: contain;
  transform: none;
  width: 50px;
  height: auto;
  display: none !important;
}
.img___8Qfct > picture > img {
  object-fit: contain;
}
.imgselected___8QfctOeozy {
  display: none !important;
}
.imgselected_color_red___8QfctOeozyRz9PY {
  display: none !important;
}
.img__achw4 {
  object-fit: contain;
  display: block !important;
  max-height: none;
  width: 35px;
  height: 35px;
  flex-shrink: 0;
}
.img__achw4 > picture > img {
  object-fit: contain;
}
.imgselected__achw4Oeozy {
  transform: scaleX(1.1) scaleY(1.1) scaleZ(1.1);
  transform-style: preserve-3d;
  display: block !important;
}
.imgselected__achw4Oeozy * {
  transform-style: preserve-3d;
}
.imgselected_color_red__achw4OeozyRz9PY {
  transform-style: preserve-3d;
  display: none !important;
}
.imgselected_color_red__achw4OeozyRz9PY * {
  transform-style: preserve-3d;
}
.img___1Gc0L {
  position: relative;
  object-fit: contain;
  display: block;
  width: 35px;
  height: 35px;
  flex-shrink: 0;
}
.img___1Gc0L > picture > img {
  object-fit: contain;
}
.imgselected___1Gc0LOeozy {
  display: none !important;
}
.imgselected_color_red___1Gc0LOeozyRz9PY {
  transform: scaleX(1.1) scaleY(1.1) scaleZ(1.1);
  transform-style: preserve-3d;
  display: block !important;
}
.imgselected_color_red___1Gc0LOeozyRz9PY * {
  transform-style: preserve-3d;
}
.freeBox {
  position: relative;
  width: 20px;
  height: 3px;
  max-width: 100%;
  background: #2141b3;
  flex-shrink: 0;
  display: none;
  border-radius: 8px;
  padding: 0px;
}
.freeBoxselected {
  display: block;
}
