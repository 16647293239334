.root {
  display: inline-flex;
  flex-direction: column;
  position: relative;
  width: auto;
  height: auto;
}
.img {
  position: relative;
  object-fit: fill;
  width: 52px;
  height: 52px;
  align-self: center;
  flex-shrink: 0;
}
.img > picture > img {
  object-fit: fill;
}
.imgrotated {
  transform: rotateX(0deg) rotateY(0deg) rotateZ(90deg);
}
