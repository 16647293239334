.root {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  background: #ffffff;
  min-width: 0;
  min-height: 0;
}
.freeBox__kgSCn {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  height: 100%;
  background: var(--token-kgwkYLWcU);
  min-height: 0;
}
.freeBoxglobal_theme_dark__kgSCnOIz4X {
  background: var(--token-V3GUThiKR);
}
.h2Headline {
  display: block;
  font-size: 22px;
  text-align: center;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  letter-spacing: 0px;
  position: relative;
  top: auto;
  left: auto;
  margin-top: 60px;
  margin-bottom: 0px;
  color: var(--token-WkUIkx5-J);
}
@media (min-width: 0px) and (max-width: 768px) {
  .h2Headline {
    font-size: 18px;
    padding-left: 20px;
    padding-right: 20px;
  }
}
.h2Headlineglobal_theme_dark {
  color: var(--token-kgwkYLWcU);
  font-weight: 600;
}
.h2Headline2 {
  display: block;
  font-size: 22px;
  text-align: center;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  letter-spacing: 0px;
  position: relative;
  top: auto;
  left: auto;
  margin-top: 5px;
  color: var(--token-yt3rDB0KD);
}
@media (min-width: 0px) and (max-width: 768px) {
  .h2Headline2 {
    font-size: 18px;
  }
}
.h2Headline2global_theme_dark {
  color: var(--token-pMUHtTtb1);
  font-weight: 400;
}
.interestContainer {
  display: flex;
  position: relative;
  flex-direction: row;
  height: 100%;
  margin-top: 20px;
  width: 100%;
  align-self: center;
  min-width: 0;
  min-height: 0;
  padding: 20px;
}
.interestContainer > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: stretch;
  justify-content: center;
  flex-wrap: wrap;
  align-content: flex-start;
  min-width: 0;
  min-height: 0;
  margin-left: calc(0px - 10px);
  width: calc(100% + 10px);
  margin-top: calc(0px - 10px);
  height: calc(100% + 10px);
}
.interestContainer > :global(.__wab_flex-container) > *,
.interestContainer > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.interestContainer > :global(.__wab_flex-container) > picture > img,
.interestContainer
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 10px;
  margin-top: 10px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .interestContainer {
    width: 409px;
    align-self: center;
  }
}
.interestContainerglobal_theme_dark {
  width: 100%;
  margin-bottom: 20px;
  min-width: 0;
}
.interestContainerglobal_theme_dark > :global(.__wab_flex-container) {
  justify-content: space-evenly;
  min-width: 0;
  margin-left: calc(0px - 0px);
  width: calc(100% + 0px);
  margin-top: calc(0px - 42px);
  height: calc(100% + 42px);
}
.interestContainerglobal_theme_dark > :global(.__wab_flex-container) > *,
.interestContainerglobal_theme_dark
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > *,
.interestContainerglobal_theme_dark
  > :global(.__wab_flex-container)
  > picture
  > img,
.interestContainerglobal_theme_dark
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 0px;
  margin-top: 42px;
}
.bottomToolbar {
  box-shadow: 0px 0px 0px 0.1px rgba(0, 0, 0, 0.05);
  filter: drop-shadow(0px -1.5px 5px rgba(0, 0, 0, 0.05));
  width: 100%;
  height: 3.4em;
  display: flex;
  position: sticky;
  top: auto;
  left: 0px;
  transition-property: all;
  transition-duration: 1s;
  z-index: 1;
  background: var(--token-kgwkYLWcU);
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-right: 1em;
  bottom: 0px;
  min-width: 0;
  flex-shrink: 0;
  -webkit-transition-property: all;
  -webkit-transition-duration: 1s;
}
.bottomToolbarglobal_theme_dark {
  background: var(--token-0Kei2aO9v);
  box-shadow: none;
  left: 0px;
  top: 0px;
  position: sticky;
}
.freeBox__sgFUm {
  display: flex;
  position: relative;
  right: auto;
  bottom: auto;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-width: 0;
}
.freeBoxglobal_theme_dark__sgFUmOIz4X {
  margin-left: 3em;
}
.slotTargetNumberOfSelectedInterests {
  font-size: 14px;
  color: rgba(0, 0, 0, 1);
  text-align: center;
  letter-spacing: 0em;
}
.slotTargetNumberOfSelectedInterestsglobal_theme_dark {
  color: var(--token-kgwkYLWcU);
  font-size: 1em;
}
.submitButton:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  max-height: 70%;
}
.svg__eX9F {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__wtthn {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
