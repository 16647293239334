.root {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 40px;
  height: 40px;
  transform: none;
  padding: 0px;
  border-width: 1px;
  border-style: none;
}
.root > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.rootselected {
  width: 40px;
  height: 50px;
}
.rootselected > :global(.__wab_flex-container) {
  margin-left: calc(0px - 0px);
  width: calc(100% + 0px);
  margin-top: calc(0px - 8px);
  height: calc(100% + 8px);
}
.rootselected > :global(.__wab_flex-container) > *,
.rootselected > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.rootselected > :global(.__wab_flex-container) > picture > img,
.rootselected
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 0px;
  margin-top: 8px;
}
.img___1LFn {
  position: relative;
  object-fit: contain;
  max-height: none;
  width: 30px;
  height: 30px;
  flex-shrink: 0;
}
.img___1LFn > picture > img {
  object-fit: contain;
}
.imgselected___1LFn6AKjm {
  transform: scaleX(1.1) scaleY(1.1) scaleZ(1.1);
  width: 30px;
  height: 30px;
  transform-style: preserve-3d;
  flex-shrink: 0;
  display: block !important;
}
.imgselected___1LFn6AKjm * {
  transform-style: preserve-3d;
}
.imgcolor_red___1LFnyrkS1 {
  display: none !important;
}
.img__nBt0Z {
  position: relative;
  object-fit: cover;
  width: 30px;
  display: none !important;
}
.img__nBt0Z > picture > img {
  object-fit: cover;
}
.imgcolor_red__nBt0ZyrkS1 {
  display: block !important;
}
.imgselected_color_red__nBt0Z6AKjmYrkS1 {
  transform: scaleX(1.1) scaleY(1.1) scaleZ(1.1);
  transform-style: preserve-3d;
}
.imgselected_color_red__nBt0Z6AKjmYrkS1 * {
  transform-style: preserve-3d;
}
.freeBox {
  position: relative;
  width: 20px;
  height: 3px;
  max-width: 100%;
  background: #2141b3;
  flex-shrink: 0;
  display: none;
  border-radius: 8px;
  padding: 0px;
}
.freeBoxselected {
  display: block;
}
