.root {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 40px;
  height: 40px;
  flex-shrink: 0;
  padding: 2px 0px 0px;
  border-width: 1px;
  border-style: none;
}
.root > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-top: calc(0px - 0px);
  height: calc(100% + 0px);
}
.root > :global(.__wab_flex-container) > *,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.root > :global(.__wab_flex-container) > picture > img,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-top: 0px;
}
.rootselected {
  height: 50px;
  width: 40px;
}
.rootselected > :global(.__wab_flex-container) {
  align-items: center;
  justify-content: center;
}
.img__ljT9 {
  object-fit: cover;
  flex-shrink: 0;
  flex-grow: 1;
  height: 33px;
  width: 45px;
}
.img__ljT9 > picture > img {
  object-fit: cover;
}
.imgselected__ljT9IQxya {
  transform: scaleX(1.2) scaleY(1.2) scaleZ(1.2);
  width: 45px;
  max-width: none;
  transform-style: preserve-3d;
  display: block !important;
}
.imgselected__ljT9IQxya * {
  transform-style: preserve-3d;
}
.imgcolor_red__ljT9Wz5RI {
  display: none !important;
}
.img__oPIkp {
  position: relative;
  object-fit: cover;
  align-self: auto;
  width: 45px;
  height: 40px;
  flex-shrink: 0;
  display: none !important;
}
.img__oPIkp > picture > img {
  object-fit: cover;
}
.imgcolor_red__oPIkpwz5RI {
  display: block !important;
}
.imgselected_color_red__oPIkpIQxyaWz5RI {
  transform: scaleX(1.2) scaleY(1.2) scaleZ(1.2);
  transform-style: preserve-3d;
}
.imgselected_color_red__oPIkpIQxyaWz5RI * {
  transform-style: preserve-3d;
}
.freeBox {
  position: relative;
  width: 20px;
  height: 3px;
  max-width: 100%;
  background: #2141b3;
  flex-shrink: 0;
  display: none;
  border-radius: 8px;
  padding: 0px;
}
.freeBoxselected {
  display: block;
}
