.root {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: auto;
  justify-content: flex-start;
  align-items: center;
  max-width: 500px;
  min-width: 0;
}
.tabsContainer:global(.__wab_instance) {
  width: 100%;
  max-width: 100%;
  object-fit: cover;
  position: relative;
  min-width: 0;
  padding: 8px;
}
.freeBox__hq5Qe {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.freeBox__rfYU {
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
.tabButton__jCKh:global(.__wab_instance) {
  width: auto;
  max-width: 100%;
  object-fit: cover;
}
.button__dSg5E:global(.__wab_instance) {
  margin-right: 1em;
}
.recommendedVoices {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.svg__pl1Wz {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__xsLvI {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.tabButton__ipSqo:global(.__wab_instance) {
  width: auto;
  max-width: 100%;
  object-fit: cover;
}
.allVoices {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.svg__vV30U {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg___2OeqV {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.tabUnderline:global(.__wab_instance) {
  background: #7777ff;
  height: 2px;
  max-width: 100%;
  object-fit: cover;
}
.freeBox__bIx5F {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.tabContent__udp9D:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
}
.freeBox__hUZuJ {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.freeBox__zDyDg {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  display: flex;
  flex-direction: row;
  min-width: 0;
}
.tabContent__xMyeD:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
}
.freeBox__plSl3 {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  min-width: 0;
  padding: 8px;
}
