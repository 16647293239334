.root {
  display: inline-flex;
  flex-direction: column;
  position: relative;
  width: auto;
  height: auto;
  padding: 20px;
}
.root > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  align-content: unset;
  margin-top: calc(0px - 40px);
  height: calc(100% + 40px);
}
.root > :global(.__wab_flex-container) > *,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.root > :global(.__wab_flex-container) > picture > img,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-top: 40px;
}
.roothubCreationPanel > :global(.__wab_flex-container) {
  margin-left: calc(0px - 0px);
  width: calc(100% + 0px);
  margin-top: calc(0px - 40px);
  height: calc(100% + 40px);
}
.roothubCreationPanel > :global(.__wab_flex-container) > *,
.roothubCreationPanel
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > *,
.roothubCreationPanel > :global(.__wab_flex-container) > picture > img,
.roothubCreationPanel
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 0px;
  margin-top: 40px;
}
.modeInfo {
  display: flex;
  flex-direction: column;
  width: auto;
  height: auto;
}
.modeInfo > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  align-content: unset;
  margin-top: calc(0px - 20px);
  height: calc(100% + 20px);
}
.modeInfo > :global(.__wab_flex-container) > *,
.modeInfo > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.modeInfo > :global(.__wab_flex-container) > picture > img,
.modeInfo
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 20px;
}
.modeInfohubCreationPanel {
  opacity: 0.08;
}
.modeSwitcher {
  width: auto;
  height: 40px;
  display: flex;
  position: relative;
  top: auto;
  left: auto;
  flex-direction: row;
  margin-top: calc(0px + 20px) !important;
  flex-shrink: 0;
  padding: 0%;
}
.modeSwitcher > :global(.__wab_flex-container) {
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
  margin-left: calc(0px - 35px);
  width: calc(100% + 35px);
}
.modeSwitcher > :global(.__wab_flex-container) > *,
.modeSwitcher > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.modeSwitcher > :global(.__wab_flex-container) > picture > img,
.modeSwitcher
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 35px;
}
.modeSwitcherhubCreationPanel {
  margin-top: calc(0px + 20px) !important;
}
.sectionModeIcon:global(.__wab_instance) {
  max-width: none;
  position: relative;
  flex-grow: 0;
  opacity: 1;
  flex-shrink: 0;
  align-self: auto;
}
.feedModeIcon:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  opacity: 1;
  align-self: auto;
  flex-shrink: 0;
}
.gridModeIcon:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  opacity: 1;
  flex-shrink: 0;
}
.surpriseModeIcon:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  align-self: auto;
  flex-grow: 0;
  flex-shrink: 1;
  max-height: 100%;
}
.slotTargetViewMode {
  font-family: "Open Sans", sans-serif;
  font-size: 18px;
  font-weight: 600;
  color: rgba(255, 255, 255, 1);
  text-align: center;
  line-height: 24px;
  letter-spacing: 0px;
}
.button {
  display: flex;
  position: relative;
  flex-direction: row;
  width: auto;
  height: auto;
  max-width: 100%;
  top: auto;
  left: auto;
  background: linear-gradient(210deg, #353132 0%, #2b2627 100%) 0% 0% / 100%
    100% no-repeat;
  box-shadow: 0px 2px 12px 0px #00000040;
  border-radius: 8px;
  padding: 6px 12px;
  border: 1px none #000000;
}
.button > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
}
.button > :global(.__wab_flex-container) > *,
.button > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.button > :global(.__wab_flex-container) > picture > img,
.button
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 8px;
}
.text {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  color: var(--token-kgwkYLWcU);
  font-size: 14px;
  white-space: pre;
  min-width: 0;
}
.img {
  object-fit: cover;
  width: 16.528px;
  height: 14.692px;
  display: block !important;
  transform: scaleX(1) scaleY(1) scaleZ(1);
  flex-shrink: 0;
}
.img > picture > img {
  object-fit: cover;
}
.hubSelection:global(.__wab_instance) {
  position: relative;
}
.hubSelectionhubCreationPanel:global(.__wab_instance) {
  opacity: 1;
}
.title:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  margin: auto;
}
.hubCreation:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  display: none;
}
.hubCreationhubCreationPanel:global(.__wab_instance) {
  display: none;
}
.checklist:global(.__wab_instance) {
  position: relative;
}
.checklisthubCreationPanel:global(.__wab_instance) {
  opacity: 0.08;
}
