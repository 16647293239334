.root {
  display: flex;
  flex-direction: column;
  position: relative;
  height: auto;
  justify-content: flex-start;
  align-items: center;
  align-content: unset;
  flex-grow: 1;
  width: 100%;
  flex-shrink: 0;
  min-width: 500px;
  max-width: 800px;
}
.arrows:global(.__wab_instance) {
  position: absolute;
  top: auto;
  left: auto;
  width: 105%;
  height: 100%;
  min-height: 0;
}
.arrowscontentHidden:global(.__wab_instance) {
  display: none;
}
.arrowssectionSettingsOpen:global(.__wab_instance) {
  display: none;
}
.arrowseditMode:global(.__wab_instance) {
  display: none;
}
.freeBox {
  display: flex;
  position: relative;
  flex-direction: column;
  left: auto;
  top: auto;
  width: 100%;
  height: auto;
  min-width: 0;
  padding: 12px 28px;
}
.freeBox > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  align-content: unset;
  min-width: 0;
  margin-top: calc(0px - 12px);
  height: calc(100% + 12px);
}
.freeBox > :global(.__wab_flex-container) > *,
.freeBox > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox > :global(.__wab_flex-container) > picture > img,
.freeBox
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 12px;
}
.freeBoxsectionSettingsOpen > :global(.__wab_flex-container) {
  margin-left: calc(0px - 0px);
  width: calc(100% + 0px);
  margin-top: calc(0px - 8px);
  height: calc(100% + 8px);
}
.freeBoxsectionSettingsOpen > :global(.__wab_flex-container) > *,
.freeBoxsectionSettingsOpen
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > *,
.freeBoxsectionSettingsOpen > :global(.__wab_flex-container) > picture > img,
.freeBoxsectionSettingsOpen
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 0px;
  margin-top: 8px;
}
.freeBoxeditMode {
  max-width: 100%;
}
.sectionHeader:global(.__wab_instance) {
  position: relative;
  width: 105%;
  margin-left: -20px;
  flex-shrink: 0;
}
@media (max-width: 6000px) {
  .sectionHeader:global(.__wab_instance) {
    flex-shrink: 0;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .sectionHeader:global(.__wab_instance) {
    flex-shrink: 0;
  }
}
@media (max-width: 480px) {
  .sectionHeader:global(.__wab_instance) {
    flex-shrink: 0;
  }
}
.sectionHeadercontentHidden:global(.__wab_instance) {
  flex-shrink: 0;
}
.sectionHeadersectionSettingsOpen:global(.__wab_instance) {
  margin-left: calc(-20px + 0px) !important;
  flex-shrink: 0;
}
.sectionHeaderheaderHidden:global(.__wab_instance) {
  flex-shrink: 0;
  display: none;
}
.sectionHeadereditMode:global(.__wab_instance) {
  margin-left: -20px;
  flex-shrink: 0;
}
.sectionHeaderunEditable:global(.__wab_instance) {
  flex-shrink: 0;
}
.sectionHeadereditMode_contentHidden:global(.__wab_instance) {
  flex-shrink: 0;
}
.sectionHeadereditMode_sectionSettingsOpen:global(.__wab_instance) {
  margin-left: calc(-20px + 0px) !important;
  flex-shrink: 0;
}
.text {
  font-size: 1em;
  font-family: "Open Sans", sans-serif;
  line-height: 2em;
}
.span {
  font-family: "Rubik", sans-serif;
}
.settingsPanelContainer {
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  display: none;
  padding: 0px 8px;
}
.settingsPanelContainersectionSettingsOpen {
  display: none;
}
.sectionContentContainer {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  overflow: visible;
  min-width: 0;
}
.sectionContentContainer > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: unset;
  justify-content: space-between;
  flex-wrap: wrap;
  align-content: stretch;
  min-width: 0;
  margin-left: calc(0px - 10px);
  width: calc(100% + 10px);
  margin-top: calc(0px - 24px);
  height: calc(100% + 24px);
}
.sectionContentContainer > :global(.__wab_flex-container) > *,
.sectionContentContainer
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > *,
.sectionContentContainer > :global(.__wab_flex-container) > picture > img,
.sectionContentContainer
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 10px;
  margin-top: 24px;
}
.sectionContentContainercontentHidden {
  display: none;
}
.sectionContentContainersectionSettingsOpen {
  display: flex;
}
.sectionContentContainereditMode_sectionSettingsOpen {
  display: flex;
}
