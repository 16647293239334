.root {
  display: inline-flex;
  flex-direction: column;
  position: relative;
  width: auto;
  height: auto;
}
.sourceContainer {
  width: auto;
  height: auto;
  display: flex;
  flex-direction: column;
  margin: 0.3em;
}
.sourceContainer > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-top: calc(0px - 9px);
  height: calc(100% + 9px);
}
.sourceContainer > :global(.__wab_flex-container) > *,
.sourceContainer > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.sourceContainer > :global(.__wab_flex-container) > picture > img,
.sourceContainer
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 9px;
}
.sourceContainerbaseVariant_isCheckingWebsiteSource {
  display: flex;
  flex-direction: column;
}
.sourceContainerbaseVariant_isCheckingWebsiteSource
  > :global(.__wab_flex-container) {
  flex-direction: column;
  margin-left: calc(0px - 0px);
  width: calc(100% + 0px);
  margin-top: calc(0px - 0px);
  height: calc(100% + 0px);
}
.sourceContainerbaseVariant_isCheckingWebsiteSource
  > :global(.__wab_flex-container)
  > *,
.sourceContainerbaseVariant_isCheckingWebsiteSource
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > *,
.sourceContainerbaseVariant_isCheckingWebsiteSource
  > :global(.__wab_flex-container)
  > picture
  > img,
.sourceContainerbaseVariant_isCheckingWebsiteSource
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 0px;
  margin-top: 0px;
}
.sourceContainerbaseVariant_isUnusableWebsiteSource {
  display: flex;
  flex-direction: column;
}
.sourceContainerbaseVariant_isUnusableWebsiteSource
  > :global(.__wab_flex-container) {
  flex-direction: column;
  margin-left: calc(0px - 0px);
  width: calc(100% + 0px);
  margin-top: calc(0px - 0px);
  height: calc(100% + 0px);
}
.sourceContainerbaseVariant_isUnusableWebsiteSource
  > :global(.__wab_flex-container)
  > *,
.sourceContainerbaseVariant_isUnusableWebsiteSource
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > *,
.sourceContainerbaseVariant_isUnusableWebsiteSource
  > :global(.__wab_flex-container)
  > picture
  > img,
.sourceContainerbaseVariant_isUnusableWebsiteSource
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 0px;
  margin-top: 0px;
}
.text___1RRim {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  align-self: flex-start;
  min-width: 0;
}
.textbaseVariant_isCheckingWebsiteSource___1RRimxumZ4 {
  font-size: 0.6em;
  padding-left: 8px;
  padding-right: 8px;
  color: var(--token-pMUHtTtb1);
  display: block;
}
.textbaseVariant_isUnusableWebsiteSource___1RRimiImk2 {
  font-size: 0.6em;
  padding-left: 8px;
  padding-right: 8px;
  color: var(--token-pMUHtTtb1);
  display: block;
}
.source {
  width: auto;
  height: auto;
  display: flex;
  position: relative;
  flex-direction: column;
}
.source > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: calc(0px - 0.1em);
  height: calc(100% + 0.1em);
}
.source > :global(.__wab_flex-container) > *,
.source > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.source > :global(.__wab_flex-container) > picture > img,
.source
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 0.1em;
}
.sourcebaseVariant_notAddedWithDescription > :global(.__wab_flex-container) {
  margin-left: calc(0px - 0px);
  width: calc(100% + 0px);
  margin-top: calc(0px - 0.2em);
  height: calc(100% + 0.2em);
}
.sourcebaseVariant_notAddedWithDescription > :global(.__wab_flex-container) > *,
.sourcebaseVariant_notAddedWithDescription
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > *,
.sourcebaseVariant_notAddedWithDescription
  > :global(.__wab_flex-container)
  > picture
  > img,
.sourcebaseVariant_notAddedWithDescription
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 0px;
  margin-top: 0.2em;
}
.sourcebaseVariant_isCheckingWebsiteSource {
  border-radius: 8px;
  padding: 4px;
  border: 1.8px solid var(--token-pMUHtTtb1);
}
.sourcebaseVariant_isUnusableWebsiteSource {
  border-radius: 8px;
  padding: 4px;
  border: 1.8px solid var(--token-pMUHtTtb1);
}
.sourceInner {
  width: auto;
  height: auto;
  display: flex;
  position: relative;
  flex-direction: row;
  overflow: visible;
  margin-bottom: 0px;
}
.sourceInner > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  margin-left: calc(0px - 0.4em);
  width: calc(100% + 0.4em);
}
.sourceInner > :global(.__wab_flex-container) > *,
.sourceInner > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.sourceInner > :global(.__wab_flex-container) > picture > img,
.sourceInner
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 0.4em;
}
.sourceInnerbaseVariant_editing {
  width: auto;
}
.sourceInnerbaseVariant_adjustAttention {
  width: auto;
}
.sourceAddCircle {
  position: relative;
  object-fit: fill;
  width: 0.66em;
  height: 0.7em;
  box-shadow: none;
  display: block !important;
  flex-shrink: 0;
}
.sourceAddCircle > picture > img {
  object-fit: fill;
}
.attentionIndicator:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 0.3em;
  height: 1.25em;
  flex-shrink: 0;
}
.attentionIndicatorbaseVariant_notAddedWithDescription:global(.__wab_instance) {
  display: none;
}
.attentionIndicatorbaseVariant_notAdded:global(.__wab_instance) {
  display: none;
}
.attentionIndicatorbaseVariant_added:global(.__wab_instance) {
  width: 0.6em;
  height: 1.5em;
  flex-shrink: 0;
}
.attentionIndicatorbaseVariant_editing:global(.__wab_instance) {
  width: 0.6em;
  height: 1.5em;
  flex-shrink: 0;
}
.attentionIndicatorbaseVariant_adjustAttention:global(.__wab_instance) {
  width: 0.6em;
  height: 1.5em;
  flex-shrink: 0;
}
.attentionIndicatorbaseVariant_isCheckingWebsiteSource:global(.__wab_instance) {
  width: 0.6em;
  height: 1.5em;
  flex-shrink: 0;
}
.attentionIndicatorbaseVariant_isUnusableWebsiteSource:global(.__wab_instance) {
  width: 0.6em;
  height: 1.5em;
  flex-shrink: 0;
}
.imageFromUrl {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 1.5em;
  height: 1.5em;
  flex-shrink: 0;
  border-radius: 50px;
}
.imageFromUrl > picture > img {
  object-fit: cover;
}
.sourceTexts {
  transform: translate(0px, -0.001px);
  width: auto;
  height: auto;
  display: flex;
  position: relative;
  top: auto;
  left: auto;
  flex-direction: column;
}
.title {
  position: relative;
  top: auto;
  left: auto;
  right: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.slotTargetSourceTitle {
  font-family: "Open Sans", sans-serif;
  font-size: 0.66em;
  font-weight: 600;
  color: rgba(250, 247, 238, 1);
  line-height: 1.2em;
  letter-spacing: 0px;
}
.handle {
  position: relative;
  top: auto;
  left: auto;
  right: auto;
  transform: translate(-0.5px, 0px);
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.link {
  display: flex;
  position: relative;
  flex-direction: column;
  width: auto;
  padding-bottom: 0.2em;
}
.linkhideHandle {
  display: none;
}
.slotTargetSourceHandle {
  font-family: "Open Sans", sans-serif;
  font-size: 0.5em;
  font-weight: 600;
  color: rgba(34, 128, 204, 1);
  line-height: 1.2em;
  letter-spacing: 0px;
}
.editingArea {
  display: flex;
  position: relative;
  flex-direction: row;
  width: auto;
  height: auto;
  max-width: 100%;
}
.editingArea > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
}
.editingAreabaseVariant_editing {
  display: flex;
}
.editingAreabaseVariant_editing > :global(.__wab_flex-container) {
  margin-left: calc(0px - 4px);
  width: calc(100% + 4px);
  margin-top: calc(0px - 0px);
  height: calc(100% + 0px);
}
.editingAreabaseVariant_editing > :global(.__wab_flex-container) > *,
.editingAreabaseVariant_editing
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > *,
.editingAreabaseVariant_editing
  > :global(.__wab_flex-container)
  > picture
  > img,
.editingAreabaseVariant_editing
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 4px;
  margin-top: 0px;
}
.editingAreabaseVariant_adjustAttention {
  display: flex;
}
.editingAreaeditingWithAdminAccess {
  display: flex;
}
.editingAreaeditingWithAdminAccess > :global(.__wab_flex-container) {
  margin-left: calc(0px - 4px);
  width: calc(100% + 4px);
  margin-top: calc(0px - 0px);
  height: calc(100% + 0px);
}
.editingAreaeditingWithAdminAccess > :global(.__wab_flex-container) > *,
.editingAreaeditingWithAdminAccess
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > *,
.editingAreaeditingWithAdminAccess
  > :global(.__wab_flex-container)
  > picture
  > img,
.editingAreaeditingWithAdminAccess
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 4px;
  margin-top: 0px;
}
.adjustAttentionDiv {
  display: block;
  position: relative;
}
.adjustAttentionDivbaseVariant_editing {
  height: 1em;
  width: auto;
  display: flex;
  flex-direction: row;
  padding-left: 0.38em;
  padding-right: 0.25em;
  padding-bottom: 0px;
  background: var(--token-kgwkYLWcU);
  border-radius: 0.5em;
}
.adjustAttentionDivbaseVariant_editing > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  margin-left: calc(0px - 1px);
  width: calc(100% + 1px);
  margin-top: calc(0px - 0px);
  height: calc(100% + 0px);
}
.adjustAttentionDivbaseVariant_editing > :global(.__wab_flex-container) > *,
.adjustAttentionDivbaseVariant_editing
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > *,
.adjustAttentionDivbaseVariant_editing
  > :global(.__wab_flex-container)
  > picture
  > img,
.adjustAttentionDivbaseVariant_editing
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 1px;
  margin-top: 0px;
}
.adjustAttentionDivbaseVariant_adjustAttention {
  height: 16px;
  width: auto;
  display: flex;
  flex-direction: row;
  padding-left: 6px;
  padding-right: 4px;
  padding-bottom: 0px;
  background: var(--token-kgwkYLWcU);
  border-radius: 8px;
}
.adjustAttentionDivbaseVariant_adjustAttention
  > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  margin-top: calc(0px - 0px);
  height: calc(100% + 0px);
}
.adjustAttentionDivbaseVariant_adjustAttention
  > :global(.__wab_flex-container)
  > *,
.adjustAttentionDivbaseVariant_adjustAttention
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > *,
.adjustAttentionDivbaseVariant_adjustAttention
  > :global(.__wab_flex-container)
  > picture
  > img,
.adjustAttentionDivbaseVariant_adjustAttention
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 0px;
}
.adjustAttentionText {
  position: absolute;
  left: 0px;
  top: 0px;
}
.adjustAttentionTextbaseVariant_editing {
  font-size: 0.3em;
  position: relative;
  left: auto;
  top: auto;
  margin-top: calc(0px + 0px) !important;
  margin-right: 0px;
  margin-bottom: 1px;
  margin-left: calc(0px + 1px) !important;
  color: var(--token-P44QyWYsh);
  font-weight: 500;
  width: auto;
  height: 1.4em;
  padding-bottom: 0px;
  flex-shrink: 1;
  white-space: pre;
}
.adjustAttentionTextbaseVariant_adjustAttention {
  font-size: 2px;
  position: relative;
  left: auto;
  top: auto;
  margin-top: calc(0px + 0px) !important;
  margin-right: 0px;
  margin-bottom: 1px;
  margin-left: 0px;
  color: var(--token-P44QyWYsh);
  font-weight: 500;
  width: 45px;
  height: 9px;
  padding-bottom: 0px;
  flex-shrink: 0;
}
.adjustAttentionInfoIcon {
  position: absolute;
  object-fit: cover;
  width: 10.5px;
  height: 10.5px;
  color: #222b45;
  transform: scale(1.000000499999875, 0.999999500000375)
    rotate(-0.057295760416576934deg) skew(-0.05729570311883569deg, 0deg);
  display: block;
  transform-origin: top left;
  left: 0px;
  top: 0px;
}
.adjustAttentionInfoIconbaseVariant_editing {
  position: relative;
  left: auto;
  top: auto;
  margin-top: calc(0px + 0px) !important;
  width: 0.66em;
  height: 0.66em;
  flex-shrink: 0;
}
.adjustAttentionInfoIconbaseVariant_adjustAttention {
  position: relative;
  left: auto;
  top: auto;
  margin-top: calc(0px + 0px) !important;
}
.button {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
.buttonbaseVariant_editing {
  display: flex;
  padding: 0px;
  border-width: 1px;
  border-style: none;
}
.buttonbaseVariant_adjustAttention {
  border-width: 1px;
  border-style: none;
}
.buttoneditingWithAdminAccess {
  padding: 0px;
  border-width: 1px;
  border-style: none;
}
.deleteSourceIcon {
  object-fit: cover;
  width: 0.9em;
  height: 1em;
  color: #d6455b;
  box-shadow: 0px 3.733px 18.666px 0px rgba(0, 0, 0, 0.15);
  display: block;
  overflow: hidden;
  flex-shrink: 0;
  border-radius: 7.466px;
}
.deleteSourceIconeditingWithAdminAccess {
  display: block;
}
.addInterestBtn {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
.addInterestBtnbaseVariant_editing {
  display: flex;
  padding: 0px;
  border-width: 1px;
  border-style: none;
}
.addInterestBtnbaseVariant_adjustAttention {
  display: none;
}
.addInterestBtneditingWithAdminAccess {
  padding: 0px;
  border-width: 1px;
  border-style: none;
}
.svg__rrO4K {
  object-fit: cover;
  max-width: 100%;
  height: 1em;
}
.svgbaseVariant_editing__rrO4KjnBbX {
  color: var(--token-O7e_v4mKD);
}
.svgeditingWithAdminAccess__rrO4K98Llt {
  color: var(--token-O7e_v4mKD);
}
.edit {
  transform: scale(1, -1);
  width: auto;
  height: 36px;
  display: flex;
  transform-origin: top left;
  flex-direction: row;
}
.edit > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: calc(0px - 6px);
  width: calc(100% + 6px);
}
.edit > :global(.__wab_flex-container) > *,
.edit > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.edit > :global(.__wab_flex-container) > picture > img,
.edit > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-left: 6px;
}
.adjustAttentionButton {
  width: auto;
  height: auto;
  display: flex;
  position: relative;
  flex-direction: row;
  padding-left: 6px;
  padding-right: 6px;
  background: rgba(252, 250, 246, 1);
  border-radius: 7.5px;
}
.adjustAttentionButton > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: calc(0px - 3px);
  width: calc(100% + 3px);
}
.adjustAttentionButton > :global(.__wab_flex-container) > *,
.adjustAttentionButton
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > *,
.adjustAttentionButton > :global(.__wab_flex-container) > picture > img,
.adjustAttentionButton
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 3px;
}
.adjustAttention {
  position: relative;
  width: 62px;
  height: 20px;
  transform: scale(1, -1);
  transform-origin: top left;
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: auto;
  top: auto;
  flex-shrink: 0;
}
.adjustAttentionbaseVariant_editing {
  left: auto;
  top: auto;
}
.adjustAttentionbaseVariant_adjustAttention {
  left: auto;
  top: auto;
}
.text__yigYs {
  display: block;
  font-size: 7.5px;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  letter-spacing: 0px;
  line-height: 24px;
  color: transparent;
}
.freeBox__wEmDu {
  display: block;
  position: relative;
  width: 61.5px;
  height: 19.5px;
  flex-shrink: 0;
}
.freeBox___9MibO {
  display: block;
  position: relative;
  width: 10.5px;
  height: 10.5px;
  flex-shrink: 0;
}
.svg__xLeCh {
  position: absolute;
  object-fit: cover;
  width: 10.5px;
  height: 10.5px;
  color: #222b45;
  transform: scale(1, -1);
  display: block;
  transform-origin: top left;
  left: 0px;
  top: 10.5px;
}
.freeBox__fIvr {
  display: block;
  position: relative;
  width: 14.563999999999993px;
  height: 16.622px;
  flex-shrink: 0;
}
.svg__lg79I {
  position: absolute;
  object-fit: cover;
  width: 14.564px;
  height: 16.622px;
  color: #d6455b;
  box-shadow: 0px 3.733px 18.666px 0px rgba(0, 0, 0, 0.15);
  transform: scale(1, -1);
  display: block;
  transform-origin: top left;
  overflow: hidden;
  left: 0px;
  top: 16.622px;
  border-radius: 4.977px;
}
.lottie:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  position: relative;
  width: 1.2em;
  flex-shrink: 0;
  display: none;
}
.lottiebaseVariant_isCheckingWebsiteSource:global(.__wab_instance) {
  display: flex;
}
.freeBox__je9Lb {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 12em;
  height: 3em;
  overflow: hidden;
  padding-left: 0.34em;
  padding-top: 0.1em;
  flex-shrink: 0;
}
.freeBoxbaseVariant_notAddedWithDescription__je9LbrK9Ev {
  padding-left: 0.3em;
}
.freeBoxbaseVariant_notAdded__je9LbDeW6L {
  display: none;
}
.freeBoxbaseVariant_added__je9Lb5Z0ZB {
  display: none;
}
.freeBoxbaseVariant_editing__je9LbjnBbX {
  display: none;
}
.freeBoxbaseVariant_isCheckingWebsiteSource__je9LbxumZ4 {
  display: none;
}
.freeBoxbaseVariant_isUnusableWebsiteSource__je9LbiImk2 {
  display: none;
}
.freeBoxisPlaylist__je9LbSh5F {
  max-height: 3em;
  height: auto;
}
.freeBoxeditingWithAdminAccess__je9Lb98Llt {
  display: none;
}
.slotTargetSourceDescription {
  font-family: "Open Sans", sans-serif;
  font-size: 0.5em;
  font-weight: 400;
  color: rgba(195, 195, 195, 1);
  letter-spacing: 0px;
}
.logo {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 1em;
  height: 1em;
  flex-shrink: 0;
  display: none !important;
}
.logo > picture > img {
  object-fit: cover;
}
