.root {
  display: inline-flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: auto;
  height: auto;
  max-width: 100%;
  padding: 2px 8px;
}
.rootcategory_youtube {
  height: auto;
}
.rootbig__default {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 12em;
  height: 7.5em;
  background: var(--token-QkOxHiuRM);
  box-shadow: none;
  border-radius: 0.4em;
  padding: 0.38em;
  border: 0.15em solid var(--token-QkOxHiuRM);
}
.button {
  background: rgba(250, 247, 238, 0);
  box-shadow: none;
  width: auto;
  height: auto;
  display: flex;
  overflow: hidden;
  flex-direction: row;
  flex-shrink: 1;
  flex-grow: 0;
  flex-basis: auto;
  filter: drop-shadow(0px 0px 2px #2381cdcc);
  border-radius: 0.2em;
  padding: 0.38rem;
  border: 0.15em solid var(--token-QkOxHiuRM);
}
.button > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: calc(0px - 4px);
  width: calc(100% + 4px);
}
.button > :global(.__wab_flex-container) > *,
.button > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.button > :global(.__wab_flex-container) > picture > img,
.button
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 4px;
}
.buttoncategory_youtube {
  box-shadow: none;
  filter: drop-shadow(0px 0px 2px #d6455b);
  border-color: var(--token-6Pfaiv2lJ);
}
.buttoncategory_websites {
  filter: drop-shadow(0px 0px 2px #7500bfcc);
  border-color: var(--token-O7e_v4mKD);
}
.buttoncategory_savedForLater {
  filter: drop-shadow(0px 0px 2px #5978e8);
  border-color: var(--token-pMUHtTtb1);
}
.buttoncategory_podcasts {
  filter: drop-shadow(0px 0px 2px #fdfbf799);
  border-color: var(--token-U0sHWprB5);
}
.buttoncategory_twitterHomeFeed {
  width: auto;
}
.buttoncategory_twitterHomeFeed > :global(.__wab_flex-container) {
  margin-left: calc(0px - 4px);
  width: calc(100% + 4px);
  margin-top: calc(0px - 0px);
  height: calc(100% + 0px);
}
.buttoncategory_twitterHomeFeed > :global(.__wab_flex-container) > *,
.buttoncategory_twitterHomeFeed
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > *,
.buttoncategory_twitterHomeFeed
  > :global(.__wab_flex-container)
  > picture
  > img,
.buttoncategory_twitterHomeFeed
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 4px;
  margin-top: 0px;
}
.buttoncategory_twitterMentions {
  width: auto;
}
.buttoncategory_twitterMentions > :global(.__wab_flex-container) {
  margin-left: calc(0px - 4px);
  width: calc(100% + 4px);
  margin-top: calc(0px - 0px);
  height: calc(100% + 0px);
}
.buttoncategory_twitterMentions > :global(.__wab_flex-container) > *,
.buttoncategory_twitterMentions
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > *,
.buttoncategory_twitterMentions
  > :global(.__wab_flex-container)
  > picture
  > img,
.buttoncategory_twitterMentions
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 4px;
  margin-top: 0px;
}
.buttoncategory_twitterLikes {
  width: auto;
}
.buttoncategory_twitterLikes > :global(.__wab_flex-container) {
  margin-left: calc(0px - 4px);
  width: calc(100% + 4px);
  margin-top: calc(0px - 0px);
  height: calc(100% + 0px);
}
.buttoncategory_twitterLikes > :global(.__wab_flex-container) > *,
.buttoncategory_twitterLikes
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > *,
.buttoncategory_twitterLikes > :global(.__wab_flex-container) > picture > img,
.buttoncategory_twitterLikes
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 4px;
  margin-top: 0px;
}
.buttoncategory_youtubeChannels {
  box-shadow: none;
  filter: drop-shadow(0px 0px 2px #d6455b);
  border-color: var(--token-6Pfaiv2lJ);
}
.buttoncategory_youtubePlaylists {
  box-shadow: none;
  filter: drop-shadow(0px 0px 2px #d6455b);
  border-color: var(--token-6Pfaiv2lJ);
}
.buttoncategory_spotify {
  filter: drop-shadow(0px 0px 2px #1dd35ecc);
  border-color: var(--token-eXgRgh_S6);
}
.buttoncategory_spotifyPlaylists {
  filter: drop-shadow(0px 0px 2px #1dd35ecc);
  border-color: var(--token-eXgRgh_S6);
}
.buttoncategory_spotifyMusicians {
  filter: drop-shadow(0px 0px 2px #1dd35ecc);
  border-color: var(--token-eXgRgh_S6);
}
.buttoncategory_spotifyPodcasts {
  filter: drop-shadow(0px 0px 2px #1dd35ecc);
  border-color: var(--token-eXgRgh_S6);
}
.buttoncategory_pinterest {
  filter: drop-shadow(0px 0px 2px #e60023cc);
  border-color: var(--token-ptkV0FmyR);
}
.buttoncategory_pinterestUserSavedPins {
  filter: drop-shadow(0px 0px 2px #e60023cc);
  border-color: var(--token-ptkV0FmyR);
}
.buttoncategory_pinterestUserCreatedPins {
  filter: drop-shadow(0px 0px 2px #e60023cc);
  border-color: var(--token-ptkV0FmyR);
}
.buttoncategory_pinterestBoards {
  filter: drop-shadow(0px 0px 2px #e60023cc);
  border-color: var(--token-ptkV0FmyR);
}
.buttoncategory_instagram {
  filter: drop-shadow(0px 0px 2px #ef6c2bcc);
  border-color: var(--token-LoWUr1Jcm);
}
.buttoncategory_instagramUserPosts {
  filter: drop-shadow(0px 0px 2px #ef6c2bcc);
  border-color: var(--token-LoWUr1Jcm);
}
.buttoncategory_instagramHashtags {
  filter: drop-shadow(0px 0px 2px #ef6c2bcc);
  border-color: var(--token-LoWUr1Jcm);
}
.buttoncategory_instagramSearchTerms {
  filter: drop-shadow(0px 0px 2px #ef6c2bcc);
  border-color: var(--token-LoWUr1Jcm);
}
.buttoncategory_pinterestSearchTerms {
  filter: drop-shadow(0px 0px 2px #e60023cc);
  border-color: var(--token-ptkV0FmyR);
}
.buttonbig__default {
  width: 12em;
  height: auto;
}
.freeBox__fKqJ {
  display: block;
  position: relative;
  width: 0.63em;
  height: 0.63em;
  flex-shrink: 0;
}
.freeBoxcategory_youtube__fKqJi1XPf {
  height: 5px;
}
.freeBoxcategory_websites__fKqJgXpC4 {
  margin-bottom: 0px;
  height: 0px;
}
.freeBoxcategory_savedForLater__fKqJnj5Zj {
  width: 10px;
  height: 10px;
  flex-shrink: 0;
}
.freeBoxcategory_podcasts__fKqJtlwEj {
  margin-bottom: 1px;
}
.freeBoxcategory_youtubeChannels__fKqJ6EPe {
  height: 5px;
}
.freeBoxcategory_youtubePlaylists__fKqJ2ExUn {
  height: 5px;
}
.freeBoxbig__default__fKqJ968A {
  width: 1.26em;
  height: 1.26em;
  flex-shrink: 0;
}
.svg {
  position: absolute;
  object-fit: cover;
  max-width: 100%;
  width: 0.63em;
  height: 0.63em;
  color: var(--token-QkOxHiuRM);
  left: 0px;
  top: 0px;
}
.svgcategory_twitter {
  color: var(--token-QkOxHiuRM);
}
.svgcategory_youtube {
  height: 7px;
  color: var(--token-6Pfaiv2lJ);
  top: -1px;
}
.svgcategory_websites {
  color: var(--token-O7e_v4mKD);
  height: 16px;
  top: -8px;
}
.svgcategory_savedForLater {
  color: var(--token-pMUHtTtb1);
  margin-right: 2px;
  width: 10px;
  padding-right: 0px;
  padding-top: 0px;
  left: -2px;
  height: 10px;
}
.svgcategory_podcasts {
  color: var(--token-U0sHWprB5);
  padding-top: 0px;
}
.svgcategory_twitterBookmarks {
  color: var(--token-QkOxHiuRM);
}
.svgcategory_twitterUserTimelines {
  color: var(--token-QkOxHiuRM);
}
.svgcategory_twitterSearches {
  color: var(--token-QkOxHiuRM);
}
.svgcategory_twitterLists {
  color: var(--token-QkOxHiuRM);
}
.svgcategory_youtubeChannels {
  height: 7px;
  color: var(--token-6Pfaiv2lJ);
  top: -1px;
}
.svgcategory_youtubePlaylists {
  height: 7px;
  color: var(--token-6Pfaiv2lJ);
  top: -1px;
}
.svgcategory_spotify {
  color: var(--token-eXgRgh_S6);
}
.svgcategory_spotifyPlaylists {
  color: var(--token-eXgRgh_S6);
}
.svgcategory_spotifyMusicians {
  color: var(--token-eXgRgh_S6);
}
.svgcategory_spotifyPodcasts {
  color: var(--token-eXgRgh_S6);
}
.svgcategory_pinterest {
  color: var(--token-ptkV0FmyR);
}
.svgcategory_pinterestUserSavedPins {
  color: var(--token-ptkV0FmyR);
}
.svgcategory_pinterestUserCreatedPins {
  color: var(--token-ptkV0FmyR);
}
.svgcategory_pinterestBoards {
  color: var(--token-ptkV0FmyR);
}
.svgcategory_instagram {
  color: var(--token-LoWUr1Jcm);
}
.svgcategory_instagramUserPosts {
  color: var(--token-LoWUr1Jcm);
}
.svgcategory_instagramHashtags {
  color: var(--token-LoWUr1Jcm);
}
.svgcategory_instagramSearchTerms {
  color: var(--token-LoWUr1Jcm);
}
.svgcategory_pinterestSearchTerms {
  color: var(--token-ptkV0FmyR);
}
.svgbig__default {
  height: 1.26em;
  width: 1.26em;
  color: var(--token-IG9ne-3Mr);
}
.textContainer {
  position: relative;
  width: 3em;
  height: 1.25em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
}
.textContainercategory_twitterUserTimelines {
  width: 3.4em;
  flex-shrink: 0;
}
.textContainercategory_twitterHomeFeed {
  width: 3em;
  flex-shrink: 0;
}
.textContainercategory_twitterMentions {
  width: 3em;
  flex-shrink: 0;
}
.textContainercategory_twitterLikes {
  width: 3em;
  flex-shrink: 0;
}
.textContainercategory_pinterestUserSavedPins {
  width: 3.6em;
  flex-shrink: 0;
}
.textContainercategory_pinterestUserCreatedPins {
  width: 3.8em;
  flex-shrink: 0;
}
.textContainercategory_pinterestBoards {
  width: 3.6em;
  flex-shrink: 0;
}
.textContainercategory_instagramSearchTerms {
  width: 3.5em;
  flex-shrink: 0;
}
.textContainercategory_pinterestSearchTerms {
  width: 3.6em;
  flex-shrink: 0;
}
.textContainerbig__default {
  width: 7.8em;
  height: 100%;
  flex-shrink: 0;
  min-height: 0;
}
.text__v3XwF {
  display: block;
  font-size: 0.5em;
  text-align: center;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  letter-spacing: 0px;
  line-height: 1.2em;
  color: rgba(34, 128, 204, 1);
}
.textcategory_youtube__v3XwFi1XPf {
  color: var(--token-6Pfaiv2lJ);
}
.textcategory_websites__v3XwFgXpC4 {
  color: var(--token-O7e_v4mKD);
}
.textcategory_savedForLater__v3XwFnj5Zj {
  color: var(--token-pMUHtTtb1);
}
.textcategory_podcasts__v3XwFtlwEj {
  color: var(--token-U0sHWprB5);
}
.textcategory_twitterMentions__v3XwFsUumK {
  padding-bottom: 0px;
}
.textcategory_twitterSearches__v3XwFhogXv {
  padding-bottom: 0px;
}
.textcategory_youtubeChannels__v3XwF6EPe {
  color: var(--token-6Pfaiv2lJ);
}
.textcategory_youtubePlaylists__v3XwF2ExUn {
  color: var(--token-6Pfaiv2lJ);
}
.textcategory_spotify__v3XwFPjWiv {
  color: var(--token-eXgRgh_S6);
}
.textcategory_spotifyPlaylists__v3XwFtXsP {
  color: var(--token-eXgRgh_S6);
}
.textcategory_spotifyMusicians__v3XwF6Mgpk {
  color: var(--token-eXgRgh_S6);
}
.textcategory_spotifyPodcasts__v3XwFSgQq {
  color: var(--token-eXgRgh_S6);
}
.textcategory_pinterest__v3XwFbuxKr {
  color: var(--token-ptkV0FmyR);
}
.textcategory_pinterestUserSavedPins__v3XwFnrHj {
  color: var(--token-ptkV0FmyR);
}
.textcategory_pinterestUserCreatedPins__v3XwFjFtQy {
  color: var(--token-ptkV0FmyR);
}
.textcategory_pinterestBoards__v3XwFett2O {
  color: var(--token-ptkV0FmyR);
}
.textcategory_instagram__v3XwF7IhWi {
  color: var(--token-LoWUr1Jcm);
}
.textcategory_instagramUserPosts__v3XwFzg32 {
  color: var(--token-LoWUr1Jcm);
}
.textcategory_instagramHashtags__v3XwFbMefe {
  color: var(--token-LoWUr1Jcm);
}
.textcategory_instagramSearchTerms__v3XwFzFgDw {
  color: var(--token-LoWUr1Jcm);
  padding-bottom: 0px;
}
.textcategory_pinterestSearchTerms__v3XwFXhWl0 {
  color: var(--token-ptkV0FmyR);
}
.textbig__default__v3XwF968A {
  font-size: 0.75em;
  color: var(--token-IG9ne-3Mr);
}
.freeBox__joUd0 {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
.freeBoxbig__default__joUd0968A {
  background: var(--token-IG9ne-3Mr);
  width: 8em;
  display: flex;
  border-radius: 0.4em;
}
.text__fljUv {
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: 0.8em;
  text-align: center;
  min-width: 0;
  display: none;
}
.textbig__default__fljUv968A {
  display: block;
}
