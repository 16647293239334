.root {
  display: inline-flex;
  flex-direction: column;
  position: relative;
  width: auto;
  height: auto;
}
.rootdisplayMode_list {
  width: auto;
  justify-self: flex-start;
  max-width: 500px;
  height: auto;
  display: inline-flex;
}
.rootdisplayMode_list_global_theme_dark {
  min-width: 500px;
  max-width: 800px;
}
.text__e0YmX {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  text-align: center;
  font-size: 0.6em;
  font-weight: 600;
  color: var(--token-NbV8yISxh);
  line-height: 2em;
  min-width: 0;
  display: none;
}
.textglobal_theme_dark__e0YmXoIz4X {
  color: var(--token-7YSTg8Ece);
}
.textcomposition_reply__e0YmXZd4Tc {
  display: block;
}
.card {
  filter: drop-shadow(0px 2px 20px rgba(0, 0, 0, 0.1));
  width: 21em;
  height: auto;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  background: rgba(253, 252, 251, 1);
  border-radius: 10px;
  padding: 0.87em 0.87em 0.6em;
  border: 1px solid #c4c4c433;
}
.card > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  align-content: unset;
  margin-top: calc(0px - 10px);
  height: calc(100% + 10px);
}
.card > :global(.__wab_flex-container) > *,
.card > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.card > :global(.__wab_flex-container) > picture > img,
.card > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-top: 10px;
}
.cardglobal_theme_dark {
  background: linear-gradient(
      193deg,
      #353132 14%,
      #2b2627 70%,
      #353132 117%,
      #2a2526 232%
    )
    0% 0% / 100% 100% no-repeat padding-box border-box scroll;
  border-width: 0.2px;
  border-color: #c4c4c433;
}
.carddisplayMode_list {
  width: 31.25em;
  background: none;
  min-width: 50px;
  border-width: 0px;
}
.carddisplayMode_list_global_theme_dark {
  background: none;
}
.cardcomposition_reply {
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
}
.cardcomposition_collapsedThread {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border-color: #c4c4c433;
}
.cardcomposition_original {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border-color: #c4c4c433;
}
.cardposition_top {
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
}
.cardposition_middle {
  border-radius: 0px;
}
.cardposition_bottom {
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
}
.twitterUserContainer {
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  top: auto;
  left: auto;
  flex-direction: row;
  min-width: 0;
}
.twitterUserContainer > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
}
.twitterUserContainer > :global(.__wab_flex-container) > *,
.twitterUserContainer
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > *,
.twitterUserContainer > :global(.__wab_flex-container) > picture > img,
.twitterUserContainer
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 8px;
}
.twitterUserContainercomposition_collapseImage {
  left: auto;
  top: auto;
}
.twitterUserContainercomposition_reply {
  left: auto;
  top: auto;
}
.twitterUserImage {
  position: relative;
  object-fit: cover;
  width: 1.87em;
  height: 1.87em;
  display: block !important;
  flex-shrink: 0;
  border-radius: 50px;
}
.twitterUserImage > picture > img {
  object-fit: cover;
}
.twitterUserData {
  height: 100%;
  display: flex;
  position: relative;
  flex-grow: 1;
  flex-direction: column;
  min-height: 0;
}
.twitterUserDatadisplayMode_list {
  display: flex;
  height: 100%;
  flex-grow: 1;
  flex-shrink: 0;
  margin-top: auto;
  margin-right: auto;
  margin-bottom: auto;
  margin-left: auto !important;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  align-self: auto;
  flex-wrap: nowrap;
  align-content: stretch;
  position: relative;
  left: auto;
  top: auto;
  z-index: 1;
  min-height: 0;
  padding: 0px 0px 0px 1em;
}
.freeBox___29UrG {
  display: flex;
  position: relative;
  top: auto;
  left: auto;
  width: 100%;
  min-width: 0;
}
.freeBoxdisplayMode_list___29UrGHmOQw {
  display: flex;
  left: auto;
  top: auto;
  position: relative;
  flex-direction: row;
  padding-right: 0em;
  justify-content: flex-start;
  align-items: center;
  margin-left: 0px;
  width: auto;
  margin-right: 1em;
}
.slotTargetTwitterUserName {
  font-family: "Open Sans", sans-serif;
  font-size: 0.88em;
  font-weight: 600;
  color: rgba(64, 60, 60, 1);
  line-height: 1.3em;
  letter-spacing: 0px;
}
.slotTargetTwitterUserNameglobal_theme_dark {
  color: var(--token-kgwkYLWcU);
}
.textdisplayMode_list__vQy2QHmOQw {
  position: relative;
  left: auto;
  top: auto;
}
.twitterUserProfileLink {
  display: flex;
  position: relative;
  top: auto;
  left: auto;
  width: 70%;
  transform: translate(-0.001px, -0.07900000000000063px);
  flex-direction: row;
}
.twitterUserProfileLinkglobal_theme_dark {
  height: auto;
}
.twitterUserProfileLinkdisplayMode_list {
  left: auto;
  top: auto;
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: row;
  position: relative;
  min-width: 0;
  margin: auto;
}
.slotTargetTwitterHandle {
  font-family: "Open Sans", sans-serif;
  font-size: 0.66em;
  font-weight: 600;
  color: var(--token-yt3rDB0KD);
  letter-spacing: 0px;
}
.slotTargetTwitterHandleglobal_theme_dark {
  color: var(--token-pMUHtTtb1);
}
.textdisplayMode_list__iuNxwHmOQw {
  position: relative;
  left: auto;
  top: auto;
}
.tweetButton:global(.__wab_instance) {
  position: relative;
}
.text {
  display: flex;
  align-self: auto;
  position: relative;
  flex-direction: row;
  padding-right: 0.62em;
  padding-left: 0.62em;
  width: 100%;
  max-width: 100%;
  min-width: 0;
}
.textglobal_theme_dark {
  margin-right: 0.62em;
}
.slotTargetText {
  font-family: "Lora", serif;
  font-size: 0.75em;
  font-weight: 400;
  color: var(--token-FmckP9B6G);
  line-height: 1.3em;
  letter-spacing: 0px;
  white-space: pre-wrap;
}
.slotTargetTextglobal_theme_dark {
  color: var(--token-U0sHWprB5);
}
.showImage {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
}
.showImagecomposition_collapseImage {
  align-self: flex-end;
  padding-right: 16px;
  height: 21px;
  flex-shrink: 0;
}
.svg {
  position: relative;
  object-fit: cover;
  width: 32.313px;
  height: 35.715px;
  flex-shrink: 0;
}
.svgcomposition_collapseImage {
  height: 1em;
  color: var(--token-yt3rDB0KD);
  width: 1.25em;
  flex-shrink: 0;
}
.text__we3Dn {
  position: relative;
}
.textcomposition_collapseImage__we3DNmeBUw {
  font-size: 0.62em;
  color: var(--token-yt3rDB0KD);
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
}
.twitterImages:global(.__wab_instance) {
  position: relative;
  top: auto;
  left: auto;
}
.twitterImagesdisplayMode_list:global(.__wab_instance) {
  align-self: flex-start;
}
.imageContainer {
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  height: auto;
  width: 100%;
  max-height: 10em;
  min-width: 0;
  display: none;
}
.imageContainerdisplayMode_list {
  display: none;
}
.img__pBIcY {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 100%;
  flex-grow: 1;
  flex-shrink: 1;
  min-width: 0;
  border-radius: 6px;
}
.img__pBIcY > picture > img {
  object-fit: cover;
}
.linkPreviewContainer {
  width: auto;
  max-width: 100%;
  flex-direction: row;
  display: none;
}
.linkPreviewContainercomposition_linkPreview {
  display: flex;
}
.metaData {
  width: 100%;
  height: 0.9em;
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-content: unset;
  flex-wrap: nowrap;
  opacity: 0.9;
  min-width: 0;
  flex-shrink: 0;
  padding: 2px;
}
.engagementData {
  transform: translate(0px, 0.31899999999999995px);
  width: auto;
  height: auto;
  display: flex;
  position: relative;
  top: auto;
  left: auto;
  flex-direction: row;
}
.engagementData > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  margin-left: calc(0px - 20px);
  width: calc(100% + 20px);
}
.engagementData > :global(.__wab_flex-container) > *,
.engagementData > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.engagementData > :global(.__wab_flex-container) > picture > img,
.engagementData
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 20px;
}
.favouritesFrame {
  width: 100%;
  height: 1em;
  display: flex;
  position: relative;
  flex-direction: row;
  min-width: 0;
}
.favouritesFrame > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-width: 0;
  margin-left: calc(0px - 1px);
  width: calc(100% + 1px);
}
.favouritesFrame > :global(.__wab_flex-container) > *,
.favouritesFrame > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.favouritesFrame > :global(.__wab_flex-container) > picture > img,
.favouritesFrame
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 1px;
}
.favouritesFrameglobal_theme_dark {
  display: flex;
  flex-direction: row;
}
.favouritesFrameglobal_theme_dark > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: calc(0px - 1px);
  width: calc(100% + 1px);
  margin-top: calc(0px - 0px);
  height: calc(100% + 0px);
}
.favouritesFrameglobal_theme_dark > :global(.__wab_flex-container) > *,
.favouritesFrameglobal_theme_dark
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > *,
.favouritesFrameglobal_theme_dark
  > :global(.__wab_flex-container)
  > picture
  > img,
.favouritesFrameglobal_theme_dark
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 1px;
  margin-top: 0px;
}
.favouritesFramecomposition_collapseImage {
  height: 1em;
  width: 1.88em;
  flex-shrink: 0;
}
.heartIcon {
  position: relative;
  object-fit: cover;
  width: 0.5em;
  height: 0.5em;
  color: #979797;
  transform: translate(-0.001px, 0.051000000000000156px);
  display: block;
  top: auto;
  left: auto;
  flex-shrink: 0;
}
.heartIconglobal_theme_dark {
  color: var(--token-7YSTg8Ece);
  position: relative;
  left: auto;
  top: auto;
}
.favouritesContainer {
  position: relative;
  top: auto;
  left: auto;
  width: auto;
  height: 1em;
  transform: translate(0.3309999999999995px, -0.001px);
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.favouritesContainerglobal_theme_dark {
  position: relative;
  left: auto;
  top: auto;
}
.favouritesContainercomposition_collapseImage {
  width: auto;
  height: 100%;
}
.slotTargetFavourites {
  font-family: "Open Sans", sans-serif;
  font-size: 0.62em;
  font-weight: 600;
  color: rgba(150, 150, 150, 1);
  letter-spacing: 0px;
}
.slotTargetFavouritesglobal_theme_dark {
  color: var(--token-7YSTg8Ece);
}
.retweetFrame {
  width: auto;
  height: 1em;
  display: flex;
  position: relative;
  flex-direction: row;
}
.retweetFrame > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: calc(0px - 4px);
  width: calc(100% + 4px);
}
.retweetFrame > :global(.__wab_flex-container) > *,
.retweetFrame > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.retweetFrame > :global(.__wab_flex-container) > picture > img,
.retweetFrame
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 4px;
}
.retweetFrameglobal_theme_dark {
  display: flex;
  flex-direction: row;
  width: auto;
}
.retweetFrameglobal_theme_dark > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: calc(0px - 4px);
  width: calc(100% + 4px);
  margin-top: calc(0px - 0px);
  height: calc(100% + 0px);
}
.retweetFrameglobal_theme_dark > :global(.__wab_flex-container) > *,
.retweetFrameglobal_theme_dark
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > *,
.retweetFrameglobal_theme_dark > :global(.__wab_flex-container) > picture > img,
.retweetFrameglobal_theme_dark
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 4px;
  margin-top: 0px;
}
.retweetFramecomposition_collapseImage {
  width: 1.88em;
  height: 1em;
  flex-shrink: 0;
}
.repeatIcon {
  position: relative;
  object-fit: cover;
  width: 0.5em;
  height: 0.5em;
  color: #979797;
  transform: translate(0px, -0.19499999999999984px);
  display: block;
  top: auto;
  left: auto;
  flex-shrink: 0;
}
.repeatIconglobal_theme_dark {
  color: var(--token-7YSTg8Ece);
  position: relative;
  left: auto;
  top: auto;
}
.retweetContainer {
  position: relative;
  top: auto;
  left: auto;
  width: 57.63%;
  height: 104.16%;
  transform: translate(-0.4550000000000054px, -0.001px);
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.retweetContainerglobal_theme_dark {
  position: relative;
  left: auto;
  top: auto;
}
.retweetContainercomposition_collapseImage {
  height: 100%;
}
.slotTargetRetweets {
  font-family: "Open Sans", sans-serif;
  font-size: 0.62em;
  font-weight: 600;
  color: rgba(150, 150, 150, 1);
  letter-spacing: 0px;
}
.slotTargetRetweetsglobal_theme_dark {
  color: var(--token-7YSTg8Ece);
}
.datePublished {
  width: auto;
  height: auto;
  display: flex;
  position: relative;
  top: auto;
  left: auto;
  flex-direction: row;
}
.datePublished > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: calc(0px - 10px);
  width: calc(100% + 10px);
}
.datePublished > :global(.__wab_flex-container) > *,
.datePublished > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.datePublished > :global(.__wab_flex-container) > picture > img,
.datePublished
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 10px;
}
.dateContainer {
  position: relative;
  width: auto;
  height: 1em;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.dateContainercomposition_collapseImage {
  width: auto;
}
.slotTargetDate {
  font-family: "Open Sans", sans-serif;
  font-size: 0.62em;
  font-weight: 600;
  color: rgba(150, 150, 150, 1);
  text-align: right;
  letter-spacing: 0px;
}
.slotTargetDateglobal_theme_dark {
  color: var(--token-7YSTg8Ece);
}
.timeContainer {
  position: relative;
  width: auto;
  height: 1em;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.timeContainercomposition_collapseImage {
  height: 1em;
}
.slotTargetTime {
  font-family: "Open Sans", sans-serif;
  font-size: 0.62em;
  font-weight: 600;
  color: rgba(150, 150, 150, 1);
  text-align: right;
  letter-spacing: 0px;
}
.slotTargetTimeglobal_theme_dark {
  color: var(--token-7YSTg8Ece);
}
.itemDivider:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  height: 0px;
  flex-shrink: 0;
  display: none;
  margin: -1em 0em 0em;
}
.itemDividerdisplayMode_list:global(.__wab_instance) {
  width: 100%;
  min-width: 0;
  display: flex;
}
.itemDividerdisplayMode_list_global_theme_dark:global(.__wab_instance) {
  margin-top: 1em;
  margin-bottom: 1em;
}
.freeBox__ow1Pp {
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  border-bottom-right-radius: 0.62em;
  border-bottom-left-radius: 0.62em;
  background: #f7f4ee;
  flex-wrap: nowrap;
  margin-top: 0.3em;
  margin-bottom: 0.3em;
  min-width: 0;
  display: none;
  padding: 0.6em;
  border: 0.2px solid #c4c4c433;
}
.freeBoxglobal_theme_dark__ow1PpOIz4X {
  background: linear-gradient(223deg, #201d1e 0%, #211d1e 100%);
  padding: 0.6em;
  border-color: #4d4d4d80;
}
.freeBoxcomposition_collapsedThread__ow1PpLWvQ {
  display: flex;
}
.text__hu7Vu {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  text-align: center;
  font-size: 0.6em;
  color: var(--token-pMUHtTtb1);
  font-weight: 600;
  min-width: 0;
}
.textglobal_theme_dark__hu7VuOIz4X {
  font-size: 0.6em;
  font-weight: 600;
}
