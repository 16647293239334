.root {
  width: 100%;
  height: 30px;
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-content: unset;
  transition-property: all;
  transition-duration: 1s;
  overflow: visible;
  -webkit-transition-property: all;
  -webkit-transition-duration: 1s;
}
.rootvariant_creating {
  align-items: center;
  justify-content: flex-end;
}
.sectionTitleContainer {
  width: auto;
  height: auto;
  display: flex;
  position: relative;
  top: auto;
  left: auto;
  overflow: visible;
  flex-direction: row;
  max-height: 2em;
  flex-grow: 1;
  padding: 0.63em;
}
.sectionTitleContainer > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: calc(0px - 18px);
  width: calc(100% + 18px);
}
.sectionTitleContainer > :global(.__wab_flex-container) > *,
.sectionTitleContainer
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > *,
.sectionTitleContainer > :global(.__wab_flex-container) > picture > img,
.sectionTitleContainer
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 18px;
}
.sectionTitleContainervariant_sectionSettings {
  padding-left: 0.63em;
  margin-left: 0.87em;
}
.sectionTitleContainervariant_editingTitle {
  padding-left: 0.63em;
  margin-left: 0.87em;
}
.sectionTitleContainervariant_divider {
  display: none;
}
.leftHandAction {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: auto;
  height: auto;
  max-width: 100%;
  padding: 0px;
}
.leftHandActionvariant_editMode {
  display: flex;
  padding: 8px;
}
.leftHandActionvariant_sectionSettings {
  display: flex;
}
.leftHandActionvariant_editingTitle {
  display: flex;
}
.leftHandActionvariant_divider {
  display: none;
  padding: 8px;
}
.tooltip:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  position: relative;
  display: none;
}
.tooltipvariant_unEditable:global(.__wab_instance) {
  display: flex;
}
.text__amcO4 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  display: none;
}
.textvariant_unEditable__amcO49XJtB {
  display: none;
}
.sourceCategoryIcon:global(.__wab_instance) {
  position: relative;
}
.sourceCategoryIconvariant_editMode:global(.__wab_instance) {
  display: flex;
}
.sourceCategoryIconvariant_divider:global(.__wab_instance) {
  display: none;
}
.save {
  display: block;
  font-size: 13px;
  text-align: right;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  letter-spacing: 0px;
  line-height: 19.535px;
  color: rgba(34, 128, 204, 1);
}
.savevariant_sectionSettings {
  color: var(--token-yt3rDB0KD);
}
.saveglobal_theme_dark_variant_sectionSettings {
  color: var(--token-pMUHtTtb1);
  filter: drop-shadow(0px 0px 6px #4062dd);
  font-size: 0.8em;
  line-height: 1.25em;
}
.savevariant_editingTitle {
  color: var(--token-yt3rDB0KD);
  display: block;
}
.saveglobal_theme_dark_variant_editingTitle {
  color: var(--token-pMUHtTtb1);
  filter: drop-shadow(0px 0px 6px #4062dd);
  font-size: 0.8em;
  line-height: 1.25em;
}
.svg__f0Ad4 {
  object-fit: none;
  width: 2.914px;
  height: 14.406px;
  color: #585858;
  display: block;
  transform-origin: top left;
  transform: scale(1, -1) rotate(-180deg)
    translate(-0.006000000000000227px, 0.316px);
  flex-shrink: 0;
}
.sourceCategoryIcon2:global(.__wab_instance) {
  position: relative;
}
.sourceCategoryIcon2variant_editMode:global(.__wab_instance) {
  display: flex;
}
.sourceCategoryIcon2variant_divider:global(.__wab_instance) {
  display: none;
}
.sourceCategoryIcon2variant_unEditable:global(.__wab_instance) {
  display: none;
}
.titleBox {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  transition-property: opacity;
  transition-duration: 1.2s;
  transition-timing-function: ease-in-out;
  min-width: 0;
  -webkit-transition-property: opacity;
  -webkit-transition-timing-function: ease-in-out;
  -webkit-transition-duration: 1.2s;
}
.titleBox > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  min-width: 0;
}
.titleBoxvariant_sectionSettings > :global(.__wab_flex-container) {
  margin-left: calc(0px - 20px);
  width: calc(100% + 20px);
  margin-top: calc(0px - 0px);
  height: calc(100% + 0px);
}
.titleBoxvariant_sectionSettings > :global(.__wab_flex-container) > *,
.titleBoxvariant_sectionSettings
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > *,
.titleBoxvariant_sectionSettings
  > :global(.__wab_flex-container)
  > picture
  > img,
.titleBoxvariant_sectionSettings
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 20px;
  margin-top: 0px;
}
.titleBoxvariant_divider {
  display: none;
}
.titleBoxhideTitle_variant__default {
  opacity: 0;
}
.root:hover .titleBox > :global(.__wab_flex-container) {
  margin-left: calc(0px - 20px);
  width: calc(100% + 20px);
  margin-top: calc(0px - 0px);
  height: calc(100% + 0px);
}
.root:hover .titleBox > :global(.__wab_flex-container) > *,
.root:hover
  .titleBox
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > *,
.root:hover .titleBox > :global(.__wab_flex-container) > picture > img,
.root:hover
  .titleBox
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 20px;
  margin-top: 0px;
}
.inputComponent:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.inputComponentvariant_sectionSettings:global(.__wab_instance) {
  display: none;
}
.inputComponentvariant_editingTitle:global(.__wab_instance) {
  display: flex;
}
.svg__mWb4M {
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
  max-width: 80%;
}
.svg__uoJrs {
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
  max-width: 80%;
  margin: 2px 0px 0px;
}
.slotTargetSectionTitle {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 1.13em;
  font-weight: 600;
  font-style: italic;
  color: rgba(87, 87, 87, 1);
  line-height: 1.8em;
  letter-spacing: 0px;
  white-space: pre;
}
.slotTargetSectionTitle > *,
.slotTargetSectionTitle > :global(.__wab_slot) > *,
.slotTargetSectionTitle > :global(.__wab_slot) > :global(.__wab_slot) > *,
.slotTargetSectionTitle
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > *,
.slotTargetSectionTitle > picture > img,
.slotTargetSectionTitle > :global(.__wab_slot) > picture > img,
.slotTargetSectionTitle
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > picture
  > img,
.slotTargetSectionTitle
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > picture
  > img {
  transition-property: opacity;
  transition-duration: 1.4s;
  transition-timing-function: ease-in;
  -webkit-transition-property: opacity;
  -webkit-transition-timing-function: ease-in;
  -webkit-transition-duration: 1.4s;
}
.slotTargetSectionTitleglobal_theme_dark {
  color: var(--token-kgwkYLWcU);
}
.slotTargetSectionTitlevariant_divider > *,
.slotTargetSectionTitlevariant_divider > :global(.__wab_slot) > *,
.slotTargetSectionTitlevariant_divider
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > *,
.slotTargetSectionTitlevariant_divider
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > *,
.slotTargetSectionTitlevariant_divider > picture > img,
.slotTargetSectionTitlevariant_divider > :global(.__wab_slot) > picture > img,
.slotTargetSectionTitlevariant_divider
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > picture
  > img,
.slotTargetSectionTitlevariant_divider
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > picture
  > img {
  display: none;
}
.editTitle {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
.editTitlevariant_sectionSettings {
  align-self: center;
  margin-left: calc(0em + 20px) !important;
  width: auto;
  margin-top: calc(0px + 0px) !important;
  margin-right: 0px;
  margin-bottom: 0px;
  display: flex;
  padding: 0.25em;
}
.editTitlevariant_editingTitle {
  display: none;
}
.root:hover .editTitle {
  align-self: center;
  margin-left: calc(0em + 20px) !important;
  width: auto;
  margin-top: calc(0px + 0px) !important;
  margin-right: 0px;
  margin-bottom: 0px;
  display: flex;
  padding: 0.25em;
}
.rootvariant__default:hover .editTitlevariant__default {
  margin-left: calc(0em + 20px) !important;
  margin-top: calc(0px + 0px) !important;
  display: flex;
}
.rootvariant_sectionSettings:hover .editTitlevariant_sectionSettings {
  margin-left: calc(0em + 20px) !important;
  margin-top: calc(0px + 0px) !important;
}
.rootvariant_editingTitle:hover .editTitlevariant_editingTitle {
  margin-left: calc(0em + 20px) !important;
  margin-top: calc(0px + 0px) !important;
  display: none;
}
.svg__bmHAm {
  object-fit: cover;
  max-width: 100%;
  height: 1em;
  display: none;
}
.svgvariant_sectionSettings__bmHAmyRb5U {
  color: var(--token-pMUHtTtb1);
  display: block;
}
.root:hover .svg__bmHAm {
  max-width: 100%;
  color: var(--token-pMUHtTtb1);
  display: block;
}
.text__ex4EO {
  text-shadow: 0px 0px 2px rgba(77, 109, 223, 1);
  display: block;
  font-size: 13px;
  text-align: right;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  letter-spacing: 0px;
  line-height: 19.535px;
  color: rgba(219, 125, 49, 1);
  width: 43px;
  flex-shrink: 0;
}
.textglobal_theme_dark__ex4EOoIz4X {
  color: var(--token-pMUHtTtb1);
  width: auto;
  display: none;
}
.rightHandAction {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: auto;
  height: auto;
  max-width: 100%;
  top: auto;
  left: auto;
  transition-property: all;
  transition-duration: 1s;
  align-self: center;
  -webkit-transition-property: all;
  -webkit-transition-duration: 1s;
}
.rightHandActionglobal_theme_dark {
  align-self: flex-start;
}
.rightHandActionvariant__default {
  left: auto;
}
.rightHandActionvariant_editMode {
  left: auto;
  right: 0%;
  justify-content: center;
  align-items: center;
}
.rightHandActionvariant_sectionSettings {
  left: auto;
}
.rightHandActionglobal_theme_dark_variant_sectionSettings {
  align-self: center;
}
.rightHandActionvariant_editingTitle {
  left: auto;
}
.rightHandActionglobal_theme_dark_variant_editingTitle {
  align-self: center;
}
.rightHandActionglobal_theme_dark_variant_creating {
  align-self: center;
}
.rightHandActionvariant_divider {
  left: auto;
  right: 0%;
  justify-content: center;
  align-items: center;
  display: none;
}
.glowingText:global(.__wab_instance) {
  margin-top: 0.3em;
}
.glowingTextvariant_editMode:global(.__wab_instance) {
  align-self: center;
}
.glowingTextvariant_divider:global(.__wab_instance) {
  align-self: center;
  display: none;
}
.dragHandle:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  margin-left: 1em;
  transform: scaleX(1.4) scaleY(1.4) scaleZ(1);
  display: none;
}
.dragHandlevariant_editMode:global(.__wab_instance) {
  display: flex;
}
.dragHandlevariant_divider:global(.__wab_instance) {
  display: flex;
}
