.root {
  display: inline-flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  width: auto;
  height: auto;
  max-width: 100%;
  position: relative;
  padding: 0px;
}
.svg {
  object-fit: cover;
  max-width: 100%;
  width: 1.3em;
  height: 1.3em;
  color: var(--token-WkUIkx5-J);
  flex-shrink: 0;
}
.root:hover .svg {
  color: var(--token-QkOxHiuRM);
}
