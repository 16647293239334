.root {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  align-content: unset;
  width: 100%;
  height: 100%;
  position: relative;
  min-width: 0;
  min-height: 0;
}
.leftArrow {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  margin-top: 30px;
  height: 100%;
  width: auto;
  min-height: 0;
}
.leftArrowsmall {
  margin: 0px;
}
.left {
  object-fit: cover;
  width: 1.4em;
  height: 100%;
  color: #535353;
  flex-shrink: 0;
  min-height: 0;
}
.leftglobal_theme_dark {
  color: var(--token-IG9ne-3Mr);
}
.leftleftHidden {
  opacity: 0;
}
.leftsmall {
  width: 1em;
  color: var(--token-IG9ne-3Mr);
  flex-shrink: 0;
}
.rightArrow {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  margin-top: 30px;
  height: 100%;
  width: auto;
  min-height: 0;
}
.rightArrowsmall {
  margin: 0px;
}
.right {
  object-fit: cover;
  width: 1.4em;
  height: 100%;
  color: #535353;
  flex-shrink: 0;
  min-height: 0;
}
.rightglobal_theme_dark {
  color: var(--token-IG9ne-3Mr);
}
.rightrightHidden {
  opacity: 0;
}
.rightsmall {
  width: 1em;
  color: var(--token-IG9ne-3Mr);
  flex-shrink: 0;
}
