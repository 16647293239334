.root {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: auto;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 60px;
}
.container {
  display: flex;
  position: relative;
  width: 382px;
  height: 750px;
  flex-direction: column;
  margin-top: 4em;
  flex-shrink: 0;
}
.container > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: calc(0px - 28px);
  height: calc(100% + 28px);
}
.container > :global(.__wab_flex-container) > *,
.container > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.container > :global(.__wab_flex-container) > picture > img,
.container
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 28px;
}
.logoAndText {
  width: 155px;
  height: 37px;
  display: flex;
  position: relative;
  left: auto;
  top: auto;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}
.img__t4Xg1 {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  display: none !important;
}
.img__t4Xg1 > picture > img {
  object-fit: cover;
}
.logo:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.form {
  width: auto;
  height: auto;
  display: flex;
  flex-direction: column;
  position: relative;
  left: auto;
  top: auto;
}
.form > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: calc(0px - 23px);
  height: calc(100% + 23px);
}
.form > :global(.__wab_flex-container) > *,
.form > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.form > :global(.__wab_flex-container) > picture > img,
.form > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-top: 23px;
}
.top {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 0px;
}
.proProLabel {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  margin-bottom: -8px;
  min-width: 0;
  padding: 0px;
}
.proProLabelmode_signUp {
  margin-bottom: -8px;
  width: auto;
  justify-content: center;
  align-items: center;
  padding: 0px;
}
.img__rF4Sv {
  position: relative;
  object-fit: cover;
  max-width: 80%;
}
.img__rF4Sv > picture > img {
  object-fit: cover;
}
.topContent {
  filter: drop-shadow(5px 10px 15px rgba(0, 0, 0, 0.5));
  width: auto;
  height: auto;
  display: flex;
  background: linear-gradient(211deg, #353132f7 0%, #2b2627ed 100%) 0% 0% / 100%
    100% repeat;
  flex-direction: column;
  border-radius: 10.546px;
  padding: 14px 28px;
}
.topContent > :global(.__wab_flex-container) {
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: calc(0px - 16px);
  height: calc(100% + 16px);
}
.topContent > :global(.__wab_flex-container) > *,
.topContent > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.topContent > :global(.__wab_flex-container) > picture > img,
.topContent
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 16px;
}
.space {
  width: 76px;
  height: 1px;
  display: block;
  position: relative;
  flex-shrink: 0;
}
.loginTable {
  width: 326px;
  height: auto;
  display: flex;
  position: relative;
  overflow: hidden;
  flex-direction: column;
  padding: 10px;
}
.loginTable > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: calc(0px - 10px);
  height: calc(100% + 10px);
}
.loginTable > :global(.__wab_flex-container) > *,
.loginTable > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.loginTable > :global(.__wab_flex-container) > picture > img,
.loginTable
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 10px;
}
.nameField {
  background: url("https://site-assets.plasmic.app/e8bf58a9219fc6bdf70f071560cd2c21.png")
      0% 0% / 94px 127px repeat,
    rgba(34, 30, 31, 0.8);
  box-shadow: inset 0px 4px 4px 0px rgba(0, 0, 0, 0.2);
  filter: drop-shadow(0px 1px 2px rgba(255, 255, 255, 0.059));
  height: auto;
  display: flex;
  position: relative;
  align-self: stretch;
  overflow: hidden;
  flex-direction: row;
  border-radius: 4px;
  padding: 10px;
}
.nameField > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  margin-left: calc(0px - 10px);
  width: calc(100% + 10px);
}
.nameField > :global(.__wab_flex-container) > *,
.nameField > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.nameField > :global(.__wab_flex-container) > picture > img,
.nameField
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 10px;
}
.nameInput:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__f6E8M {
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
  max-width: 80%;
}
.svg__fnqpt {
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
  max-width: 80%;
  margin: 2px 0px 0px;
}
.emailField {
  background: url("https://site-assets.plasmic.app/e8bf58a9219fc6bdf70f071560cd2c21.png")
      0% 0% / 94px 127px repeat,
    rgba(34, 30, 31, 0.8);
  box-shadow: inset 0px 4px 4px 0px rgba(0, 0, 0, 0.2);
  filter: drop-shadow(0px 1px 2px rgba(255, 255, 255, 0.059));
  height: auto;
  display: flex;
  position: relative;
  align-self: stretch;
  overflow: hidden;
  flex-direction: row;
  border-radius: 4px;
  padding: 10px;
}
.emailField > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  margin-left: calc(0px - 10px);
  width: calc(100% + 10px);
}
.emailField > :global(.__wab_flex-container) > *,
.emailField > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.emailField > :global(.__wab_flex-container) > picture > img,
.emailField
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 10px;
}
.emailInput:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg___0KHx {
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
  max-width: 80%;
}
.svg___6Bv5C {
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
  max-width: 80%;
  margin: 2px 0px 0px;
}
.passwordField {
  background: url("https://site-assets.plasmic.app/e8bf58a9219fc6bdf70f071560cd2c21.png")
      0% 0% / 94px 127px repeat,
    rgba(34, 30, 31, 0.8);
  box-shadow: inset 0px 4px 4px 0px rgba(0, 0, 0, 0.2);
  filter: drop-shadow(0px 1px 2px rgba(255, 255, 255, 0.059));
  height: auto;
  display: flex;
  position: relative;
  align-self: stretch;
  overflow: hidden;
  flex-direction: row;
  border-radius: 4px;
  padding: 10px;
}
.passwordField > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  margin-left: calc(0px - 10px);
  width: calc(100% + 10px);
}
.passwordField > :global(.__wab_flex-container) > *,
.passwordField > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.passwordField > :global(.__wab_flex-container) > picture > img,
.passwordField
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 10px;
}
.passwordFieldmode_magic {
  display: none;
}
.passwordInput:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__vs4Y5 {
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
  max-width: 80%;
}
.svg__gSgSg {
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
  max-width: 80%;
  margin: 2px 0px 0px;
}
.signInButton:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  margin-right: auto;
  margin-left: auto;
  margin-top: calc(1em + 10px) !important;
}
.signInButtonmode_signIn:global(.__wab_instance) {
  margin-top: calc(1em + 10px) !important;
}
.signInButtonmode_signUp:global(.__wab_instance) {
  margin-top: calc(1em + 10px) !important;
}
.signInButtonmode_magic:global(.__wab_instance) {
  margin-top: calc(1em + 10px) !important;
}
.svg__cj8D {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.textmode_signUp__iSkZvDiTvY {
  font-size: 14px;
}
.svg___1FcsS {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.space2 {
  width: 76px;
  height: 1px;
  display: block;
  position: relative;
  flex-shrink: 0;
}
.svg__q9Gh9 {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  color: #231f20;
  width: 150px;
  height: 2px;
  display: block;
  flex-shrink: 0;
}
.orSignUpSignInWithOneOfTheseSocialNetworks {
  position: relative;
  width: 301px;
  height: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
}
.text__yYutg {
  display: block;
  font-size: 11.16px;
  text-align: center;
  letter-spacing: 0px;
  line-height: 17.857px;
  color: rgba(195, 195, 195, 1);
}
.signInPlatformsButtons {
  width: auto;
  height: auto;
  display: flex;
  position: relative;
  flex-direction: row;
}
.signInPlatformsButtons > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  margin-left: calc(0px - 10px);
  width: calc(100% + 10px);
}
.signInPlatformsButtons > :global(.__wab_flex-container) > *,
.signInPlatformsButtons
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > *,
.signInPlatformsButtons > :global(.__wab_flex-container) > picture > img,
.signInPlatformsButtons
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 10px;
}
.facebookAuthButton {
  width: 78px;
  height: 55px;
  display: block;
  position: relative;
  flex-shrink: 0;
}
.facebookAuthButtonmode_signIn {
  display: none;
}
.facebookAuthButtonmode_signUp {
  display: none;
}
.facebookAuthButtonmode_magic {
  display: none;
}
.blackButtonFrame {
  box-shadow: 0px 3.515px 10.546px 0px rgba(0, 0, 0, 0.25);
  background: url("https://site-assets.plasmic.app/e8bf58a9219fc6bdf70f071560cd2c21.png")
      0% 0% / 94px 127px repeat,
    rgba(34, 30, 31, 0.8);
  width: 78px;
  height: 55px;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;
  border-radius: 10.546px;
}
.blackButtonFramemode_signUp {
  display: none;
}
.iconSourcesFacebook {
  transform: translate(0.05999999999999872px, 0.15799999999999947px);
  width: 37px;
  height: 43px;
  display: block;
  position: absolute;
  top: 10px;
  left: 22px;
}
.svg__tYfub {
  position: absolute;
  object-fit: cover;
  max-width: 100%;
  color: #2e89ff;
  transform: translate(0.2889999999999999px, 0.4730000000000001px);
  width: 37px;
  height: 37px;
  display: block;
  top: -2px;
  left: -2px;
}
.img__rQ3Ez {
  position: absolute;
  object-fit: cover;
  max-width: 100%;
  transform: translate(0.10499999999999998px, 0.10500000000000043px);
  width: 33.788px;
  height: 36.603px;
  display: block !important;
  top: 4px;
  left: 1px;
}
.img__rQ3Ez > picture > img {
  object-fit: cover;
}
.xcomAuthButton {
  width: 76px;
  height: 55px;
  display: block;
  position: relative;
  flex-shrink: 0;
}
.blackButtonFrame2 {
  box-shadow: 0px 3.515px 10.546px 0px rgba(0, 0, 0, 0.25);
  background: url("https://site-assets.plasmic.app/e8bf58a9219fc6bdf70f071560cd2c21.png")
      0% 0% / 94px 127px repeat,
    rgba(34, 30, 31, 0.8);
  width: 76px;
  height: 55px;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;
  border-radius: 10.546px;
}
.svg__rYpli {
  position: absolute;
  object-fit: cover;
  max-width: 100%;
  color: #fff;
  transform: translate(-0.1770000000000067px, -0.27500000000000036px);
  width: 31.083px;
  height: 31.109px;
  display: block;
  top: 11px;
  left: 21px;
}
.googleAuthButton {
  width: 80px;
  height: 55px;
  display: block;
  position: relative;
  flex-shrink: 0;
}
.googleAuthButtonmode_signIn {
  display: block;
}
.googleAuthButtonmode_signUp {
  display: block;
}
.googleAuthButtonmode_magic {
  display: none;
}
.blackButtonFrame3 {
  box-shadow: 0px 3.515px 10.546px 0px rgba(0, 0, 0, 0.25);
  background: url("https://site-assets.plasmic.app/e8bf58a9219fc6bdf70f071560cd2c21.png")
      0% 0% / 94px 127px repeat,
    rgba(34, 30, 31, 1);
  width: 80px;
  height: 55px;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;
  padding-right: 0px;
  border-radius: 10.546px;
}
.img__uvjd8 {
  position: absolute;
  object-fit: cover;
  max-width: 100%;
  transform: translate(0.4060000000000059px, 0.07899999999999974px);
  width: 41.832px;
  height: 41.735px;
  display: block !important;
  top: 5px;
  left: 17px;
  overflow: hidden;
}
.img__uvjd8 > picture > img {
  object-fit: cover;
}
.space3 {
  width: 76px;
  height: 1px;
  display: block;
  position: relative;
  flex-shrink: 0;
}
.space3mode_signUp {
  display: none;
}
.dontLikePasswordsMaybeAPasswordManagerIsWhatYouNeedButYouCanAlso {
  position: relative;
  width: 301px;
  height: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
}
.dontLikePasswordsMaybeAPasswordManagerIsWhatYouNeedButYouCanAlsomode_signUp {
  display: none;
}
.dontLikePasswordsMaybeAPasswordManagerIsWhatYouNeedButYouCanAlsomode_magic {
  display: none;
}
.text__idk5K {
  display: block;
  font-size: 11.16px;
  text-align: center;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 17.857px;
  color: rgba(195, 195, 195, 1);
}
.textmode_signUp__idk5KdiTvY {
  display: none;
}
.space4 {
  width: 76px;
  height: 1px;
  display: block;
  position: relative;
  flex-shrink: 0;
}
.space4mode_signUp {
  display: none;
}
.magicLinkSignInButton:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.magicLinkSignInButtonmode_signUp:global(.__wab_instance) {
  display: none;
}
.svg__uEuuM {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.textmode_signUp__z6WguDiTvY {
  font-size: 14px;
}
.spanmode_signUp {
  color: #2744a3;
}
.svg___50Rpr {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.resetPassword {
  width: auto;
  height: auto;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.text__cjuw2 {
  display: block;
  font-size: 11.16px;
  text-align: center;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  letter-spacing: 0px;
  line-height: 17.857px;
  color: rgba(195, 195, 195, 1);
  position: relative;
  width: 301px;
}
.andIfYouNeedToYouCan {
  display: block;
  font-size: 11.16px;
  text-align: center;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 17.857px;
  color: rgba(195, 195, 195, 1);
  width: 301px;
  position: relative;
}
.andIfYouNeedToYouCanmode_signIn {
  width: auto;
}
.resetPasswordButton {
  display: block;
  font-size: 11.16px;
  text-align: center;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  letter-spacing: 0px;
  line-height: 17.857px;
  color: rgba(88, 119, 231, 1);
  position: relative;
  width: 301px;
}
.resetPasswordButtonmode_signIn {
  width: auto;
}
.signInContainer {
  width: auto;
  height: auto;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.text__mfobx {
  display: block;
  font-size: 11.16px;
  text-align: center;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  letter-spacing: 0px;
  line-height: 17.857px;
  color: rgba(195, 195, 195, 1);
  position: relative;
  width: 301px;
}
.freeBox__u185V {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
.freeBox__u185V > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-width: 0;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
}
.freeBox__u185V > :global(.__wab_flex-container) > *,
.freeBox__u185V > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__u185V > :global(.__wab_flex-container) > picture > img,
.freeBox__u185V
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 8px;
}
.andIfYouNeedToYouCan2 {
  display: block;
  font-size: 11.16px;
  text-align: center;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 17.857px;
  color: rgba(195, 195, 195, 1);
  width: auto;
}
.resetPasswordButton2 {
  display: block;
  font-size: 11.16px;
  text-align: center;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  letter-spacing: 0px;
  line-height: 17.857px;
  color: rgba(88, 119, 231, 1);
  position: relative;
  width: auto;
}
.space5 {
  width: 76px;
  height: 1px;
  display: block;
  position: relative;
  flex-shrink: 0;
}
.bottom {
  filter: drop-shadow(5px 10px 15px rgba(0, 0, 0, 0.5));
  width: 382px;
  height: 114px;
  display: flex;
  position: relative;
  flex-direction: column;
  flex-shrink: 0;
  border-radius: 10.546px;
  padding: 14px 28px;
}
.bottom > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: calc(0px - 16px);
  height: calc(100% + 16px);
}
.bottom > :global(.__wab_flex-container) > *,
.bottom > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.bottom > :global(.__wab_flex-container) > picture > img,
.bottom
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 16px;
}
.text__qFl8Y {
  display: block;
  font-size: 11.16px;
  text-align: center;
  font-family: "Open Sans", sans-serif;
  font-weight: 500;
  letter-spacing: 0px;
  line-height: 17.857px;
  color: #faf7ee;
  position: relative;
}
.orSignUpButton {
  filter: drop-shadow(0px 0px 14.567px rgba(63, 98, 220, 1));
  width: auto;
  height: auto;
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}
.freeBox__vaOmp {
  display: block;
  position: relative;
  width: 24px;
  height: 24px;
  flex-shrink: 0;
}
.svg__kcgj3 {
  position: absolute;
  object-fit: cover;
  max-width: 100%;
  color: #5978e8;
  box-shadow: none;
  transform: scale(1.000000499999875, 0.999999500000375)
    rotate(-179.94270423958343deg) skew(0.05729570311883569deg, 0deg);
  width: 24px;
  height: 24px;
  display: block;
  transform-origin: top left;
  overflow: hidden;
  left: 24px;
  top: 24px;
  backdrop-filter: none;
  filter: drop-shadow(0px 0px 3px var(--token-v6F1z1DYnr3y));
  -webkit-backdrop-filter: none;
}
.buttonDefault {
  filter: drop-shadow(0px 2.913px 4.37px rgba(0, 0, 0, 0.25));
  width: auto;
  height: 35px;
  display: flex;
  position: relative;
  overflow: hidden;
  flex-direction: row;
  border-radius: 8.74px;
  padding: 8.74px 18.937px;
}
.buttonDefault > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: calc(0px - 14.567px);
  width: calc(100% + 14.567px);
}
.buttonDefault > :global(.__wab_flex-container) > *,
.buttonDefault > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.buttonDefault > :global(.__wab_flex-container) > picture > img,
.buttonDefault
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 14.567px;
}
.text__x6Wea {
  text-shadow: 0px 1.456px 36.417px rgba(63, 98, 220, 1),
    0px 0px 2.913px rgba(77, 109, 223, 1);
  display: block;
  font-size: 14.567px;
  text-align: right;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  letter-spacing: 0px;
  line-height: 28.457px;
  color: rgba(88, 119, 231, 1);
  position: relative;
}
.textmode_signUp__x6WeaDiTvY {
  padding-bottom: 0px;
}
.continueWithoutAnAccountButton:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  display: none;
}
.continueWithoutAnAccountButtonmode_signUp:global(.__wab_instance) {
  display: flex;
}
.svg__l3Wwn {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__u0L71 {
  font-size: 14px;
}
.svg__kpQpl {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
