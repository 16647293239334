.root {
  display: inline-flex;
  flex-direction: column;
  position: relative;
  width: auto;
  height: auto;
}
.headerMenu {
  width: auto;
  height: auto;
  display: flex;
  flex-direction: row;
  padding-top: 0.25em;
  padding-bottom: 0.25em;
  align-self: flex-end;
}
.headerMenu > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: calc(0px - 20px);
  width: calc(100% + 20px);
}
.headerMenu > :global(.__wab_flex-container) > *,
.headerMenu > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.headerMenu > :global(.__wab_flex-container) > picture > img,
.headerMenu
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 20px;
}
.signUpButton {
  width: auto;
  height: auto;
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.signUpButtonglobal_theme_dark {
  display: flex;
}
.signUpButtonview_welcomeView {
  display: none;
}
.shinyButton:global(.__wab_instance) {
  position: relative;
}
.shinyButtonglobal_theme_light:global(.__wab_instance) {
  display: none;
}
.buttonDefault {
  filter: drop-shadow(0px 2px 3px rgba(0, 0, 0, 0.25));
  width: auto;
  height: 1.5em;
  display: flex;
  position: relative;
  overflow: hidden;
  flex-direction: row;
  background: rgba(33, 42, 68, 1);
  border-radius: 0.24em;
  padding: 0.37em 0.81em;
}
.buttonDefault > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: calc(0px - 10px);
  width: calc(100% + 10px);
}
.buttonDefault > :global(.__wab_flex-container) > *,
.buttonDefault > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.buttonDefault > :global(.__wab_flex-container) > picture > img,
.buttonDefault
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 10px;
}
.buttonDefaultglobal_theme_light {
  display: flex;
}
.buttonDefaulthideSignUpButton {
  display: none;
}
.text {
  display: block;
  font-size: 0.62em;
  text-align: center;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  letter-spacing: 0px;
  line-height: 1em;
  color: rgba(250, 247, 238, 1);
  position: relative;
}
.freeBox {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  display: none;
  padding: 8px;
}
.freeBoxview_seeAllView {
  display: block;
}
.editButton {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 1.37em;
  overflow: visible;
  flex-shrink: 0;
  padding: 0px;
  border-width: 0px;
  border-style: none;
}
.editButtonview_publicSectionView {
  display: flex;
}
.editButtonview_welcomeView {
  display: none;
}
.editButtonred {
  overflow: visible;
}
.editButtoneditMode {
  width: 1.5em;
  flex-shrink: 0;
}
.editIconRed:global(.__wab_instance) {
  position: relative;
  width: 100%;
  height: 100%;
  align-self: center;
  min-width: 0;
  min-height: 0;
  display: none;
}
.editIconRedview_seeAllView:global(.__wab_instance) {
  display: none;
}
.editIconBlue:global(.__wab_instance) {
  position: relative;
  width: 22px;
  flex-shrink: 0;
  display: none;
}
.editIconBlueview_seeAllView:global(.__wab_instance) {
  display: none;
}
.editIcon:global(.__wab_instance) {
  max-width: 110%;
  position: relative;
  flex-shrink: 0;
}
.editIconglobal_theme_light:global(.__wab_instance) {
  flex-shrink: 0;
}
.editIconview_seeAllView:global(.__wab_instance) {
  flex-shrink: 0;
  display: block;
}
.editIconview_publicSectionView:global(.__wab_instance) {
  flex-shrink: 0;
  display: block;
}
.editIconred:global(.__wab_instance) {
  flex-shrink: 0;
}
.editIconeditMode:global(.__wab_instance) {
  flex-shrink: 0;
  display: none;
}
.svg {
  object-fit: cover;
  margin-left: 0px;
  width: 1.5em;
  height: 1.5em;
  color: var(--token-kgwkYLWcU);
  flex-shrink: 0;
  display: none;
}
.svgeditMode {
  display: block;
}
.smallOverlayMenu:global(.__wab_instance) {
  position: relative;
  margin-top: 0px;
  margin-left: calc(-12px + 20px) !important;
}
.smallOverlayMenuview_seeAllView:global(.__wab_instance) {
  margin-left: calc(-12px + 20px) !important;
  display: flex;
}
.smallOverlayMenuview_publicSectionView:global(.__wab_instance) {
  margin-left: calc(-12px + 20px) !important;
}
.smallOverlayMenuview_welcomeView:global(.__wab_instance) {
  margin-left: calc(-12px + 20px) !important;
  display: none;
}
.smallOverlayMenured:global(.__wab_instance) {
  margin-left: calc(-12px + 20px) !important;
}
.smallOverlayMenueditMode:global(.__wab_instance) {
  opacity: 1;
  margin-left: calc(-12px + 20px) !important;
  display: flex;
}
