.root {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  background: url("https://site-assets.plasmic.app/9894b823d7c48795feb36d70abc09eb1.svg")
    top 50% left 50% / 100% 100% repeat;
  min-width: 0;
  min-height: 0;
}
.rootlevel__3 {
  background: url("https://site-assets.plasmic.app/33bac616d0640d466982c2e35839c570.svg")
    top 50% left 50% / 100% 100% repeat;
}
.rootlevel__2 {
  background: url("https://site-assets.plasmic.app/c1c5eb6787a308e34f28f60c53b3f1e8.svg")
    top 50% left 50% / 100% 100% repeat;
}
.rootlevel__1 {
  background: url("https://site-assets.plasmic.app/7a82d4efa5405c7be64ea079afbf3ac6.svg")
    top 50% left 50% / 100% 100% repeat;
}
.rootlevel__0 {
  background: url("https://site-assets.plasmic.app/d0fc2e0391c0c3dc95702c803c45fdad.svg")
    top 50% left 50% / 100% 100% repeat;
}
.freeBox {
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  max-width: 100%;
  background: url("https://site-assets.plasmic.app/9894b823d7c48795feb36d70abc09eb1.svg")
    top 50% left 50% / 100% 100% repeat;
  box-shadow: 0px 4px 16px 0px var(--token-O7e_v4mKD);
  min-width: 0;
  min-height: 0;
  display: none;
  padding: 8px;
}
