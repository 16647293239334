.card {
  width: 11.5em;
  height: auto;
  display: flex;
  position: relative;
  flex-direction: column;
  flex-grow: 0;
}
.card > :global(.__wab_flex-container) {
  flex-direction: column;
  margin-top: calc(0px - 0.13em);
  height: calc(100% + 0.13em);
}
.card > :global(.__wab_flex-container) > *,
.card > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.card > :global(.__wab_flex-container) > picture > img,
.card > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-top: 0.13em;
}
.cardawaitingMetadata > :global(.__wab_flex-container) {
  margin-left: calc(0px - 0px);
  width: calc(100% + 0px);
  margin-top: calc(0px - 2px);
  height: calc(100% + 2px);
}
.cardawaitingMetadata > :global(.__wab_flex-container) > *,
.cardawaitingMetadata
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > *,
.cardawaitingMetadata > :global(.__wab_flex-container) > picture > img,
.cardawaitingMetadata
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 0px;
  margin-top: 2px;
}
.carddisplayMode_list {
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  min-width: 0;
}
.carddisplayMode_list > :global(.__wab_flex-container) {
  flex-direction: row-reverse;
  min-width: 0;
  margin-top: calc(0px - 0px);
  height: calc(100% + 0px);
}
.carddisplayMode_list > :global(.__wab_flex-container) > *,
.carddisplayMode_list
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > *,
.carddisplayMode_list > :global(.__wab_flex-container) > picture > img,
.carddisplayMode_list
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 0px;
}
.cardglobal_theme_dark_displayMode_list {
  padding-bottom: 4em;
  min-width: 500px;
  max-width: 800px;
}
.videoThumbnailContainer {
  box-shadow: 0px 2px 6px 2px var(--token-Dl_IO0P9f);
  width: 100%;
  height: 6.5em;
  display: block;
  position: relative;
  top: auto;
  left: auto;
  overflow: hidden;
  min-width: 0;
  flex-shrink: 0;
  border-radius: 10px;
}
.videoThumbnailContainerdisplayMode_list {
  left: auto;
  top: auto;
  width: 12em;
  flex-shrink: 0;
}
.image {
  position: absolute;
  object-fit: cover;
  left: auto;
  top: 0px;
  width: 100%;
  bottom: auto;
  right: auto;
  height: 100%;
  min-width: 0;
  min-height: 0;
}
.image > picture > img {
  object-fit: cover;
}
.freeBox__io041 {
  display: flex;
  position: absolute;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  top: 120px;
  left: 0px;
}
.svg__s8Zj {
  position: relative;
  object-fit: cover;
  width: 113px;
  height: 4px;
  color: #2141b3;
  display: block;
  top: auto;
  left: auto;
  flex-shrink: 0;
}
.videoLengthContainer {
  width: auto;
  height: auto;
  display: flex;
  position: absolute;
  flex-direction: row;
  padding-left: 8px;
  padding-right: 8px;
  left: auto;
  top: auto;
  right: 10px;
  background: rgba(19, 22, 30, 0.839);
  bottom: 4px;
  border-radius: 6px;
}
.videoLengthContainer > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: calc(0px - 10px);
  width: calc(100% + 10px);
}
.videoLengthContainer > :global(.__wab_flex-container) > *,
.videoLengthContainer
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > *,
.videoLengthContainer > :global(.__wab_flex-container) > picture > img,
.videoLengthContainer
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 10px;
}
.freeBox__mzbW {
  display: flex;
  position: relative;
  align-self: auto;
  justify-content: center;
  flex-direction: row;
}
.slotTargetDuration {
  font-family: "Open Sans", sans-serif;
  font-size: 0.6em;
  font-weight: 600;
  color: rgba(255, 255, 255, 1);
  text-align: center;
  line-height: 15px;
  letter-spacing: 0px;
}
.freeBox__inEcc {
  position: absolute;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 2em;
  height: 100%;
  max-width: 100%;
  left: 0px;
  top: 0px;
  background: #e4d6d6b3;
  backdrop-filter: blur(0.7px);
  min-height: 0;
  display: none;
  -webkit-backdrop-filter: blur(0.7px);
  padding: 0px;
  border-right: 2px none #e6002361;
}
.freeBoxisShort__inEcceZo2S {
  display: flex;
}
.svg__ncSqg {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 1em;
  flex-shrink: 0;
}
.videoData {
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  top: auto;
  left: auto;
  overflow: hidden;
  flex-direction: column;
  min-width: 0;
}
.videoData > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - 0.3em);
  height: calc(100% + 0.3em);
}
.videoData > :global(.__wab_flex-container) > *,
.videoData > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.videoData > :global(.__wab_flex-container) > picture > img,
.videoData
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 0.3em;
}
.videoDataawaitingMetadata > :global(.__wab_flex-container) {
  margin-left: calc(0px - 0px);
  width: calc(100% + 0px);
  margin-top: calc(0px - 2px);
  height: calc(100% + 2px);
}
.videoDataawaitingMetadata > :global(.__wab_flex-container) > *,
.videoDataawaitingMetadata
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > *,
.videoDataawaitingMetadata > :global(.__wab_flex-container) > picture > img,
.videoDataawaitingMetadata
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 0px;
  margin-top: 2px;
}
.videoDatadisplayMode_list {
  left: auto;
  top: auto;
}
.videoDatadisplayMode_list > :global(.__wab_flex-container) {
  margin-left: calc(0px - 0px);
  width: calc(100% + 0px);
  margin-top: calc(0px - 6.4px);
  height: calc(100% + 6.4px);
}
.videoDatadisplayMode_list > :global(.__wab_flex-container) > *,
.videoDatadisplayMode_list
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > *,
.videoDatadisplayMode_list > :global(.__wab_flex-container) > picture > img,
.videoDatadisplayMode_list
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 0px;
  margin-top: 6.4px;
}
.videoDataglobal_theme_dark_displayMode_list {
  align-self: flex-start;
}
.videoTitle {
  display: flex;
  position: relative;
  width: 100%;
  height: auto;
  margin-top: calc(5px + 0.3em) !important;
  margin-bottom: 0%;
  flex-direction: row;
  min-height: 1.2em;
  max-height: 3.4em;
  overflow: hidden;
  min-width: 0;
}
.videoTitleglobal_theme_dark {
  margin-top: calc(5px + 0.3em) !important;
}
.videoTitleawaitingMetadata {
  margin-top: calc(5px + 2px) !important;
}
.videoTitledisplayMode_list {
  margin-top: calc(5px + 6.4px) !important;
}
.videoTitleglobal_theme_dark_displayMode_list {
  margin-top: calc(5px + 6.4px) !important;
}
.slotTargetTitle {
  font-family: "Open Sans", sans-serif;
  font-size: 0.9em;
  font-weight: 600;
  color: var(--token-P44QyWYsh);
  line-height: 1.2em;
  letter-spacing: 0px;
  white-space: pre-wrap;
}
.slotTargetTitleglobal_theme_dark {
  color: var(--token-kgwkYLWcU);
  font-weight: 600;
}
.slotTargetTitledisplayMode_list {
  font-size: 1.2em;
}
.videoMetaData {
  width: auto;
  height: auto;
  display: flex;
  position: relative;
  overflow: hidden;
  flex-direction: column;
}
.videoMetaData > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: calc(0px - 0.15em);
  height: calc(100% + 0.15em);
}
.videoMetaData > :global(.__wab_flex-container) > *,
.videoMetaData > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.videoMetaData > :global(.__wab_flex-container) > picture > img,
.videoMetaData
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 0.15em;
}
.videoMetaDatadisplayMode_list {
  width: 100%;
  min-width: 0;
  padding: 0em 0.4em;
}
.videoMetaDatadisplayMode_list > :global(.__wab_flex-container) {
  min-width: 0;
}
.meta {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 0px;
}
.metadisplayMode_list {
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  padding: 0px 2em 0px 0px;
}
.channelData {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 0px;
}
.channelDatadisplayMode_list {
  padding: 0px;
}
.slotTargetChannel {
  font-family: "Open Sans", sans-serif;
  font-size: 0.6em;
  font-weight: 600;
  color: var(--token-moETPK9D_);
  line-height: 1.3em;
  letter-spacing: 0px;
}
.slotTargetChanneldisplayMode_list {
  font-size: 0.65em;
}
.slotTargetChannelglobal_theme_dark_displayMode_list {
  color: var(--token-pMUHtTtb1);
}
.freeBox__oZqy7 {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 0px;
}
.freeBoxdisplayMode_list__oZqy7Y705 {
  padding: 0px;
}
.freeBoxglobal_theme_dark_displayMode_list__oZqy7OIz4XY705 {
  width: auto;
  flex-grow: 0;
  flex-shrink: 0;
}
.slotTargetMetaData {
  font-family: "Open Sans", sans-serif;
  font-size: 0.6em;
  font-weight: 600;
  color: var(--token-moETPK9D_);
  line-height: 1.3em;
  letter-spacing: 0px;
}
.slotTargetMetaData > *,
.slotTargetMetaData > :global(.__wab_slot) > *,
.slotTargetMetaData > :global(.__wab_slot) > :global(.__wab_slot) > *,
.slotTargetMetaData
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > *,
.slotTargetMetaData > picture > img,
.slotTargetMetaData > :global(.__wab_slot) > picture > img,
.slotTargetMetaData
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > picture
  > img,
.slotTargetMetaData
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > picture
  > img {
  transition-property: all;
  transition-duration: 1s;
  -webkit-transition-property: all;
  -webkit-transition-duration: 1s;
}
.slotTargetMetaDatadisplayMode_list {
  font-size: 0.65em;
}
.slotTargetMetaDataglobal_theme_dark_displayMode_list {
  white-space: pre;
  color: var(--token-B8u26uCIS);
}
.freeBox___7SvqU {
  position: relative;
  width: 100%;
  height: 100%;
  max-width: 100%;
  flex-direction: row;
  padding-top: 0.3em;
  min-width: 0;
  min-height: 0;
  display: none;
}
.freeBoxdisplayMode_list___7SvqUy705 {
  display: flex;
}
.slotTargetChildren {
  font-size: 0.6em;
  color: #535353;
}
.slotTargetChildrenglobal_theme_dark_displayMode_list {
  color: var(--token-moETPK9D_);
}
.itemDivider:global(.__wab_instance) {
  max-width: 100%;
  position: absolute;
  left: 0px;
  top: 0px;
  display: none;
}
.itemDividerdisplayMode_list:global(.__wab_instance) {
  left: -1px;
  top: 114px;
  display: flex;
}
.itemDividerglobal_theme_dark_displayMode_list:global(.__wab_instance) {
  left: -6px;
  top: 101px;
  margin-top: calc(2rem + 0px) !important;
  margin-bottom: 1em;
}
