.root {
  display: inline-flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-around;
  width: auto;
  height: auto;
  max-height: 250px;
  max-width: 320px;
  flex-grow: 1;
  box-shadow: none;
  overflow: visible;
  border-width: 0px;
  border-style: none;
}
.rootsectionSettings {
  flex-grow: 0;
  flex-shrink: 0;
}
.freeBox___0CBgA {
  display: block;
  flex-direction: column;
  width: 280px;
  height: 190px;
  overflow: visible;
  position: relative;
  left: auto;
  top: auto;
  background: none;
  flex-shrink: 0;
  border-radius: 10px;
}
.freeBoxselected___0CBgAk7FwP {
  overflow: visible;
  border-radius: 10px;
}
.freeBoxsectionSettings___0CBgADqL73 {
  height: 90px;
  width: 150px;
  flex-shrink: 0;
}
.freeBoxsectionSettingsSquashed___0CBgA1FXWk {
  height: 27px;
  width: 150px;
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
}
.freeBoxinterest_productivity___0CBgAHwlW2 {
  background: url("https://site-assets.plasmic.app/59e4f17a8e060247932e28fc8c0739f2.jpg")
    top 50% left 50% / cover repeat;
}
.freeBoxinterest_videoGames___0CBgA6Df1C {
  background: url("https://site-assets.plasmic.app/d889c03e8dd42a8fa9df2791ad109050.jpg")
    top 50% left 50% / cover repeat;
}
.freeBoxinterest_nature___0CBgA6CLqR {
  background: url("https://site-assets.plasmic.app/76bceddbc8b26c8c814e92dffed7a8a5.jpg")
    top 50% left 50% / cover repeat;
}
.freeBoxinterest_art___0CBgARgWmC {
  background: url("https://site-assets.plasmic.app/45e135e789c376e16f9d085c41a007f4.jpg")
    top 50% left 50% / cover repeat;
}
.freeBoxinterest_science___0CBgAk9Erv {
  background: url("https://site-assets.plasmic.app/129cf167ff2a7598fa6a864aa8ef5bee.jpg")
    top 50% left 50% / cover repeat;
}
.freeBoxinterest_fitness___0CBgA7CwRb {
  background: url("https://site-assets.plasmic.app/f0e92f1235f8f27f303c87c876fba2d5.jpg")
    top 50% left 50% / cover repeat;
}
.freeBoxinterest_photography___0CBgAsWw7K {
  background: url("https://site-assets.plasmic.app/5bfc1277a84fba0e282ddb48aa85a317.jpg")
    top 50% left 50% / cover repeat;
}
.freeBoxinterest_health___0CBgAneAp {
  background: url("https://site-assets.plasmic.app/7451a578d088e406a915a807d347979a.jpg")
    top 50% left 50% / cover repeat;
}
.freeBoxinterest_entrepreneurship___0CBgAhA5D {
  background: url("https://site-assets.plasmic.app/4b02c83276b29ccd3a9f394940ef261e.jpg")
    top 50% left 50% / cover repeat;
}
.freeBoxinterest_designUx___0CBgAGvnx5 {
  background: url("https://site-assets.plasmic.app/caf6a1229353294bb32afbe081756a61.jpg")
    top 50% left 50% / cover repeat;
}
.freeBoxinterest_programming___0CBgAnIpkR {
  background: url("https://site-assets.plasmic.app/47523a0e38d201c25d3113e23a1903ca.jpg")
    top 50% left 50% / cover repeat;
}
.freeBoxinterest_future___0CBgAxTkQ {
  background: url("https://site-assets.plasmic.app/cedcd7fd8ba2becbd51d08b6c9550ea5.jpg")
    top 50% left 50% / cover repeat;
}
.freeBoxinterest_technology___0CBgA5QzhF {
  background: url("https://site-assets.plasmic.app/0eb17fee5bbee0ed220a63229fce7a91.jpg")
    top 50% left 50% / cover repeat;
}
.freeBoxinterest_comedy___0CBgAci3Lx {
  background: url("https://site-assets.plasmic.app/df9ce2986b7a28edbd276458adc779ea.jpg")
    top 50% left 50% / cover repeat;
}
.freeBoxvariant_selected___0CBgAjNaXi {
  overflow: visible;
  border-radius: 10px;
}
.freeBoxvariant_sectionSettings___0CBgAxyKiJ {
  height: 90px;
  width: 150px;
  flex-shrink: 0;
}
.freeBoxvariant_sectionSettingsSquashed___0CBgA6VEbi {
  height: 27px;
  width: 150px;
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
}
.bgImage {
  position: absolute;
  object-fit: cover;
  max-width: 100%;
  left: 0px;
  top: 0px;
  height: 100%;
  width: 100%;
  border-radius: 10px;
}
.bgImage > picture > img {
  object-fit: cover;
}
.bgImagesectionSettings {
  width: 100%;
}
.interestBox {
  display: block;
  position: absolute;
  width: 100%;
  height: 62px;
  left: auto;
  top: auto;
  bottom: 0px;
  box-shadow: none;
  background: #1613138c;
  min-width: 0;
  border-radius: 0px 0px 10px 10px;
  border-width: 0px;
}
.interestBoxselected {
  background: #2141b38c;
  border-radius: 0px 0px 10px 10px;
}
.interestBoxsectionSettings {
  height: 25%;
}
.interestBoxsectionSettingsSquashed {
  height: 100%;
  position: relative;
  left: auto;
  top: auto;
  bottom: auto;
  border-radius: 10px;
}
.interestBoxvariant_selected {
  background: #2141b38c;
  border-radius: 0px 0px 10px 10px;
}
.interestBoxvariant_sectionSettings {
  height: 25%;
}
.interestBoxvariant_sectionSettingsSquashed {
  height: 100%;
  position: relative;
  left: auto;
  top: auto;
  bottom: auto;
  background: #16131399;
  filter: blur(0px);
  border-radius: 10px;
}
.freeBox__obCa {
  display: flex;
  position: absolute;
  left: 0%;
  top: 17px;
  width: 280px;
  justify-content: center;
}
.freeBoxsectionSettings__obCaDqL73 {
  width: 100%;
  top: 17%;
  min-width: 0;
}
.freeBoxsectionSettingsSquashed__obCa1FXWk {
  width: 100%;
  top: 25%;
  min-width: 0;
}
.freeBoxvariant_sectionSettings__obCaxyKiJ {
  width: 100%;
  top: 17%;
  min-width: 0;
}
.freeBoxvariant_sectionSettingsSquashed__obCa6VEbi {
  width: 100%;
  top: 25%;
  min-width: 0;
}
.text {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  text-align: center;
  font-size: 18px;
  color: var(--token-kgwkYLWcU);
  min-width: 0;
}
.textsectionSettings {
  font-size: 10px;
}
.textsectionSettingsSquashed {
  font-size: 10px;
}
.textinterest_productivity {
  height: auto;
}
.textinterest_art {
  height: auto;
}
.textinterest_comedy {
  height: auto;
}
.textvariant_sectionSettings {
  font-size: 10px;
}
.textvariant_sectionSettingsSquashed {
  font-size: 10px;
}
.addButton:global(.__wab_instance) {
  position: absolute;
  left: auto;
  top: auto;
  right: 15px;
  bottom: -21px;
}
.addButtonsectionSettings:global(.__wab_instance) {
  display: none;
}
.addButtonsectionSettingsSquashed:global(.__wab_instance) {
  display: none;
}
.addButtoninterest_personalImprovement:global(.__wab_instance) {
  display: none;
}
.addButtoninterest_productivity:global(.__wab_instance) {
  display: none;
}
.addButtoninterest_videoGames:global(.__wab_instance) {
  display: none;
}
.addButtoninterest_nature:global(.__wab_instance) {
  display: none;
}
.addButtoninterest_art:global(.__wab_instance) {
  display: none;
}
.addButtoninterest_science:global(.__wab_instance) {
  display: none;
}
.addButtoninterest_fitness:global(.__wab_instance) {
  display: none;
}
.addButtoninterest_photography:global(.__wab_instance) {
  display: none;
}
.addButtoninterest_health:global(.__wab_instance) {
  display: none;
}
.addButtoninterest_entrepreneurship:global(.__wab_instance) {
  display: none;
}
.addButtoninterest_designUx:global(.__wab_instance) {
  display: none;
}
.addButtoninterest_programming:global(.__wab_instance) {
  display: none;
}
.addButtoninterest_future:global(.__wab_instance) {
  display: none;
}
.addButtoninterest_technology:global(.__wab_instance) {
  display: none;
}
.addButtoninterest_comedy:global(.__wab_instance) {
  display: none;
}
.addButtonvariant__default:global(.__wab_instance) {
  display: flex;
}
.addButtonvariant_selected:global(.__wab_instance) {
  display: flex;
}
.addButtonvariant_sectionSettings:global(.__wab_instance) {
  display: none;
}
.addButtonvariant_sectionSettingsSquashed:global(.__wab_instance) {
  display: none;
}
