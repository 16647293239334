.root {
  display: inline-flex;
  flex-direction: row;
  position: relative;
  width: auto;
  height: auto;
  justify-self: flex-start;
}
.root > :global(.__wab_flex-container) {
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  margin-left: calc(0px - 4px);
  width: calc(100% + 4px);
}
.root > :global(.__wab_flex-container) > *,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.root > :global(.__wab_flex-container) > picture > img,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-left: 4px;
}
.button {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: auto;
  height: auto;
  max-width: 100%;
  flex-shrink: 0;
  padding: 2px;
}
.buttonisInactive {
  display: none;
}
.svg {
  object-fit: cover;
  max-width: 100%;
  transform: rotateX(0deg) rotateY(0deg) rotateZ(90deg);
  transition-property: transform;
  transition-duration: 0.6s;
  height: 1em;
  -webkit-transition-property: transform;
  -webkit-transition-duration: 0.6s;
}
.svgglobal_theme_dark {
  color: var(--token-kgwkYLWcU);
}
.svgglobal_theme_light {
  color: var(--token-WkUIkx5-J);
}
.svgisCollapsed {
  transform: translateX(0px) translateY(0px) translateZ(0px);
}
.freeBox {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: auto;
  height: auto;
  max-width: 100%;
  padding: 0px;
}
.freeBoxisCollapsed {
  display: none;
}
.freeBoxbehaviour_stretching {
  width: 100%;
  min-width: 0;
}
.freeBoxbehaviour_keepingWidth_isCollapsed {
  height: 0px;
  opacity: 0;
  display: flex;
}
