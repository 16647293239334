.root {
  display: inline-flex;
  flex-direction: column;
  position: relative;
  width: auto;
  height: auto;
}
.text__ozTx6 {
  display: block;
  font-size: 10px;
  text-align: center;
  text-decoration-line: underline;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  letter-spacing: 0px;
  line-height: 25px;
  color: var(--token-pMUHtTtb1);
  width: 100%;
  min-width: 0;
}
.checklistContainer {
  filter: drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.15));
  width: 255px;
  height: auto;
  display: flex;
  flex-direction: column;
  background: linear-gradient(
      194deg,
      rgba(52, 48, 49, 1) 115%,
      rgba(42, 37, 38, 1) 228%
    )
    0% 0% / 100% 100% no-repeat padding-box border-box scroll;
  border-radius: 10px;
  padding: 12px 20px;
}
.checklistContainer > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: calc(0px - 5px);
  height: calc(100% + 5px);
}
.checklistContainer > :global(.__wab_flex-container) > *,
.checklistContainer > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.checklistContainer > :global(.__wab_flex-container) > picture > img,
.checklistContainer
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 5px;
}
.checklistItem__ren:global(.__wab_instance) {
  position: relative;
  flex-shrink: 0;
}
.checklistItem__obD0:global(.__wab_instance) {
  position: relative;
}
.checklistItem___5Gdgg:global(.__wab_instance) {
  position: relative;
}
.checklistItem__koZxn:global(.__wab_instance) {
  position: relative;
}
.checklistItem__xivwn:global(.__wab_instance) {
  position: relative;
}
.checklistItem__vsBxM:global(.__wab_instance) {
  position: relative;
}
.checklistItem__zt1Gi:global(.__wab_instance) {
  position: relative;
}
.checklistItem___1LLtl:global(.__wab_instance) {
  position: relative;
}
.hideChecklist {
  position: relative;
  width: auto;
  height: 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  flex-shrink: 0;
}
.text__vLlhA {
  display: block;
  font-size: 10px;
  text-align: center;
  text-decoration-line: underline;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  letter-spacing: 0px;
  line-height: 25px;
  color: var(--token-pMUHtTtb1);
  width: 100%;
  min-width: 0;
}
