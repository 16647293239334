.root {
  display: inline-flex;
  flex-direction: column;
  position: relative;
  width: auto;
  height: auto;
}
.freeBox__lbL9Q {
  box-shadow: 0px 2px 6px 2px var(--token-Dl_IO0P9f);
  width: 44px;
  height: 44px;
  left: auto;
  right: auto;
  display: block;
  position: relative;
  top: auto;
  background: var(--token-kgwkYLWcU);
  flex-shrink: 0;
  border-radius: 50%;
  border: 4px solid var(--token-zkLtEhfWu);
}
.freeBoxstate_selected__lbL9QbalMi {
  box-shadow: 0px 2px 6px 2px var(--token-Dl_IO0P9f);
}
.svg__r1Dnl {
  width: 18px;
  height: 14px;
  left: 10px;
  top: 11px;
  color: var(--token-yt3rDB0KD);
  z-index: 1;
  position: absolute;
  object-fit: cover;
  display: block;
}
.svg__aoy8Z {
  position: absolute;
  object-fit: cover;
  width: 28px;
  height: 28px;
  color: var(--token-kgwkYLWcU);
  display: block;
  left: 0px;
  top: 0px;
  z-index: 1;
}
.freeBox___5VcrX {
  width: 44px;
  height: 44px;
  left: auto;
  right: auto;
  display: block;
  position: relative;
  top: auto;
  background: var(--token-kgwkYLWcU);
  flex-shrink: 0;
  border-radius: 50%;
  border: 4px solid var(--token-zkLtEhfWu);
}
.freeBoxstate_unselected___5VcrXVgwL {
  box-shadow: 0px 2px 6px 2px var(--token-Dl_IO0P9f);
  left: auto;
  top: auto;
  right: auto;
  width: 44px;
  height: 44px;
  background: var(--token-yt3rDB0KD);
  flex-shrink: 0;
  border-style: none;
}
.freeBoxstate_small___5VcrXWuZx {
  display: block;
}
.svg___1SQUn {
  width: 18px;
  height: 14px;
  left: 10px;
  top: 11px;
  color: var(--token-yt3rDB0KD);
  z-index: 1;
  position: absolute;
  object-fit: cover;
  display: block;
}
.svg__jqHif {
  position: absolute;
  object-fit: cover;
  width: 28px;
  height: 28px;
  color: var(--token-kgwkYLWcU);
  display: block;
  left: 0px;
  top: 0px;
  z-index: 1;
}
.svgstate_unselected__jqHifVgwL {
  left: 8px;
  top: 8px;
}
.freeBox__h3WYg {
  width: 44px;
  height: 44px;
  left: 0px;
  display: block;
  position: absolute;
  top: 0px;
  background: var(--token-kgwkYLWcU);
  border-radius: 50%;
  border: 4px solid var(--token-zkLtEhfWu);
}
.freeBoxstate_unselected__h3WYgVgwL {
  box-shadow: 0px 2px 6px 2px var(--token-Dl_IO0P9f);
  left: 0px;
  top: 0px;
  width: 44px;
  height: 44px;
  background: var(--token-yt3rDB0KD);
  border-style: none;
}
.svg___7NJDf {
  width: 18px;
  height: 14px;
  left: 10px;
  top: 11px;
  color: var(--token-yt3rDB0KD);
  z-index: 1;
  position: absolute;
  object-fit: cover;
  display: block;
}
.svgstate_small___7NJDfWuZx {
  height: 1em;
}
.svg__jGtUc {
  position: absolute;
  object-fit: cover;
  width: 28px;
  height: 28px;
  color: var(--token-kgwkYLWcU);
  display: block;
  left: 0px;
  top: 0px;
  z-index: 1;
}
.svgstate_unselected__jGtUcVgwL {
  left: 8px;
  top: 8px;
}
