.root {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: auto;
  justify-content: center;
  align-items: center;
  min-width: 0;
}
.sectionSettings {
  background: linear-gradient(24deg, #353132 0%, #212121 12%, #2b2627 85%);
  filter: drop-shadow(5px 10px 20px rgba(0, 0, 0, 0.5));
  width: 100%;
  display: flex;
  min-width: 30em;
  flex-direction: column;
  flex-grow: 1;
  height: 25rem;
  flex-shrink: 0;
  border-radius: 8px;
  border: 1px solid #00000066;
}
.sectionSettings > :global(.__wab_flex-container) {
  flex-direction: column;
}
.sectionSettingsconnectingWith_twitter {
  display: flex;
  flex-direction: column;
  margin-bottom: 0px;
  height: 100%;
  min-height: 0;
  padding: 10px;
}
.sectionSettingsconnectingWith_twitter > :global(.__wab_flex-container) {
  flex-direction: column;
  min-height: 0;
  margin-left: calc(0px - 0px);
  width: calc(100% + 0px);
  margin-top: calc(0px - 10px);
  height: calc(100% + 10px);
}
.sectionSettingsconnectingWith_twitter > :global(.__wab_flex-container) > *,
.sectionSettingsconnectingWith_twitter
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > *,
.sectionSettingsconnectingWith_twitter
  > :global(.__wab_flex-container)
  > picture
  > img,
.sectionSettingsconnectingWith_twitter
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 0px;
  margin-top: 10px;
}
.sectionSettingsconnectingWith_spotify {
  display: flex;
  flex-direction: column;
  margin-bottom: 0px;
  height: 100%;
  min-height: 0;
  padding: 10px;
}
.sectionSettingsconnectingWith_spotify > :global(.__wab_flex-container) {
  flex-direction: column;
  min-height: 0;
  margin-left: calc(0px - 0px);
  width: calc(100% + 0px);
  margin-top: calc(0px - 10px);
  height: calc(100% + 10px);
}
.sectionSettingsconnectingWith_spotify > :global(.__wab_flex-container) > *,
.sectionSettingsconnectingWith_spotify
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > *,
.sectionSettingsconnectingWith_spotify
  > :global(.__wab_flex-container)
  > picture
  > img,
.sectionSettingsconnectingWith_spotify
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 0px;
  margin-top: 10px;
}
.sectionSettingscategoryTypeOverview_pro {
  display: flex;
  flex-direction: column;
  margin-bottom: 0px;
  height: auto;
  flex-grow: 0;
  padding: 10px;
}
.sectionSettingscategoryTypeOverview_pro > :global(.__wab_flex-container) {
  flex-direction: column;
  margin-left: calc(0px - 0px);
  width: calc(100% + 0px);
  margin-top: calc(0px - 10px);
  height: calc(100% + 10px);
}
.sectionSettingscategoryTypeOverview_pro > :global(.__wab_flex-container) > *,
.sectionSettingscategoryTypeOverview_pro
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > *,
.sectionSettingscategoryTypeOverview_pro
  > :global(.__wab_flex-container)
  > picture
  > img,
.sectionSettingscategoryTypeOverview_pro
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 0px;
  margin-top: 10px;
}
.sectionSettingscategoryTypeOverview_complete {
  display: flex;
  flex-direction: column;
  margin-bottom: 0px;
  height: auto;
  flex-grow: 0;
  padding: 10px;
}
.sectionSettingscategoryTypeOverview_complete > :global(.__wab_flex-container) {
  flex-direction: column;
  margin-left: calc(0px - 0px);
  width: calc(100% + 0px);
  margin-top: calc(0px - 10px);
  height: calc(100% + 10px);
}
.sectionSettingscategoryTypeOverview_complete
  > :global(.__wab_flex-container)
  > *,
.sectionSettingscategoryTypeOverview_complete
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > *,
.sectionSettingscategoryTypeOverview_complete
  > :global(.__wab_flex-container)
  > picture
  > img,
.sectionSettingscategoryTypeOverview_complete
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 0px;
  margin-top: 10px;
}
.sectionSettingscategoryTypeOverview_free {
  display: flex;
  flex-direction: column;
  margin-bottom: 0px;
  height: auto;
  flex-grow: 0;
  padding: 10px;
}
.sectionSettingscategoryTypeOverview_free > :global(.__wab_flex-container) {
  flex-direction: column;
  margin-left: calc(0px - 0px);
  width: calc(100% + 0px);
  margin-top: calc(0px - 10px);
  height: calc(100% + 10px);
}
.sectionSettingscategoryTypeOverview_free > :global(.__wab_flex-container) > *,
.sectionSettingscategoryTypeOverview_free
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > *,
.sectionSettingscategoryTypeOverview_free
  > :global(.__wab_flex-container)
  > picture
  > img,
.sectionSettingscategoryTypeOverview_free
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 0px;
  margin-top: 10px;
}
.sectionSettingscategoryOverviewForType_youtube {
  display: flex;
  flex-direction: column;
  margin-bottom: 0px;
  height: auto;
  flex-grow: 0;
  padding: 10px;
}
.sectionSettingscategoryOverviewForType_youtube
  > :global(.__wab_flex-container) {
  flex-direction: column;
  margin-left: calc(0px - 0px);
  width: calc(100% + 0px);
  margin-top: calc(0px - 10px);
  height: calc(100% + 10px);
}
.sectionSettingscategoryOverviewForType_youtube
  > :global(.__wab_flex-container)
  > *,
.sectionSettingscategoryOverviewForType_youtube
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > *,
.sectionSettingscategoryOverviewForType_youtube
  > :global(.__wab_flex-container)
  > picture
  > img,
.sectionSettingscategoryOverviewForType_youtube
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 0px;
  margin-top: 10px;
}
.sectionSettingscategoryOverviewForType_twitter {
  display: flex;
  flex-direction: column;
  margin-bottom: 0px;
  height: auto;
  flex-grow: 0;
  padding: 10px;
}
.sectionSettingscategoryOverviewForType_twitter
  > :global(.__wab_flex-container) {
  flex-direction: column;
  margin-left: calc(0px - 0px);
  width: calc(100% + 0px);
  margin-top: calc(0px - 10px);
  height: calc(100% + 10px);
}
.sectionSettingscategoryOverviewForType_twitter
  > :global(.__wab_flex-container)
  > *,
.sectionSettingscategoryOverviewForType_twitter
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > *,
.sectionSettingscategoryOverviewForType_twitter
  > :global(.__wab_flex-container)
  > picture
  > img,
.sectionSettingscategoryOverviewForType_twitter
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 0px;
  margin-top: 10px;
}
.sectionSettingscategoryOverviewForType_spotify {
  display: flex;
  flex-direction: column;
  margin-bottom: 0px;
  height: auto;
  flex-grow: 0;
  padding: 10px;
}
.sectionSettingscategoryOverviewForType_spotify
  > :global(.__wab_flex-container) {
  flex-direction: column;
  margin-left: calc(0px - 0px);
  width: calc(100% + 0px);
  margin-top: calc(0px - 10px);
  height: calc(100% + 10px);
}
.sectionSettingscategoryOverviewForType_spotify
  > :global(.__wab_flex-container)
  > *,
.sectionSettingscategoryOverviewForType_spotify
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > *,
.sectionSettingscategoryOverviewForType_spotify
  > :global(.__wab_flex-container)
  > picture
  > img,
.sectionSettingscategoryOverviewForType_spotify
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 0px;
  margin-top: 10px;
}
.sectionSettingsbaseVariant__default {
  height: 18em;
  flex-shrink: 0;
}
.sectionSettingsbaseVariant_searchResults {
  height: 38rem;
  flex-shrink: 0;
}
.sectionSettingsbaseVariant_expanded {
  height: 36rem;
  flex-shrink: 0;
}
.sectionSettingsbaseVariant_playlistInput {
  height: 26em;
  flex-shrink: 0;
}
.sectionSettingsbaseVariant_searchTermInput {
  height: auto;
}
.sectionSettingsbaseVariant_noSources {
  height: auto;
}
.sectionSettingsbaseVariant_urlSearch {
  height: 26em;
  flex-shrink: 0;
}
.freeBox__wLyk {
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  top: auto;
  left: auto;
  min-width: 0;
  display: none;
  padding: 8px;
}
.freeBoxconnectingWith_twitter__wLykS4RmW {
  position: relative;
  top: auto;
  left: auto;
  align-items: center;
  justify-content: center;
  display: flex;
}
.freeBoxconnectingWith_spotify__wLykXHcTg {
  position: relative;
  top: auto;
  left: auto;
  align-items: center;
  justify-content: center;
  display: flex;
}
.freeBoxcategoryTypeOverview_pro__wLykAtOnj {
  position: relative;
  top: auto;
  left: auto;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  flex-direction: row;
  min-width: 0;
  display: flex;
  padding: 8px;
}
.freeBoxcategoryTypeOverview_complete__wLykWqYA {
  position: relative;
  top: auto;
  left: auto;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  flex-direction: row;
  min-width: 0;
  display: flex;
  padding: 8px;
}
.freeBoxcategoryTypeOverview_free__wLykArOg {
  position: relative;
  top: auto;
  left: auto;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  flex-direction: row;
  min-width: 0;
  display: flex;
  padding: 8px;
}
.freeBoxcategoryOverviewForType_youtube__wLykKVGoG {
  position: relative;
  top: auto;
  left: auto;
  align-items: center;
  justify-content: center;
  display: flex;
}
.freeBoxcategoryOverviewForType_twitter__wLykYtWIf {
  position: relative;
  top: auto;
  left: auto;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  flex-direction: row;
  min-width: 0;
  display: flex;
  padding: 8px;
}
.freeBoxcategoryOverviewForType_spotify__wLykO7Fwt {
  position: relative;
  top: auto;
  left: auto;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  flex-direction: row;
  min-width: 0;
  display: flex;
  padding: 8px;
}
.sourceCategorySelectionBackButton {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
.sourceCategorySelectionBackButtonconnectingWith_twitter {
  width: auto;
}
.sourceCategorySelectionBackButtonconnectingWith_spotify {
  width: auto;
}
.sourceCategorySelectionBackButtoncategoryTypeOverview_pro {
  width: auto;
}
.sourceCategorySelectionBackButtoncategoryTypeOverview_complete {
  width: auto;
}
.sourceCategorySelectionBackButtoncategoryTypeOverview_free {
  width: auto;
}
.sourceCategorySelectionBackButtoncategoryOverviewForType_youtube {
  width: auto;
}
.sourceCategorySelectionBackButtoncategoryOverviewForType_twitter {
  width: auto;
}
.sourceCategorySelectionBackButtoncategoryOverviewForType_spotify {
  width: auto;
}
.leftArrow {
  object-fit: cover;
  max-width: 100%;
  color: var(--token-kgwkYLWcU);
  height: 1em;
  display: none;
}
.leftArrowconnectingWith_twitter {
  display: block;
}
.leftArrowconnectingWith_spotify {
  display: block;
}
.leftArrowcategoryTypeOverview_pro {
  display: block;
}
.leftArrowcategoryTypeOverview_complete {
  display: block;
}
.leftArrowcategoryTypeOverview_free {
  display: block;
}
.leftArrowcategoryOverviewForType_youtube {
  display: block;
}
.leftArrowcategoryOverviewForType_twitter {
  display: block;
}
.leftArrowcategoryOverviewForType_spotify {
  display: block;
}
.text__xCeac {
  width: 100%;
  height: auto;
  max-width: 800px;
  color: var(--token-kgwkYLWcU);
  min-width: 0;
  display: none;
}
.textconnectingWith_twitter__xCeacs4RmW {
  text-align: center;
  font-size: 0.8em;
  opacity: 1;
  display: block;
}
.textconnectingWith_spotify__xCeacxHcTg {
  text-align: center;
  font-size: 0.8em;
  opacity: 1;
  display: block;
}
.textcategoryTypeOverview_pro__xCeacatOnj {
  text-align: center;
  font-size: 0.8em;
  display: block;
}
.textcategoryTypeOverview_complete__xCeacWqYA {
  text-align: center;
  font-size: 0.8em;
  display: block;
}
.textcategoryTypeOverview_free__xCeacArOg {
  text-align: center;
  font-size: 0.8em;
  display: block;
}
.textcategoryOverviewForType_youtube__xCeackVGoG {
  text-align: center;
  font-size: 0.8em;
  display: block;
}
.textcategoryOverviewForType_twitter__xCeacYtWIf {
  text-align: center;
  font-size: 0.8em;
  display: block;
}
.textcategoryOverviewForType_spotify__xCeacO7Fwt {
  text-align: center;
  font-size: 0.8em;
  display: block;
}
.sourceCategoryButtonStack {
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  max-width: 100%;
  left: auto;
  top: auto;
  min-width: 0;
  min-height: 0;
  display: none;
  padding: 8px;
}
.sourceCategoryButtonStackconnectingWith_twitter {
  position: relative;
  left: auto;
  top: auto;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: flex-start;
}
.sourceCategoryButtonStackconnectingWith_spotify {
  position: relative;
  left: auto;
  top: auto;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: flex-start;
}
.sourceCategoryButtonStackcategoryTypeOverview_pro {
  position: relative;
  left: auto;
  top: auto;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: stretch;
  height: auto;
  display: flex;
}
.sourceCategoryButtonStackcategoryTypeOverview_complete {
  position: relative;
  left: auto;
  top: auto;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: stretch;
  height: auto;
  display: flex;
}
.sourceCategoryButtonStackcategoryTypeOverview_free {
  position: relative;
  left: auto;
  top: auto;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: stretch;
  height: auto;
  display: flex;
}
.sourceCategoryButtonStackcategoryOverviewForType_youtube {
  position: relative;
  left: auto;
  top: auto;
  justify-content: flex-start;
  height: auto;
  display: flex;
}
.sourceCategoryButtonStackcategoryOverviewForType_twitter {
  position: relative;
  left: auto;
  top: auto;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: flex-start;
  height: auto;
  display: flex;
}
.sourceCategoryButtonStackcategoryOverviewForType_spotify {
  position: relative;
  left: auto;
  top: auto;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: flex-start;
  box-shadow: none;
  height: auto;
  display: flex;
}
.websites:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.websitesconnectingWith_twitter:global(.__wab_instance) {
  display: none;
}
.websitesconnectingWith_spotify:global(.__wab_instance) {
  display: none;
}
.websitescategoryOverviewForType_twitter:global(.__wab_instance) {
  display: none;
}
.websitescategoryOverviewForType_spotify:global(.__wab_instance) {
  display: none;
}
.youtube:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.youtubeChannels:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.youtubePlaylists:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.twitter:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.twitterTimelines:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.twitterBookmarks:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.twitterHomeFeed:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.twitterLikes:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.twitterMentions:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.twitterLists:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.twitterSearches:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.spotifyMusicians:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.spotifyMusicianscategoryOverviewForType_twitter:global(.__wab_instance) {
  display: none;
}
.spotifyPodcasts:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.spotifyPodcastscategoryOverviewForType_twitter:global(.__wab_instance) {
  display: none;
}
.spotifyPlaylists:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.spotifyPlaylistscategoryOverviewForType_twitter:global(.__wab_instance) {
  display: none;
}
.podcasts:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.podcastsconnectingWith_twitter:global(.__wab_instance) {
  display: none;
}
.podcastsconnectingWith_spotify:global(.__wab_instance) {
  display: none;
}
.podcastscategoryOverviewForType_twitter:global(.__wab_instance) {
  display: none;
}
.podcastscategoryOverviewForType_spotify:global(.__wab_instance) {
  display: none;
}
.savedForLater:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.savedForLaterconnectingWith_twitter:global(.__wab_instance) {
  display: none;
}
.savedForLaterconnectingWith_spotify:global(.__wab_instance) {
  display: none;
}
.savedForLatercategoryOverviewForType_twitter:global(.__wab_instance) {
  display: none;
}
.savedForLatercategoryOverviewForType_spotify:global(.__wab_instance) {
  display: none;
}
.spotify:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.sourceCategoryOptions {
  transform: translate(0px, -0.5px);
  width: auto;
  height: auto;
  display: flex;
  position: relative;
  top: auto;
  left: auto;
  overflow: visible;
  flex-direction: row;
  min-width: 20em;
  flex-shrink: 1;
  flex-grow: 0;
  transition-property: border;
  transition-duration: 1s;
  -webkit-transition-property: border;
  -webkit-transition-duration: 1s;
  padding: 0.8em 1em;
}
.sourceCategoryOptions > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: calc(0px - 2em);
  width: calc(100% + 2em);
}
.sourceCategoryOptions > :global(.__wab_flex-container) > *,
.sourceCategoryOptions
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > *,
.sourceCategoryOptions > :global(.__wab_flex-container) > picture > img,
.sourceCategoryOptions
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 2em;
}
.sourceCategoryOptionsconnectingWith_twitter {
  position: relative;
  left: auto;
  top: auto;
}
.sourceCategoryOptionsconnectingWith_spotify {
  position: relative;
  left: auto;
  top: auto;
}
.sourceCategoryOptionscategoryTypeOverview_pro {
  position: relative;
  left: auto;
  top: auto;
}
.sourceCategoryOptionscategoryTypeOverview_complete {
  position: relative;
  left: auto;
  top: auto;
}
.sourceCategoryOptionscategoryTypeOverview_free {
  position: relative;
  left: auto;
  top: auto;
}
.sourceCategoryOptionscategoryOverviewForType_youtube {
  position: relative;
  left: auto;
  top: auto;
}
.sourceCategoryOptionscategoryOverviewForType_twitter {
  position: relative;
  left: auto;
  top: auto;
}
.sourceCategoryOptionscategoryOverviewForType_spotify {
  position: relative;
  left: auto;
  top: auto;
}
.sourceCategorySelection {
  width: 11.4em;
  height: auto;
  display: flex;
  position: relative;
  flex-direction: row;
  flex-grow: 0;
  flex-shrink: 0;
  align-self: auto;
}
.sourceCategorySelection > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: calc(0px - 0.35em);
  width: calc(100% + 0.35em);
}
.sourceCategorySelection > :global(.__wab_flex-container) > *,
.sourceCategorySelection
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > *,
.sourceCategorySelection > :global(.__wab_flex-container) > picture > img,
.sourceCategorySelection
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 0.35em;
}
.sourceCategorySelectionconnectingWith_twitter {
  display: none;
}
.sourceCategorySelectionconnectingWith_spotify {
  display: none;
}
.sourceCategorySelectioncategoryTypeOverview_free {
  display: none;
}
.sourceCategorySelectioncategoryOverviewForType_youtube {
  display: none;
}
.sourceCategorySelectioncategoryOverviewForType_twitter {
  display: none;
}
.sourceCategorySelectioncategoryOverviewForType_spotify {
  display: none;
}
.text__c1KcU {
  display: block;
  font-size: 0.5em;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  letter-spacing: 0px;
  color: rgba(195, 195, 195, 1);
  position: relative;
  width: auto;
  min-width: 6em;
  flex-grow: 1;
  flex-basis: auto;
  flex-shrink: 0;
}
.plus {
  width: 0px;
  height: 0px;
  display: block;
  position: relative;
  flex-shrink: 0;
}
.rectangle3 {
  background: rgba(195, 195, 195, 1);
  transform: translate(-0.00899999999998613px, 0px);
  width: 1px;
  display: block;
  position: absolute;
  top: 0px;
  left: 3px;
  bottom: -7px;
  border-radius: 1.227px;
}
.rectangle4 {
  background: rgba(195, 195, 195, 1);
  transform: rotate(-90deg) translate(0px, -0.18099999999999916px);
  width: 1px;
  display: block;
  transform-origin: top left;
  position: absolute;
  top: 4px;
  left: 0px;
  bottom: -11px;
  border-radius: 1.227px;
}
.svg__tVRkB {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  color: #d6455b;
  width: 6px;
  height: 6px;
  display: block;
  flex-shrink: 0;
}
.currentSourceCategory:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.sortingModeBox {
  width: auto;
  height: 1.25em;
  display: flex;
  position: relative;
  flex-direction: row;
}
.sortingModeBox > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: calc(0px - 0.6em);
  width: calc(100% + 0.6em);
}
.sortingModeBox > :global(.__wab_flex-container) > *,
.sortingModeBox > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.sortingModeBox > :global(.__wab_flex-container) > picture > img,
.sortingModeBox
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 0.6em;
}
.sortingModeBoxconnectingWith_twitter {
  display: none;
}
.sortingModeBoxconnectingWith_spotify {
  display: none;
}
.sortingModeBoxcategoryTypeOverview_free {
  display: none;
}
.sortingModeBoxcategoryOverviewForType_youtube {
  display: none;
}
.sortingModeBoxcategoryOverviewForType_twitter {
  display: none;
}
.sortingModeBoxcategoryOverviewForType_spotify {
  display: none;
}
.text__cUYdF {
  display: block;
  font-size: 0.5em;
  text-align: center;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  letter-spacing: 0px;
  color: rgba(195, 195, 195, 1);
  flex-grow: 1;
  flex-shrink: 0;
  position: relative;
  left: auto;
  top: auto;
  line-height: 1.3;
}
.dropdown {
  background: linear-gradient(
      228deg,
      rgba(52, 48, 49, 1) 53%,
      rgba(42, 37, 38, 1) 114%
    )
    0% 0% / 100% 100% no-repeat;
  box-shadow: inset 0px 0px 0px 0.3px rgba(69, 82, 119, 0.3);
  filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.1));
  width: 4em;
  height: 1.25em;
  position: relative;
  top: auto;
  left: auto;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  display: none;
  border-radius: 3px;
}
.dropdownfooter_twitterConnect {
  border-radius: 0.2em;
}
.sortingMode {
  position: relative;
  top: auto;
  left: auto;
  width: 78.48%;
  height: 70%;
  transform: translate(-0.15200000000000014px, -0.5px);
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.sortingMode2 {
  display: block;
  font-size: 0.5em;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  letter-spacing: 0px;
  line-height: 1em;
  color: rgba(250, 247, 238, 1);
}
.svg__zwMbg {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  color: #979797;
  transform: translate(-0.3549999999999969px, -0.5px);
  width: 0.4em;
  height: 0.4em;
  display: block;
  top: auto;
  left: auto;
  flex-shrink: 0;
}
.sortingModeDropdown:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.freeBox___8KOcp {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: 100%;
  max-width: 100%;
  top: auto;
  right: auto;
  align-self: flex-start;
  min-width: 0;
  min-height: 0;
  padding: 0px;
}
.freeBox___8KOcp > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  min-width: 0;
  min-height: 0;
  margin-left: calc(0px - 4px);
  width: calc(100% + 4px);
}
.freeBox___8KOcp > :global(.__wab_flex-container) > *,
.freeBox___8KOcp > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox___8KOcp > :global(.__wab_flex-container) > picture > img,
.freeBox___8KOcp
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 4px;
}
.freeBoxconnectingWith_twitter___8KOcps4RmW {
  position: relative;
  top: auto;
  right: auto;
}
.freeBoxconnectingWith_spotify___8KOcpxHcTg {
  position: relative;
  top: auto;
  right: auto;
}
.freeBoxcategoryTypeOverview_pro___8KOcpatOnj {
  position: relative;
  top: auto;
  right: auto;
}
.freeBoxcategoryTypeOverview_complete___8KOcpWqYA {
  position: relative;
  top: auto;
  right: auto;
}
.freeBoxcategoryTypeOverview_free___8KOcpArOg {
  position: relative;
  top: auto;
  right: auto;
}
.freeBoxcategoryOverviewForType_youtube___8KOcpkVGoG {
  position: relative;
  top: auto;
  right: auto;
}
.freeBoxcategoryOverviewForType_twitter___8KOcpYtWIf {
  position: relative;
  top: auto;
  right: auto;
}
.freeBoxcategoryOverviewForType_spotify___8KOcpO7Fwt {
  position: relative;
  top: auto;
  right: auto;
}
.optionsMenu {
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: auto;
  height: auto;
  max-width: 100%;
  display: none;
  padding: 8px;
}
.svg__yOx4A {
  object-fit: cover;
  max-width: 100%;
  color: #c4c4c4;
  transform: translate(-0.09399999999999409px, 0px);
  width: 0.2em;
  height: 0.9em;
  display: block;
  flex-shrink: 0;
}
.overlayMenuBtn:global(.__wab_instance) {
  max-width: 100%;
}
.freeBox__kq6WJ {
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  margin-left: calc(0px + 2em) !important;
  min-width: 0;
  display: none;
  padding: 0px;
}
.menuButton {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: auto;
  height: auto;
  max-width: 100%;
  opacity: 1;
  padding: 8px;
}
.localHeaderSubmenu {
  object-fit: contain;
  max-width: 100%;
  width: 1.25em;
  height: auto;
  max-height: 1.56em;
  flex-shrink: 0;
}
.localHeaderSubmenu > picture > img {
  object-fit: contain;
}
.freeBox__bslVd {
  display: flex;
  flex-direction: column;
  position: absolute;
  width: auto;
  height: auto;
  background: #353132b3;
  max-width: var(--token-a9MdTTayw);
  transform: translateX(0px) translateY(0px) translateZ(0px);
  left: 32px;
  top: 43px;
  border-radius: 10px;
  padding: 8px 12px;
}
.freeBox__bslVd > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: calc(0px - 2px);
  height: calc(100% + 2px);
}
.freeBox__bslVd > :global(.__wab_flex-container) > *,
.freeBox__bslVd > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__bslVd > :global(.__wab_flex-container) > picture > img,
.freeBox__bslVd
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 2px;
}
.freeBox__hbfZp {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  height: auto;
  max-width: 100%;
  margin-bottom: -6px;
  align-self: flex-end;
  min-width: 0;
  padding: 0px;
}
.closeButton {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: auto;
  height: auto;
  max-width: 100%;
  align-self: flex-end;
  padding: 0px;
}
.close {
  object-fit: cover;
  max-width: 100%;
  color: var(--token-kgwkYLWcU);
  height: 1em;
  flex-shrink: 0;
}
.deleteSectionButton {
  display: flex;
  position: relative;
  flex-direction: row;
  width: auto;
  height: auto;
  max-width: 100%;
  margin-top: calc(7px + 2px) !important;
  padding: 0px;
}
.deleteSectionButton > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: calc(0px - 4px);
  width: calc(100% + 4px);
}
.deleteSectionButton > :global(.__wab_flex-container) > *,
.deleteSectionButton
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > *,
.deleteSectionButton > :global(.__wab_flex-container) > picture > img,
.deleteSectionButton
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 4px;
}
.deleteSectionButtonfooter_twitterConnect {
  margin-top: calc(7px + 2px) !important;
}
.text__mM0E {
  width: auto;
  height: auto;
  max-width: 800px;
  text-align: right;
  font-size: 0.5em;
  color: var(--token-6Pfaiv2lJ);
}
.svg__eWEwD {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  color: var(--token-6Pfaiv2lJ);
  height: 1em;
}
.group232 {
  filter: drop-shadow(0px 2px 3px rgba(255, 255, 255, 0.15));
  width: 197px;
  height: 41px;
  flex-shrink: 0;
  display: none;
}
.group267 {
  width: 108px;
  height: 41px;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;
}
.img__mf6Do {
  position: absolute;
  object-fit: cover;
  max-width: 100%;
  filter: drop-shadow(0px 2px 3px rgba(0, 0, 0, 0.25));
  width: 108px;
  height: 41px;
  display: block !important;
  top: 0%;
  left: 0%;
}
.img__mf6Do > picture > img {
  object-fit: cover;
}
.darkMode {
  position: absolute;
  top: 0%;
  left: 19.53%;
  width: 50%;
  height: 101.71%;
  transform: translate(-0.1670000000000016px, 0px);
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.darkMode2 {
  display: block;
  font-size: 10.076px;
  text-align: center;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  letter-spacing: 0px;
  line-height: 26.871px;
  color: rgba(250, 247, 238, 1);
}
.group266 {
  filter: drop-shadow(0px 2px 3px rgba(0, 0, 0, 0.25));
  transform: translate(0.3709999999999951px, 0px);
  width: 105px;
  height: 41px;
  display: block;
  position: absolute;
  top: 0px;
  left: 92px;
}
.img__voXx9 {
  position: absolute;
  object-fit: cover;
  max-width: 100%;
  width: 105px;
  height: 41px;
  display: block !important;
  top: 0%;
  left: 0%;
}
.img__voXx9 > picture > img {
  object-fit: cover;
}
.eggshellMode {
  position: absolute;
  top: 17.36%;
  left: 23.04%;
  width: 67.74%;
  height: 66.98%;
  transform: translate(0.19200000000000728px, -0.2820000000000107px);
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.text__uluXg {
  display: block;
  font-size: 10.076px;
  text-align: center;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  letter-spacing: 0px;
  line-height: 26.871px;
  color: rgba(64, 60, 60, 1);
}
.sectionSettingsContent {
  display: block;
  position: relative;
  top: auto;
  left: auto;
  right: auto;
  bottom: auto;
  overflow: hidden;
  height: 100%;
  flex-grow: 1;
  min-height: 0;
  padding: 0px;
}
.sectionSettingsContentfooter_hidden {
  bottom: -2px;
  margin-bottom: 0px;
}
.sectionSettingsContentconnectingWith_twitter {
  position: relative;
  left: auto;
  top: auto;
  bottom: auto;
  right: auto;
  display: none;
}
.sectionSettingsContentconnectingWith_spotify {
  position: relative;
  left: auto;
  top: auto;
  bottom: auto;
  right: auto;
  display: none;
}
.sectionSettingsContentcategoryTypeOverview_pro {
  position: relative;
  left: auto;
  top: auto;
  bottom: auto;
  right: auto;
}
.sectionSettingsContentcategoryTypeOverview_complete {
  position: relative;
  left: auto;
  top: auto;
  bottom: auto;
  right: auto;
}
.sectionSettingsContentcategoryTypeOverview_free {
  position: relative;
  left: auto;
  top: auto;
  bottom: auto;
  right: auto;
}
.sectionSettingsContentcategoryOverviewForType_youtube {
  position: relative;
  left: auto;
  top: auto;
  bottom: auto;
  right: auto;
  display: none;
}
.sectionSettingsContentcategoryOverviewForType_twitter {
  position: relative;
  left: auto;
  top: auto;
  bottom: auto;
  right: auto;
  display: none;
}
.sectionSettingsContentcategoryOverviewForType_spotify {
  position: relative;
  left: auto;
  top: auto;
  bottom: auto;
  right: auto;
  display: none;
}
.sectionSettingsContentbaseVariant_searchTermInput {
  display: flex;
  flex-direction: column;
}
.svg__yLj6B {
  position: absolute;
  object-fit: cover;
  max-width: 100%;
  color: #c4c4c4;
  box-shadow: 0px -1px 4px 0px #ffffff4d;
  transform: scale(1.000000499999875, 0.999999500000375)
    rotate(-0.057295760416576934deg) skew(-0.05729570311883569deg, 0deg)
    translate(0px, -0.049000000000006594px);
  width: 100%;
  height: 0.01em;
  display: block;
  transform-origin: top left;
  top: auto;
  left: 0%;
  bottom: 0%;
  min-width: 0;
}
.svgfooter_hidden__yLj6B9UZmM {
  display: none;
}
.svgbaseVariant_searchTermInput__yLj6BlGeLg {
  position: relative;
  left: auto;
  top: auto;
  bottom: auto;
}
.svg___1C2Gg {
  position: absolute;
  object-fit: cover;
  max-width: 100%;
  color: #c4c4c4;
  box-shadow: 0px 0px 4px 0px #ffffff4d;
  transform: skew(-0.057295760414500616deg, 0deg);
  height: 0.01em;
  display: block;
  transform-origin: top left;
  top: 0px;
  left: 0px;
  right: 0px;
  width: 100%;
  min-width: 0;
}
.svgconnectingWith_twitter___1C2Ggs4RmW {
  display: none;
}
.svgconnectingWith_spotify___1C2GgxHcTg {
  display: none;
}
.svgcategoryTypeOverview_pro___1C2GgatOnj {
  display: none;
}
.svgcategoryTypeOverview_complete___1C2GgWqYA {
  display: none;
}
.svgcategoryTypeOverview_free___1C2GgArOg {
  display: none;
}
.svgcategoryOverviewForType_youtube___1C2GgkVGoG {
  display: none;
}
.svgcategoryOverviewForType_twitter___1C2GgYtWIf {
  display: none;
}
.svgcategoryOverviewForType_spotify___1C2GgO7Fwt {
  display: none;
}
.svgbaseVariant_searchTermInput___1C2GglGeLg {
  position: relative;
  left: auto;
  top: auto;
  right: auto;
}
.contentFrame {
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  top: 0px;
  left: 0px;
  flex-direction: column;
  min-width: 0;
  min-height: 0;
  padding: 0.25em 0px 0.4em;
}
.contentFrame > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  min-height: 0;
  margin-top: calc(0px - 0.25em);
  height: calc(100% + 0.25em);
}
.contentFrame > :global(.__wab_flex-container) > *,
.contentFrame > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.contentFrame > :global(.__wab_flex-container) > picture > img,
.contentFrame
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 0.25em;
}
.contentFrameconnectingWith_twitter {
  height: auto;
  display: none;
}
.contentFrameconnectingWith_spotify {
  height: auto;
  display: none;
}
.contentFramecategoryTypeOverview_pro {
  height: auto;
  display: none;
}
.contentFramecategoryTypeOverview_complete {
  height: auto;
  display: none;
}
.contentFramecategoryTypeOverview_free {
  height: auto;
  display: none;
}
.contentFramecategoryOverviewForType_youtube {
  height: auto;
  display: none;
}
.contentFramecategoryOverviewForType_twitter {
  height: auto;
  display: none;
}
.contentFramecategoryOverviewForType_spotify {
  height: auto;
  display: none;
}
.contentFramebaseVariant_searchTermInput {
  position: relative;
  left: auto;
  top: auto;
}
.sectionSettingsSourcePanel:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  left: auto;
  top: auto;
}
.sourceList {
  height: auto;
  position: relative;
  align-self: stretch;
  overflow: hidden;
  flex-grow: 0;
  flex-direction: row;
  display: none;
}
.freeBox__sxA6S {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  margin-top: calc(0.2em + 0.25em) !important;
  min-width: 0;
  padding: 8px;
}
.freeBoxbaseVariant__default__sxA6SBswdH {
  margin-top: calc(0.2em + 0.25em) !important;
}
.freeBoxbaseVariant_searchTermInput__sxA6SlGeLg {
  margin-top: calc(0.2em + 0.25em) !important;
}
.searchBar:global(.__wab_instance) {
  max-width: 100%;
}
.button__lLs1K:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  display: none;
}
.buttonbaseVariant_searchTermInput__lLs1KlGeLg:global(.__wab_instance) {
  display: flex;
}
.svg___6V8Dk {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__kNVrX {
  font-size: 0.8em;
}
.svg__aNnTq {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.resultsPanel {
  transform: translate(0px, -0.129999999999999px);
  display: flex;
  position: relative;
  top: auto;
  left: auto;
  right: auto;
  bottom: auto;
  overflow: hidden;
  width: 100%;
  height: 100%;
  flex-direction: column;
  min-width: 0;
  min-height: 0;
  padding: 0.6em 0px 0.25em;
}
.resultsPanelconnectingWith_twitter {
  display: none;
}
.resultsPanelconnectingWith_spotify {
  display: none;
}
.resultsPanelcategoryTypeOverview_pro {
  display: none;
}
.resultsPanelcategoryTypeOverview_complete {
  display: none;
}
.resultsPanelcategoryTypeOverview_free {
  display: none;
}
.resultsPanelcategoryOverviewForType_youtube {
  display: none;
}
.resultsPanelcategoryOverviewForType_twitter {
  display: none;
}
.resultsPanelcategoryOverviewForType_spotify {
  display: none;
}
.resultsPanelbaseVariant_searchResults {
  flex-direction: column-reverse;
}
.text__emtA3 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: 8px;
  color: var(--token-B8u26uCIS);
  font-weight: 400;
  padding-left: 10px;
  min-width: 0;
  display: none;
}
.textbaseVariant_searchResults__emtA3ERWq {
  font-size: 0.5em;
  display: none;
}
.textbaseVariant_expanded__emtA3ECdj {
  font-size: 0.5em;
  width: 100%;
  height: auto;
  padding-left: 2em;
  font-weight: 400;
  color: var(--token-B8u26uCIS);
  min-width: 0;
  display: block;
}
.interestTilePanel:global(.__wab_instance) {
  position: relative;
  margin-top: 0px;
  margin-bottom: 0.5em;
}
.text__iSi {
  position: relative;
  width: 100%;
  height: auto;
  font-size: 0.5em;
  color: var(--token-B8u26uCIS);
  font-weight: 400;
  padding-left: 2em;
  min-width: 0;
}
.textbaseVariant_searchResults__iSiERWq {
  display: none;
}
.textbaseVariant_expanded__iSiECdj {
  display: block;
}
.textbaseVariant_playlistInput__iSiy6WVb {
  display: none;
}
.textbaseVariant_searchTermInput__iSilGeLg {
  display: none;
}
.textbaseVariant_urlSearch__iSiQ8Mi {
  display: none;
}
.freeBox__tPx0G {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  max-width: 100%;
  margin-top: 0px;
  min-width: 0;
  min-height: 0;
  padding: 0.25em 0.32em;
}
.freeBoxfooter_twitterConnect__tPx0GhAvbp {
  padding-left: 0.32em;
  padding-right: 0.32em;
}
.freeBoxbaseVariant_searchResults__tPx0GeRWq {
  margin-bottom: 0.5em;
}
.freeBoxbaseVariant_playlistInput__tPx0Gy6WVb {
  display: flex;
  flex-direction: column;
}
.freeBoxbaseVariant_searchTermInput__tPx0GlGeLg {
  display: flex;
  flex-direction: column;
}
.freeBoxbaseVariant_urlSearch__tPx0GQ8Mi {
  display: flex;
  flex-direction: column;
}
.suggestionContainer {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  max-width: 100%;
  flex-wrap: wrap;
  overflow: hidden;
  align-content: stretch;
  min-width: 0;
  min-height: 0;
  padding: 0px 10px;
}
.arrows:global(.__wab_instance) {
  max-width: 100%;
  position: absolute;
  align-self: flex-start;
  left: 0px;
  top: 0px;
}
.suggestionStack {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  max-width: 100%;
  flex-wrap: wrap;
  align-content: flex-start;
  min-width: 0;
  min-height: 0;
  padding: 0.2em;
}
.suggestionStackbaseVariant_searchResults {
  align-content: flex-start;
}
.suggestionStackbaseVariant_playlistInput {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: stretch;
}
.suggestionStackbaseVariant_searchTermInput {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: stretch;
}
.suggestionStackbaseVariant_urlSearch {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: stretch;
}
.expandButton {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
.expandButtonfooter_twitterConnect {
  padding-top: 0.2em;
  padding-bottom: 0.2em;
  display: flex;
}
.expandButtonbaseVariant__default {
  padding-top: 0px;
}
.expandButtonbaseVariant_searchResults {
  display: none;
}
.expandButtonbaseVariant_expanded {
  display: none;
}
.button___6KRvH {
  width: 100%;
  height: auto;
  text-align: center;
  font-size: 0.5em;
  text-decoration-line: underline;
  color: var(--token-pMUHtTtb1);
  min-width: 0;
}
.buttonconnectingWith_twitter___6KRvHs4RmW {
  display: none;
}
.buttonconnectingWith_spotify___6KRvHxHcTg {
  display: none;
}
.buttoncategoryTypeOverview_pro___6KRvHatOnj {
  display: none;
}
.buttoncategoryTypeOverview_complete___6KRvHWqYA {
  display: none;
}
.buttoncategoryTypeOverview_free___6KRvHarOg {
  display: none;
}
.buttoncategoryOverviewForType_youtube___6KRvHkVGoG {
  display: none;
}
.buttoncategoryOverviewForType_twitter___6KRvHYtWIf {
  display: none;
}
.buttoncategoryOverviewForType_spotify___6KRvHo7Fwt {
  display: none;
}
.buttonbaseVariant_searchResults___6KRvHeRWq {
  display: block;
}
.buttonbaseVariant_expanded___6KRvHECdj {
  display: none;
}
.freeBox__kosLt {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  top: auto;
  left: auto;
  bottom: auto;
  right: auto;
  min-width: 0;
  padding: 0.5em;
}
.freeBoxfooter_hidden__kosLt9UZmM {
  padding: 0em;
}
.freeBoxconnectingWith_twitter__kosLTs4RmW {
  position: relative;
  top: auto;
  left: auto;
  bottom: auto;
}
.freeBoxconnectingWith_spotify__kosLTxHcTg {
  position: relative;
  top: auto;
  left: auto;
  bottom: auto;
}
.freeBoxcategoryTypeOverview_pro__kosLTatOnj {
  position: relative;
  top: auto;
  left: auto;
  bottom: auto;
}
.freeBoxcategoryTypeOverview_complete__kosLtWqYA {
  position: relative;
  top: auto;
  left: auto;
  bottom: auto;
}
.freeBoxcategoryTypeOverview_free__kosLtarOg {
  position: relative;
  top: auto;
  left: auto;
  bottom: auto;
}
.freeBoxcategoryOverviewForType_youtube__kosLTkVGoG {
  position: relative;
  top: auto;
  left: auto;
  bottom: auto;
}
.freeBoxcategoryOverviewForType_twitter__kosLtYtWIf {
  position: relative;
  top: auto;
  left: auto;
  bottom: auto;
}
.freeBoxcategoryOverviewForType_spotify__kosLto7Fwt {
  position: relative;
  top: auto;
  left: auto;
  bottom: auto;
}
.footerButton {
  background: linear-gradient(
      193deg,
      #353132 14%,
      #2b2627 70%,
      #353132 117%,
      #2a2526 232%
    )
    0% 0% / 100% 100% no-repeat padding-box border-box scroll;
  filter: drop-shadow(0px 2px 3px rgba(0, 0, 0, 0.25));
  width: auto;
  height: 1.25em;
  display: flex;
  overflow: hidden;
  flex-direction: row;
  flex-shrink: 0;
  border-radius: 4px;
  padding: 0.5em;
}
.footerButton > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: calc(0px - 2px);
  width: calc(100% + 2px);
  margin-top: calc(0px - 0px);
  height: calc(100% + 0px);
}
.footerButton > :global(.__wab_flex-container) > *,
.footerButton > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.footerButton > :global(.__wab_flex-container) > picture > img,
.footerButton
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 2px;
  margin-top: 0px;
}
.footerButtonfooter_hidden {
  display: none;
  padding: 0px;
}
.footerButtonconnectingWith_twitter {
  display: none;
}
.footerButtonconnectingWith_spotify {
  display: none;
}
.footerButtoncategoryTypeOverview_pro {
  display: none;
}
.footerButtoncategoryTypeOverview_complete {
  display: none;
}
.footerButtoncategoryTypeOverview_free {
  display: none;
}
.footerButtoncategoryOverviewForType_youtube {
  display: none;
}
.footerButtoncategoryOverviewForType_twitter {
  display: none;
}
.footerButtoncategoryOverviewForType_spotify {
  display: none;
}
.text__mkps0 {
  display: block;
  font-size: 0.5em;
  text-align: center;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  letter-spacing: 0px;
  color: var(--token-kgwkYLWcU);
  position: relative;
}
.textconnectingWith_twitter__mkps0S4RmW {
  display: none;
}
.textconnectingWith_spotify__mkps0XHcTg {
  display: none;
}
.textcategoryTypeOverview_pro__mkps0AtOnj {
  display: none;
}
.textcategoryTypeOverview_complete__mkps0WqYA {
  display: none;
}
.textcategoryTypeOverview_free__mkps0ArOg {
  display: none;
}
.textcategoryOverviewForType_youtube__mkps0KVGoG {
  display: none;
}
.textcategoryOverviewForType_twitter__mkps0YtWIf {
  display: none;
}
.textcategoryOverviewForType_spotify__mkps0O7Fwt {
  display: none;
}
.svg__uyMx {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  color: var(--token-QkOxHiuRM);
  width: 0.75em;
  height: 0.75em;
  display: block;
  flex-shrink: 0;
}
.redirectingDelete {
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  display: none;
}
.redirectingDeleteconnectingWith_twitter {
  display: none;
}
.redirectingDeleteconnectingWith_spotify {
  display: none;
}
.text__dePq {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  text-align: center;
  min-width: 0;
}
