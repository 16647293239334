.article * {
  margin: 1rem 0;
}

.article h2 {
  font-size: 1.5rem;
  margin-top: 3rem;
}

.article li {
  list-style-type: disc;
  margin-left: 3rem;
}

.article code {
  display: inline-block;
  background-color: #0f0e0e;
  border-radius: 3px;
  padding: 0rem 0.5rem;
  margin: 0;
}
