.root {
  display: inline-flex;
  flex-direction: column;
  position: relative;
  width: auto;
  height: auto;
}
.root > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: calc(0px - 10px);
  height: calc(100% + 10px);
}
.root > :global(.__wab_flex-container) > *,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.root > :global(.__wab_flex-container) > picture > img,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-top: 10px;
}
.currentHubContainer {
  width: auto;
  height: 3.6em;
  display: flex;
  position: relative;
  left: auto;
  top: auto;
  flex-direction: column;
  flex-shrink: 0;
}
.currentHubContainerjustAListOfHubs {
  left: auto;
  top: auto;
  display: none;
}
.currentHubContainercontext_welcomeView {
  height: auto;
}
.currentHubContainerblurContent_showHubCreationPanel {
  opacity: 0.08;
}
.freeBox {
  display: flex;
  position: relative;
  flex-direction: column;
  width: auto;
  height: 43px;
  flex-wrap: nowrap;
  align-items: stretch;
  align-content: stretch;
  flex-shrink: 0;
}
.freeBoxcontext_welcomeView {
  height: auto;
}
.slotTargetCurrentHub {
  font-family: "Open Sans", sans-serif;
  font-size: 29.562px;
  font-weight: 700;
  color: rgba(255, 255, 255, 1);
  text-align: center;
  line-height: 43px;
  letter-spacing: 0px;
  white-space: pre;
}
.slotTargetHeadline {
  font-family: "Rubik", sans-serif;
  font-size: 1em;
  font-weight: 600;
  color: var(--token-7YSTg8Ece);
  text-align: center;
  letter-spacing: 0px;
}
.blueDivider {
  width: 36px;
  height: 4px;
  display: block;
  position: relative;
  left: auto;
  top: auto;
  align-self: center;
  background: rgba(33, 65, 179, 1);
  flex-shrink: 0;
  border-radius: 7.6px;
}
.hubListContainer {
  width: 18em;
  height: auto;
  display: flex;
  position: relative;
  left: auto;
  top: auto;
  flex-direction: column;
}
.hubListContainer > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  align-content: unset;
  margin-top: calc(0px - 4px);
  height: calc(100% + 4px);
}
.hubListContainer > :global(.__wab_flex-container) > *,
.hubListContainer > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.hubListContainer > :global(.__wab_flex-container) > picture > img,
.hubListContainer
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 4px;
}
.hubListContainerjustAListOfHubs {
  left: auto;
  top: auto;
}
.hubListContainerscrollableList {
  max-height: 25em;
  overflow: auto;
}
.hubListContainerblurContent_showHubCreationPanel {
  opacity: 0.08;
}
.text {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  align-self: center;
  text-align: center;
  line-height: 0.2em;
  color: var(--token-pMUHtTtb1);
  margin-bottom: 0px;
  font-family: "Oldenburg";
  min-width: 0;
}
.textjustAListOfHubs {
  display: none;
}
.textcontext_welcomeView {
  display: none;
}
.welcome:global(.__wab_instance) {
  position: relative;
  align-self: auto;
}
.welcomejustAListOfHubs:global(.__wab_instance) {
  display: none;
}
.createHubButton:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  flex-shrink: 0;
}
.createHubButtonjustAListOfHubs:global(.__wab_instance) {
  flex-shrink: 0;
}
.createHubButtoncontext_mainModal:global(.__wab_instance) {
  flex-shrink: 0;
}
.createHubButtoncontext_welcomeView:global(.__wab_instance) {
  flex-shrink: 0;
}
.createHubButtonshowHubCreationPanel:global(.__wab_instance) {
  flex-shrink: 0;
  display: none;
}
.hubCreation:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  display: none;
}
.hubCreationshowHubCreationPanel:global(.__wab_instance) {
  margin-top: calc(12px + 10px) !important;
  display: flex;
}
.hubCreationblurContent_showHubCreationPanel:global(.__wab_instance) {
  margin-top: calc(12px + 10px) !important;
}
