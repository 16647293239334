.root {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: auto;
  min-width: 0;
}
.root > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  margin-top: calc(0px - 8px);
  height: calc(100% + 8px);
}
.root > :global(.__wab_flex-container) > *,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.root > :global(.__wab_flex-container) > picture > img,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-top: 8px;
}
.closeBtn:global(.__wab_instance) {
  position: relative;
  margin-bottom: 0px;
  flex-shrink: 0;
}
.freeBox {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 170px;
  height: auto;
  max-width: 100%;
  background: linear-gradient(210deg, #353132 0%, #2b2627ed 100%);
  border-radius: 10px;
  padding: 10px;
}
.freeBox > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-top: calc(0px - 8px);
  height: calc(100% + 8px);
}
.freeBox > :global(.__wab_flex-container) > *,
.freeBox > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox > :global(.__wab_flex-container) > picture > img,
.freeBox
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 8px;
}
.freeBoxcreateEmptyHub {
  background: linear-gradient(210deg, #353132 0%, #2b2627ed 100%);
  display: none;
}
.createEmptyHubBtn {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  color: var(--token-kgwkYLWcU);
  font-size: 10px;
  background: var(--token-pMUHtTtb1);
  font-weight: 500;
  cursor: pointer;
  box-shadow: 0px 2px 6px 2px var(--token-Dl_IO0P9f);
  min-width: 0;
  border-radius: 6px;
  padding: 6px 12px;
  border-width: 0px;
  border-style: none;
}
.createSourceBasedHubBtn {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  color: var(--token-kgwkYLWcU);
  font-size: 10px;
  background: var(--token-pMUHtTtb1);
  font-weight: 500;
  box-shadow: 0px 2px 6px 2px var(--token-Dl_IO0P9f);
  min-width: 0;
  border-radius: 6px;
  padding: 6px 12px;
  border-width: 0px;
  border-style: none;
}
.createInterestBasedHubBtn {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  color: var(--token-kgwkYLWcU);
  font-size: 10px;
  background: var(--token-6Pfaiv2lJ);
  font-weight: 500;
  box-shadow: 0px 2px 6px 2px var(--token-Dl_IO0P9f);
  min-width: 0;
  border-radius: 6px;
  padding: 6px 12px;
  border-width: 0px;
  border-style: none;
}
.createEmptyHubForm {
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 255px;
  height: auto;
  max-width: 100%;
  background: linear-gradient(210deg, #353132 0%, #2b2627 100%) 0% 0% / 100%
    100% repeat;
  display: none;
  border-radius: 10px;
  padding: 10px;
}
.createEmptyHubFormcreateEmptyHub {
  display: flex;
}
.input:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  flex-grow: 0;
  min-width: 0;
}
.svg__e2XMs {
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
  max-width: 80%;
}
.svg___2Vabu {
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
  max-width: 80%;
  margin: 2px 0px 0px;
}
.createEmptyHubFormSubmitBtn {
  display: flex;
  position: relative;
  flex-direction: row;
  width: auto;
  height: auto;
  max-width: 100%;
  cursor: pointer;
  pointer-events: auto;
  padding: 8px;
}
.createEmptyHubFormSubmitBtn > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.createEmptyHubFormSubmitBtncreateEmptyHub {
  background: var(--token-yt3rDB0KD);
  position: absolute;
  left: auto;
  top: 50%;
  transform: translateX(0px) translateY(-50%) translateZ(0px);
  height: 50%;
  right: 12px;
  border-radius: 6px;
  padding: 2px 8px;
  border-width: 1px;
  border-style: none;
}
.createEmptyHubFormSubmitBtncreateEmptyHub > :global(.__wab_flex-container) {
  flex-wrap: nowrap;
  align-items: center;
  align-content: stretch;
  justify-content: center;
  margin-left: calc(0px - 5px);
  width: calc(100% + 5px);
  margin-top: calc(0px - 0px);
  height: calc(100% + 0px);
}
.createEmptyHubFormSubmitBtncreateEmptyHub > :global(.__wab_flex-container) > *,
.createEmptyHubFormSubmitBtncreateEmptyHub
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > *,
.createEmptyHubFormSubmitBtncreateEmptyHub
  > :global(.__wab_flex-container)
  > picture
  > img,
.createEmptyHubFormSubmitBtncreateEmptyHub
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 5px;
  margin-top: 0px;
}
.text {
  width: 100%;
  height: auto;
  max-width: 100%;
  color: var(--token-kgwkYLWcU);
  font-size: 12px;
  font-weight: 500;
  min-width: 0;
}
.svg {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  color: var(--token-kgwkYLWcU);
  height: 1em;
}
