.root {
  display: flex;
  flex-direction: column;
  position: relative;
}
.rootcolor_softGreen {
  border-color: #ffffff00;
}
.root:focus-within {
  outline: none;
}
.root___focusVisibleWithin {
  outline: none;
}
.trigger {
  background: linear-gradient(
      193deg,
      #353132 14%,
      #2b2627 70%,
      #353132 117%,
      #2a2526 232%
    )
    0% 0% / 100% 100% no-repeat padding-box border-box scroll;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: inset 0px 0px 0px 0.3px rgba(69, 82, 119, 0.3);
  flex-direction: row;
  height: 100%;
  min-height: 0;
  border-radius: 0.3em;
  padding: 0.5em 0.1em 0.5em 0.7em;
  border: 0.01em none var(--token-9YopChMg6);
}
.triggerisOpen {
  background: var(--token-0Kei2aO9v);
}
.triggercolor_softBlue {
  background: #edf6ff;
  border-color: #ffffff00;
}
.triggercolor_softCyan {
  background: #e7f9fb;
  border-color: #ffffff00;
}
.triggercolor_softGreen {
  background: #e9f9ee;
  border-color: #ffffff00;
}
.triggercolor_softYellow {
  background: #fffbd1;
  border-color: #ffffff00;
}
.triggercolor_softOrange {
  background: #fff1e7;
  border-color: #ffffff00;
}
.triggercolor_softRed {
  background: #ffefef;
  border-color: #ffffff00;
}
.triggercolor_softPink {
  background: #feeef8;
  border-color: #ffffff00;
}
.triggercolor_softPurple {
  background: #f9f1fe;
  border-color: #ffffff00;
}
.triggercolor_softGray {
  background: #f3f3f2;
}
.triggercolor_clear {
  background: none;
}
.root:focus-within .trigger {
  box-shadow: 0px 0px 0px 3px var(--token-pMUHtTtb1);
  outline: none;
}
.root .trigger___focusVisibleWithin {
  box-shadow: 0px 0px 0px 3px #96c7f2;
  outline: none;
}
.root .trigger:hover {
  background: var(--token-V3GUThiKR);
}
.rootcolor_softBlue .triggercolor_softBlue:hover {
  background: #e1f0ff;
}
.rootcolor_softCyan .triggercolor_softCyan:hover {
  background: #d8f3f6;
}
.rootcolor_softGreen .triggercolor_softGreen:hover {
  background: #ddf3e4;
}
.rootcolor_softYellow .triggercolor_softYellow:hover {
  background: #fff8bb;
}
.rootcolor_softOrange .triggercolor_softOrange:hover {
  background: #ffe8d7;
}
.rootcolor_softRed .triggercolor_softRed:hover {
  background: #ffe6e2;
}
.rootcolor_softPink .triggercolor_softPink:hover {
  background: #fce5f3;
}
.rootcolor_softPurple .triggercolor_softPurple:hover {
  background: #f3e7fc;
}
.root .trigger:active {
  background: #e9e9e60f;
}
.rootcolor_softBlue .triggercolor_softBlue:active {
  background: #cee7fe;
}
.rootcolor_softCyan .triggercolor_softCyan:active {
  background: #c4eaef;
}
.rootcolor_softGreen .triggercolor_softGreen:active {
  background: #ccebd7;
}
.rootcolor_softYellow .triggercolor_softYellow:active {
  background: #fef2a4;
}
.rootcolor_softOrange .triggercolor_softOrange:active {
  background: #ffdcc3;
}
.rootcolor_softRed .triggercolor_softRed:active {
  background: #fdd8d3;
}
.rootcolor_softPink .triggercolor_softPink:active {
  background: #f9d8ec;
}
.rootcolor_softPurple .triggercolor_softPurple:active {
  background: #eddbf9;
}
.contentContainer {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  min-width: 0;
}
.root:focus-within .contentContainer {
  outline: none;
}
.slotTargetSelectedContent {
  white-space: pre-wrap;
  color: var(--token-kgwkYLWcU);
  font-size: 0.72em;
}
.slotTargetSelectedContentisDisabled {
  color: var(--token-0Kei2aO9v);
}
.slotTargetSelectedContentcolor_softBlue {
  color: #006adc;
}
.slotTargetSelectedContentcolor_softCyan {
  color: #0c7792;
}
.slotTargetSelectedContentcolor_softGreen {
  color: #18794e;
}
.slotTargetSelectedContentcolor_softYellow {
  color: #946800;
}
.slotTargetSelectedContentcolor_softOrange {
  color: #bd4b00;
}
.slotTargetSelectedContentcolor_softRed {
  color: #cd2b31;
}
.slotTargetSelectedContentcolor_softPink {
  color: #cd1d8d;
}
.slotTargetSelectedContentcolor_softPurple {
  color: #793aaf;
}
.root:focus-within .slotTargetSelectedContent > *,
.root:focus-within .slotTargetSelectedContent > :global(.__wab_slot) > *,
.root:focus-within
  .slotTargetSelectedContent
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > *,
.root:focus-within
  .slotTargetSelectedContent
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > *,
.root:focus-within .slotTargetSelectedContent > picture > img,
.root:focus-within
  .slotTargetSelectedContent
  > :global(.__wab_slot)
  > picture
  > img,
.root:focus-within
  .slotTargetSelectedContent
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > picture
  > img,
.root:focus-within
  .slotTargetSelectedContent
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > picture
  > img {
  outline: none;
}
.slotTargetPlaceholder {
  white-space: pre;
  color: #706f6c;
}
.slotTargetPlaceholder > :global(.__wab_text),
.slotTargetPlaceholder > :global(.__wab_expr_html_text),
.slotTargetPlaceholder > :global(.__wab_slot-string-wrapper),
.slotTargetPlaceholder > :global(.__wab_slot) > :global(.__wab_text),
.slotTargetPlaceholder > :global(.__wab_slot) > :global(.__wab_expr_html_text),
.slotTargetPlaceholder
  > :global(.__wab_slot)
  > :global(.__wab_slot-string-wrapper),
.slotTargetPlaceholder
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_text),
.slotTargetPlaceholder
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_expr_html_text),
.slotTargetPlaceholder
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot-string-wrapper),
.slotTargetPlaceholder
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_text),
.slotTargetPlaceholder
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_expr_html_text),
.slotTargetPlaceholder
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot-string-wrapper) {
  text-overflow: ellipsis;
}
.slotTargetPlaceholder > *,
.slotTargetPlaceholder > :global(.__wab_slot) > *,
.slotTargetPlaceholder > :global(.__wab_slot) > :global(.__wab_slot) > *,
.slotTargetPlaceholder
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > *,
.slotTargetPlaceholder > picture > img,
.slotTargetPlaceholder > :global(.__wab_slot) > picture > img,
.slotTargetPlaceholder
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > picture
  > img,
.slotTargetPlaceholder
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > picture
  > img {
  overflow: hidden;
}
.slotTargetPlaceholdershowPlaceholder {
  color: var(--token-kgwkYLWcU);
  font-size: 0.72em;
  white-space: pre-wrap;
}
.slotTargetPlaceholdershowPlaceholder_color_softBlue {
  color: #006adc;
}
.slotTargetPlaceholdershowPlaceholder_color_softCyan {
  color: #0c7792;
}
.slotTargetPlaceholdercolor_softGreen_showPlaceholder {
  color: #18794e;
}
.slotTargetPlaceholdershowPlaceholder_color_softYellow {
  color: #946800;
}
.slotTargetPlaceholdercolor_softOrange_showPlaceholder {
  color: #bd4b00;
}
.slotTargetPlaceholdershowPlaceholder_color_softRed {
  color: #cd2b31;
}
.slotTargetPlaceholdercolor_softPink_showPlaceholder {
  color: #cd1d8d;
}
.slotTargetPlaceholdershowPlaceholder_color_softPurple {
  color: #793aaf;
}
.dropdownIcon {
  position: relative;
  object-fit: cover;
  width: 1.25em;
  height: 1.25em;
  color: #90908c;
  flex-shrink: 0;
}
.dropdownIconcolor_softBlue {
  color: #0091ff;
}
.dropdownIconcolor_softCyan {
  color: #05a2c2;
}
.dropdownIconcolor_softGreen {
  color: #30a46c;
}
.dropdownIconcolor_softYellow {
  color: #f5d90a;
}
.dropdownIconcolor_softOrange {
  color: #f76808;
}
.dropdownIconcolor_softRed {
  color: #e5484d;
}
.dropdownIconcolor_softPink {
  color: #d6409f;
}
.dropdownIconcolor_softPurple {
  color: #8e4ec6;
}
.root:focus-within .dropdownIcon {
  outline: none;
}
.root .dropdownIcon___focusVisibleWithin {
  outline: none;
}
.overlay:global(.__wab_instance) {
  position: absolute;
  left: 0px;
  top: 100%;
  z-index: 1000;
}
.optionsContainer {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  min-width: 0;
}
.option__raLm5:global(.__wab_instance) {
  position: relative;
}
.option__qIEvB:global(.__wab_instance) {
  position: relative;
}
