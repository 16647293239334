.editTitleButton {
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  position: relative;
  min-width: 0;
  padding: 8px;
}
.editTitleButtonisTitleHovered {
  width: auto;
  display: flex;
  padding: 4px;
  border-width: 1px;
  border-style: none;
}
.editTitleButton:hover {
  width: auto;
  height: auto;
  justify-content: center;
  align-items: center;
  justify-self: flex-start;
  transform: scaleX(1.02) scaleY(1.02) scaleZ(1);
  display: flex;
  padding: 4px;
  border-width: 1px;
  border-style: none;
}
.svg {
  object-fit: cover;
  max-width: 100%;
  color: var(--token-pMUHtTtb1);
  backdrop-filter: none;
  box-shadow: none;
  filter: drop-shadow(0px 0px 2px #3259b7a1);
  transform: scaleX(1.3) scaleY(1.3) scaleZ(1);
  height: 1em;
  display: none;
  -webkit-backdrop-filter: none;
}
.svgisTitleHovered {
  transform: scaleX(1.3) scaleY(1.3) scaleZ(1);
  display: block;
}
.editTitleButton:hover .svg {
  transform: scaleX(1.3) scaleY(1.3) scaleZ(1);
  display: block;
}
