.root {
  display: block;
  width: 25px;
  height: 20px;
  max-width: 100%;
  transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg);
  position: relative;
  overflow: visible;
}
.rootvertical {
  transform: rotateX(0deg) rotateY(0deg) rotateZ(90deg);
}
.line1 {
  position: absolute;
  object-fit: cover;
  max-width: 100%;
  color: #fdfbf7;
  width: 8px;
  height: 2px;
  display: block;
  left: 1px;
  top: 15px;
  transform: none;
  border-radius: 2.461px;
}
.line1global_theme_light {
  color: var(--token-P44QyWYsh);
}
.blueCircles {
  position: absolute;
  object-fit: cover;
  max-width: 100%;
  filter: drop-shadow(0px 0px 5px #4062dd) blur(0px);
  width: auto;
  height: 1.25em;
  display: block !important;
  left: 0px;
  top: 0px;
  transform: none;
}
.blueCircles > picture > img {
  object-fit: cover;
}
.img {
  position: absolute;
  object-fit: cover;
  max-width: 100%;
  left: 0px;
  top: 0px;
  display: none !important;
}
.img > picture > img {
  object-fit: cover;
}
.imgsectionSettings {
  left: -6px;
  top: -12px;
  width: 160%;
  max-width: 160%;
  display: block !important;
}
.redCircles {
  position: absolute;
  object-fit: cover;
  max-width: 100%;
  width: 23px;
  height: 20px;
  left: 0px;
  top: 0px;
}
.redCircles > picture > img {
  object-fit: cover;
}
.redCirclescolor_red {
  display: block !important;
}
.line2 {
  position: absolute;
  object-fit: cover;
  max-width: 100%;
  color: #fdfbf7;
  width: 8px;
  height: 2px;
  display: block;
  left: 14px;
  top: 3px;
  transform: none;
  border-radius: 2.461px;
}
.line2global_theme_light {
  color: var(--token-P44QyWYsh);
}
