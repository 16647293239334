.root {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: auto;
  justify-content: flex-start;
  align-items: center;
  padding-top: 50px;
  min-width: 0;
}
@media (max-width: 480px) {
  .root {
    width: 100%;
    min-width: 0;
  }
}
.rootglobal_theme_dark {
  padding-bottom: 87px;
}
.collapsableTextContainer:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  margin-bottom: 60px;
}
@media (max-width: 480px) {
  .collapsableTextContainer:global(.__wab_instance) {
    display: none;
  }
}
.collapsableTextContainerisOnlyHubList:global(.__wab_instance) {
  display: none;
}
.textContainer {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 1000px;
  min-width: 0;
  padding: 8px 24px;
}
.textContainer > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - 16px);
  height: calc(100% + 16px);
}
.textContainer > :global(.__wab_flex-container) > *,
.textContainer > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.textContainer > :global(.__wab_flex-container) > picture > img,
.textContainer
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 16px;
}
.introText {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  font-family: "Literata", serif;
  color: var(--token-kgwkYLWcU);
  padding-bottom: 0px;
  min-width: 0;
}
.span__lLiez {
  font-family: "Rubik", sans-serif;
  font-weight: 600;
  font-size: 14px;
}
.span___0QWzN {
  font-size: 15px;
}
.span__xFmpY {
  font-weight: 700;
  text-decoration-line: underline;
  color: var(--token-pMUHtTtb1);
  font-size: 15px;
}
.feedbackText {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  font-family: "Literata", serif;
  color: var(--token-kgwkYLWcU);
  padding-top: 0px;
  min-width: 0;
}
.span__dqHqb {
  color: var(--token-pMUHtTtb1);
  font-weight: 500;
}
.span___2MTd {
  color: var(--token-pMUHtTtb1);
  font-weight: 500;
}
.randomTipText {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  font-family: "Literata", serif;
  color: var(--token-kgwkYLWcU);
  min-width: 0;
}
.span__mLvGa {
  font-size: 14px;
  font-family: "Rubik", sans-serif;
  font-weight: 600;
}
.freeBox__nLgz {
  display: flex;
  position: relative;
  flex-direction: row;
  width: auto;
  height: auto;
  max-width: 100%;
  padding: 8px;
}
.freeBox__nLgz > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  margin-left: calc(0px - 16px);
  width: calc(100% + 16px);
}
.freeBox__nLgz > :global(.__wab_flex-container) > *,
.freeBox__nLgz > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__nLgz > :global(.__wab_flex-container) > picture > img,
.freeBox__nLgz
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 16px;
}
.leftSide {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: auto;
  height: auto;
  max-width: 100%;
  padding: 8px;
}
.collapsableHubLists:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.yourHubs:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.savedPublicHubs:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.savedPublicHubsisOnlyHubList:global(.__wab_instance) {
  display: none;
}
.center {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: auto;
  height: auto;
  padding: 8px;
}
.centerisOnlyHubList {
  display: none;
}
.collapsableButtonCollection:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.yourFirstHub {
  display: flex;
  position: relative;
  flex-direction: column;
  width: auto;
  height: auto;
  max-width: 100%;
  padding: 8px;
}
.yourFirstHub > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-top: calc(0px - 4px);
  height: calc(100% + 4px);
}
.yourFirstHub > :global(.__wab_flex-container) > *,
.yourFirstHub > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.yourFirstHub > :global(.__wab_flex-container) > picture > img,
.yourFirstHub
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 4px;
}
.yourFirstHubisHideFirstHubButton {
  display: none;
}
.tooltipDefault {
  filter: drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.15));
  width: auto;
  height: auto;
  display: flex;
  flex-direction: column;
}
.tooltipDefault > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: calc(0px - 10px);
  height: calc(100% + 10px);
}
.tooltipDefault > :global(.__wab_flex-container) > *,
.tooltipDefault > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.tooltipDefault > :global(.__wab_flex-container) > picture > img,
.tooltipDefault
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 10px;
}
.frame40 {
  width: auto;
  height: auto;
  display: flex;
  position: relative;
  overflow: hidden;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.frame33 {
  background: rgba(88, 119, 231, 1);
  width: auto;
  height: auto;
  display: flex;
  position: relative;
  overflow: hidden;
  flex-direction: column;
  border-radius: 4px;
  padding: 4px 7px;
}
.frame33 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-top: calc(0px - 10px);
  height: calc(100% + 10px);
}
.frame33 > :global(.__wab_flex-container) > *,
.frame33 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame33 > :global(.__wab_flex-container) > picture > img,
.frame33
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 10px;
}
.chooseAnyOfTheseButtonsToCreateANewSectionWennIchWeiterSchreibDann {
  display: block;
  font-size: 10px;
  text-align: center;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  letter-spacing: 0px;
  line-height: 16px;
  color: rgba(250, 247, 238, 1);
  position: relative;
  width: 156px;
  height: 32px;
  flex-shrink: 0;
}
.freeBox__sVtzk {
  display: block;
  position: relative;
  width: 30px;
  height: 5px;
  flex-shrink: 0;
}
.svg__jAmvX {
  position: absolute;
  object-fit: cover;
  max-width: 100%;
  color: #5978e8;
  transform: rotateX(0deg) rotateY(0deg) rotateZ(180deg) scaleX(3) scaleY(3)
    scaleZ(1);
  width: 89px;
  height: 6px;
  display: flex;
  transform-origin: top left;
  overflow: hidden;
  flex-direction: column;
  padding-left: 10px;
  padding-right: 10px;
  left: 68px;
  top: 11px;
}
.svg__jAmvX > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-top: calc(0px - 10px);
  height: calc(100% + 10px);
}
.svg__jAmvX > :global(.__wab_flex-container) > *,
.svg__jAmvX > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.svg__jAmvX > :global(.__wab_flex-container) > picture > img,
.svg__jAmvX
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 10px;
}
.yourFirstHubButton {
  display: flex;
  position: relative;
  width: auto;
  height: 180px;
  background: #ffffff05;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  flex-shrink: 0;
  border-radius: 8px;
  padding: 8px;
  border: 1px solid #2c2b2b;
}
.freeBox__g8KNo {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  width: auto;
  height: 100%;
  max-width: 100%;
  margin-right: -10px;
  min-height: 0;
  padding: 8px;
}
.freeBox__kHoJu {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 0px;
}
.freeBox__kHoJu > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - 0px);
  height: calc(100% + 0px);
}
.freeBox__kHoJu > :global(.__wab_flex-container) > *,
.freeBox__kHoJu > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__kHoJu > :global(.__wab_flex-container) > picture > img,
.freeBox__kHoJu
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 0px;
}
.text___95Q6S {
  width: 100%;
  height: auto;
  max-width: 100%;
  font-family: "Rubik", sans-serif;
  font-weight: 600;
  color: var(--token-kgwkYLWcU);
  min-width: 0;
}
.svg__trNns {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  color: #4062dd;
  filter: drop-shadow(0px 0px 2px rgba(88, 119, 231, 0.6));
  transform: scale(0.9990405397179837, 0.999031531074548)
    rotate(-0.5161642297649448deg) skew(-0.057348458963264175deg, 0deg);
  width: 165px;
  height: 4px;
  display: block;
  transform-origin: top left;
  flex-shrink: 0;
}
.text__wbRto {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  color: var(--token-kgwkYLWcU);
  font-size: 10px;
  padding-top: 0px;
  min-width: 0;
}
.img__na5Y {
  position: relative;
  object-fit: cover;
  width: 172px;
  flex-shrink: 0;
}
.img__na5Y > picture > img {
  object-fit: cover;
}
.freeBox__x4F64 {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
.freeBox__pYjg {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  align-self: flex-start;
  min-width: 0;
  padding: 8px;
}
.freeBox__pYjg > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox__pYjg > :global(.__wab_flex-container) {
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 26px);
    height: calc(100% + 26px);
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox__pYjg > :global(.__wab_flex-container) > *,
  .freeBox__pYjg > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox__pYjg > :global(.__wab_flex-container) > picture > img,
  .freeBox__pYjg
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
    margin-top: 26px;
  }
}
.collapsableHubLists2:global(.__wab_instance) {
  max-width: 100%;
}
.hubSelection___41Sj:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.hubSelection__mml4T:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.hubSelectionisOnlyHubList__mml4TBJcK:global(.__wab_instance) {
  display: none;
}
.checklist__grl80:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  display: none;
}
@media (min-width: 0px) and (max-width: 768px) {
  .checklist__grl80:global(.__wab_instance) {
    display: flex;
  }
}
@media (max-width: 480px) {
  .checklist__grl80:global(.__wab_instance) {
    display: none;
  }
}
.img__pK21D {
  object-fit: cover;
  max-width: 50%;
  width: auto;
  display: none !important;
}
.img__pK21D > picture > img {
  object-fit: cover;
}
@media (min-width: 0px) and (max-width: 768px) {
  .img__pK21D {
    display: block !important;
  }
}
@media (max-width: 480px) {
  .img__pK21D {
    display: none !important;
  }
}
.img__vqsvv {
  position: relative;
  object-fit: cover;
  max-width: 90%;
}
.img__vqsvv > picture > img {
  object-fit: cover;
}
.sectionStack1Container {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
.rightSide {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: auto;
  height: auto;
  max-width: 100%;
  padding: 8px;
}
.rightSideisOnlyHubList {
  display: none;
}
.checklist__tFux2:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  transform: scaleX(0.7) scaleY(0.7) scaleZ(1);
}
