.originalLinkButton {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  position: relative;
  min-width: 0;
  padding: 0px;
}
.originalLinkButton > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-width: 0;
}
.originalLinkButtonexpanded_spotify > :global(.__wab_flex-container) {
  align-items: center;
  justify-content: center;
}
.originalLinkButton:hover > :global(.__wab_flex-container) {
  align-items: center;
  justify-content: center;
  margin-left: calc(0px - 4px);
  width: calc(100% + 4px);
  margin-top: calc(0px - 0px);
  height: calc(100% + 0px);
}
.originalLinkButton:hover > :global(.__wab_flex-container) > *,
.originalLinkButton:hover
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > *,
.originalLinkButton:hover > :global(.__wab_flex-container) > picture > img,
.originalLinkButton:hover
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 4px;
  margin-top: 0px;
}
.freeBox {
  display: flex;
  position: relative;
  width: 100%;
  height: auto;
  min-width: 0;
}
.originalLinkButton:hover .freeBox {
  width: auto;
}
.slotTargetChildren {
  font-size: 0.4em;
  color: var(--token-pMUHtTtb1);
}
.slotTargetChildren > :global(.__wab_text),
.slotTargetChildren > :global(.__wab_expr_html_text),
.slotTargetChildren > :global(.__wab_slot-string-wrapper),
.slotTargetChildren > :global(.__wab_slot) > :global(.__wab_text),
.slotTargetChildren > :global(.__wab_slot) > :global(.__wab_expr_html_text),
.slotTargetChildren
  > :global(.__wab_slot)
  > :global(.__wab_slot-string-wrapper),
.slotTargetChildren
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_text),
.slotTargetChildren
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_expr_html_text),
.slotTargetChildren
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot-string-wrapper),
.slotTargetChildren
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_text),
.slotTargetChildren
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_expr_html_text),
.slotTargetChildren
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot-string-wrapper) {
  text-decoration-line: underline;
}
.slotTargetChildrenexpanded_youtube {
  color: var(--token-6Pfaiv2lJ);
}
.slotTargetChildrenexpanded_spotify {
  color: var(--token-eXgRgh_S6);
}
.slotTargetChildrensize_big {
  font-size: 0.6em;
}
.originalLinkButton:hover .slotTargetChildren {
  font-size: 0.44em;
  font-weight: 700;
}
.svg {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  color: #535353;
  height: 1em;
  display: none;
}
.svgexpanded_websites {
  color: var(--token-pMUHtTtb1);
}
.svgexpanded_youtube {
  color: var(--token-6Pfaiv2lJ);
}
.svgexpanded_spotify {
  color: var(--token-eXgRgh_S6);
}
.originalLinkButton:hover .svg {
  display: block;
}
