.root {
  display: inline-flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: auto;
  height: auto;
  max-width: 100%;
  overflow: hidden;
  position: relative;
  border-radius: 6px;
}
.repeatImageContainer {
  width: 19em;
  display: flex;
  flex-grow: 1;
  height: auto;
  background: url("https://site-assets.plasmic.app/34aa6e5b81e5c3372900c3cf268a8d4c.jpg")
    center center / cover no-repeat padding-box border-box scroll;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: stretch;
  overflow: hidden;
  position: relative;
  left: auto;
  top: auto;
  flex-shrink: 0;
  border-radius: 0.38em;
  margin: -2px;
}
.repeatImageContainersize_list {
  width: 28.31em;
  flex-shrink: 0;
}
.img {
  position: relative;
  object-fit: cover;
  max-width: 50%;
  left: auto;
  top: auto;
  width: 100%;
  height: 100%;
  max-height: 50%;
  min-width: 0;
  min-height: 0;
  border: 1px solid var(--token-IG9ne-3Mr);
}
.img > picture > img {
  object-fit: cover;
}
.imgglobal_theme_dark {
  border-color: #343031;
}
.imgimageCount__2 {
  max-height: none;
}
.imgimageCount__1 {
  max-width: none;
  max-height: none;
}
.freeBox__ieLys {
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 19em;
  height: 10em;
  flex-grow: 1;
  flex-wrap: wrap;
  flex-shrink: 0;
  display: none;
  border-radius: 0.38em;
  padding: 0px;
  margin: -2px;
}
.freeBoximageCount__3__ieLysSaXgy {
  display: flex;
}
.freeBox__krVh {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 60%;
  height: 100%;
  max-width: 100%;
  flex-wrap: wrap;
  min-height: 0;
  padding: 0px;
}
.image1 {
  object-fit: cover;
  max-width: 100%;
  height: 50%;
  border: 1px solid var(--token-IG9ne-3Mr);
}
.image1 > picture > img {
  object-fit: cover;
}
.image2 {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 50%;
  border: 1px solid var(--token-IG9ne-3Mr);
}
.image2 > picture > img {
  object-fit: cover;
}
.freeBox__g0DE4 {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 30%;
  height: auto;
  max-width: 100%;
  flex-wrap: wrap;
  padding: 0px;
}
.image3 {
  object-fit: cover;
  height: 100%;
  min-height: 0;
  border: 1px solid var(--token-IG9ne-3Mr);
}
.image3 > picture > img {
  object-fit: cover;
}
