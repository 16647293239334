.core {
  background: #231f20;
  min-height: 100vh;

  &::before {
    content: '';
    position: fixed;
    inset: 0;
    background: url(../../../assets/DarkNoise.png) repeat;
    background-size: 50px auto;
    opacity: 0.7;
  }
}
