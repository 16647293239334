/* colours */
$c-bg: #f3f3fa;
$c-text: #475569;
$c-text-light: #64748b;
$c-border: #f1f5f9;
$c-border-dark: #cbd5e1;
$c-primary: #73b0f4;
$c-dark: #1f2937;

// social media
$c-facebook: #3b5998;
$c-twitter: #55acee;
$c-instagram: #405de6;
$c-youtube: #cd201f;
$c-pinterest: #bd081c;
$c-linkedin: #0077b5;
$c-microsoft: #d73a17;
$c-google: #4285f4;
$c-github: #333;
$c-whatsapp: #25d366;
$c-telegram: #0088cc;
$c-discord: #7289da;
$c-reddit: #ff4500;
$c-twitch: #6441a5;
$c-medium: #00ab6c;
$c-snapchat: #fffc00;
$c-spotify: #1db954;

// hubhub
$c-gravity-red: #d95565;

$c-temp: #5978e8;
$c-steel-blue: #546491;
$c-mountain-blue: #2141b3;

// gravity
$c-gravity-green: #10b981;
$c-gravity-green-alt: #10b981;
$c-gravity-lightblue: #73b0f4;
$c-gravity-darkblue: #3a4488;
$c-gravity-orange: #f0aa61;
$c-gravity-tint: #fbfbff;
$c-gravity-purple: #6363ac;
$c-gravity-darkpurple: #556cb6;