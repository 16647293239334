.root {
  display: inline-flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: auto;
  height: auto;
  max-width: 100%;
}
.svg {
  object-fit: none;
  width: auto;
  height: 1em;
  color: #d6455b;
  display: block;
  box-shadow: none;
  filter: drop-shadow(0px 0px 6px #d6455b);
  backdrop-filter: none;
  -webkit-backdrop-filter: none;
}
.svgsourceCategoryType_twitter {
  color: var(--token-QkOxHiuRM);
  filter: drop-shadow(0px 0px 6px #4586d6);
}
.svgsourceCategoryType_websites {
  color: var(--token-O7e_v4mKD);
  filter: drop-shadow(0px 0px 6px #8e45d6);
}
.svgsourceCategoryType_hubHub {
  color: var(--token-pMUHtTtb1);
  filter: drop-shadow(0px 0px 6px #2070d4);
}
.svgsourceCategoryType_spotify {
  color: var(--token-eXgRgh_S6);
  filter: drop-shadow(0px 0px 6px #45d65abf);
  transform: scaleX(0.9) scaleY(0.9) scaleZ(1);
}
.svghover {
  height: 1em;
  display: none;
}
.root:hover .svg {
  height: 1em;
  display: none;
}
.editIcon:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  flex-shrink: 0;
  display: none;
}
.editIconhover:global(.__wab_instance) {
  flex-shrink: 0;
  display: block;
}
.root:hover .editIcon:global(.__wab_instance) {
  flex-shrink: 0;
  display: block;
}
