.root {
  display: inline-flex;
  flex-direction: column;
  position: relative;
  width: auto;
  height: auto;
  justify-content: center;
  align-items: center;
}
.select:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  height: 1.6em;
  flex-shrink: 0;
}
.text__qbjBj {
  font-size: 0.72em;
}
.text__yk8XB {
  font-size: 0.72em;
}
.option__d6I8R:global(.__wab_instance) {
  position: relative;
}
.text__nIk34 {
  font-size: 0.72em;
}
.option__idOrc:global(.__wab_instance) {
  position: relative;
}
.text__o9FjV {
  font-size: 0.72em;
}
