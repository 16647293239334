button,
[type='button'],
[type='reset'],
[type='submit'] {
  border: none;
}

.interest-selection-page-tooltip {
  position: absolute;
  top: -60px;
  left: 50%;
  transform: translateX(-50%);
  background: #5978e8;
  padding: 10px 16px;
  font-size: 14px;
  font-weight: 600;
  border-radius: 4px;
}

.interest-selection-page-tooltip::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  border: 8px solid transparent;
  border-top-color: #5978e8;
}

#custom-scrollbar::-webkit-scrollbar {
  width: 8px;
  border-radius: 8px;
  background: #555;
}

#custom-scrollbar::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

#custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #777;
  border-radius: 8px;

  /* outline: 1px solid slategrey; */
}

.highlight-spoken {
  color: black !important;
  background-color: #7791efff !important;
  border-radius: 5px;
}

.highlight-sentence {
  background-color: #7791ef99 !important;
  border-radius: 5px 5px 0 0;
  position: relative;
}
/* .highlight-sentence *::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  background: #5978e8;
  bottom: -1px;
  height: 2px;
} */

@keyframes example {
  from {
    background-color: #ff6f00;
  }
  to {
    background-color: #ffe082;
  }
}

.highlight-spoken-still {
  color: black !important;
  background-color: #ff6f00;
  border-radius: 5px;
  animation-name: example;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-direction: alternate-reverse;
  animation-timing-function: ease-in-out;
}

.highlight-sentence-still {
  color: #000000 !important;
  background-color: #ffe082 !important;
  border-radius: 5px;
}

table,
th,
td {
  border: 1px solid black;
  border-collapse: collapse;
}

.wrap-box {
  width: 100px;
  display: table;
  white-space: pre-wrap; /* css-3 */
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
}

.show_mark spw {
  padding-top: 2px;
  padding-bottom: 2px;
  background-color: #fdb9b9e4;
}

.show_mark sps {
  /* margin-top: 50px;
  margin-bottom: 50px; */
  padding-top: 2px;
  padding-bottom: 2px;
  background-color: #9bcbff;
}

.show_mark sps:hover {
  background-color: #9bcbff;
}

.show_mark sps:active {
  background-color: #9bcbff;
}

sps:hover {
  background-color: #82c5ff62;
}

sps:active {
  background-color: #007bff;
}
