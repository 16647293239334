.root {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: auto;
  justify-content: center;
  align-items: center;
  min-width: 0;
  padding: 1em;
}
.svg {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 100%;
  height: 1em;
  flex-shrink: 0;
  margin: 0em;
}
.svgcolor_darkGrey {
  color: var(--token-WkUIkx5-J);
}
.svgcolor_eggshell {
  color: var(--token-kgwkYLWcU);
}
.svgcolor_mediumGrey {
  color: var(--token-7YSTg8Ece);
}
