@import url("https://fonts.googleapis.com/css2?family=Inter%3Aital%2Cwght%400%2C300%3B0%2C400%3B0%2C500%3B0%2C600%3B0%2C700&family=Inconsolata%3Aital%2Cwght%400%2C300%3B0%2C400%3B0%2C500%3B0%2C600%3B0%2C700&family=Open+Sans%3Aital%2Cwght%400%2C300%3B0%2C400%3B0%2C500%3B0%2C600%3B0%2C700%3B1%2C300%3B1%2C400%3B1%2C500%3B1%2C600%3B1%2C700&family=Source+Sans+Pro%3Aital%2Cwght%400%2C300%3B0%2C400%3B0%2C600%3B0%2C700%3B1%2C300%3B1%2C400%3B1%2C600%3B1%2C700&family=Rubik%3Aital%2Cwght%400%2C300%3B0%2C400%3B0%2C500%3B0%2C600%3B0%2C700%3B1%2C300%3B1%2C400%3B1%2C500%3B1%2C600%3B1%2C700&display=swap");

.plasmic_tokens {
  --token-v6F1z1DYnr3y: #4163dd;
  --plasmic-token-logo-blue: var(--token-v6F1z1DYnr3y);
  --token-ZugA3JU5ecCi: #bebebe;
  --plasmic-token-medium-grey: var(--token-ZugA3JU5ecCi);
  --token-vScsy3mKu6O4: #353132;
  --plasmic-token-dark: var(--token-vScsy3mKu6O4);
  --token-leZ473BVNVNf: #fdfdfc;
  --plasmic-token-almost-white: var(--token-leZ473BVNVNf);
  --token-KjKMBdm000QG: #fcfaf6;
  --plasmic-token-eggshell: var(--token-KjKMBdm000QG);
  --token-i42SNYPlbnNw: #2141b3;
  --plasmic-token-mountain-blue: var(--token-i42SNYPlbnNw);
  --token-B-7gFLvZxgqh: #d6455b;
  --plasmic-token-red: var(--token-B-7gFLvZxgqh);
  --token-DMJNCkKU1x_j: #231f20;
  --plasmic-token-dark-background: var(--token-DMJNCkKU1x_j);
  --token-zNkG2KujPORc: #00000033;
  --plasmic-token-button-shadow: var(--token-zNkG2KujPORc);
}

.plasmic_tokens:where(.global_theme_dark) {
  --token-v6F1z1DYnr3y: #5978e8;
  --plasmic-token-logo-blue: var(--token-v6F1z1DYnr3y);
  --token-ZugA3JU5ecCi: #c4c4c4;
  --plasmic-token-medium-grey: var(--token-ZugA3JU5ecCi);
  --token-vScsy3mKu6O4: #353132;
  --plasmic-token-dark: var(--token-vScsy3mKu6O4);
}

.plasmic_tokens {
  --plsmc-standard-width: 800px;
  --plsmc-wide-width: 1280px;
  --plsmc-viewport-gap: 16px;
  --plsmc-wide-chunk: calc(
    ((var(--plsmc-wide-width) - var(--plsmc-standard-width)) / 2) -
      var(--plsmc-viewport-gap)
  );
}

.plasmic_default_styles,
.plasmic_default_styles:where(.global_theme_dark) {
  --mixin-IPQz7NEbBDpU_font-family: "Inter", sans-serif;
  --mixin-IPQz7NEbBDpU_font-size: 14px;
  --mixin-IPQz7NEbBDpU_font-weight: 400;
  --mixin-IPQz7NEbBDpU_font-style: normal;
  --mixin-IPQz7NEbBDpU_color: #535353;
  --mixin-IPQz7NEbBDpU_text-align: left;
  --mixin-IPQz7NEbBDpU_text-transform: none;
  --mixin-IPQz7NEbBDpU_line-height: 1.5;
  --mixin-IPQz7NEbBDpU_letter-spacing: normal;
  --mixin-IPQz7NEbBDpU_white-space: pre-wrap;
  --mixin-IPQz7NEbBDpU_user-select: text;
  --mixin-IPQz7NEbBDpU_text-decoration-line: none;
  --mixin-IPQz7NEbBDpU_text-overflow: clip;
  --mixin-ls_PP52o74F__color: #000000;
  --mixin-ls_PP52o74F__font-weight: 600;
  --mixin-ls_PP52o74F__font-size: 40px;
  --mixin-ls_PP52o74F__line-height: 1;
  --mixin-ls_PP52o74F__letter-spacing: -1px;
  --mixin-ls_PP52o74F__white-space: pre-wrap;
  --mixin-vYWA5pvhYCsV_color: #000000;
  --mixin-vYWA5pvhYCsV_font-size: 30px;
  --mixin-vYWA5pvhYCsV_font-weight: 600;
  --mixin-vYWA5pvhYCsV_line-height: 1.1;
  --mixin-vYWA5pvhYCsV_white-space: pre-wrap;
  --mixin-obEREfZ-hqhV_color: #0070f3;
  --mixin-obEREfZ-hqhV_white-space: pre-wrap;
  --mixin-AV-7oTbRvWHc_color: #000000;
  --mixin-AV-7oTbRvWHc_font-size: 24px;
  --mixin-AV-7oTbRvWHc_font-weight: 600;
  --mixin-AV-7oTbRvWHc_line-height: 1.2;
  --mixin-AV-7oTbRvWHc_white-space: pre-wrap;
  --mixin-YbJI1zthX8YU_color: #000000;
  --mixin-YbJI1zthX8YU_font-size: 20px;
  --mixin-YbJI1zthX8YU_font-weight: 600;
  --mixin-YbJI1zthX8YU_line-height: 1.3;
  --mixin-YbJI1zthX8YU_white-space: pre-wrap;
  --mixin-q_6YsWqxtsyr_background: linear-gradient(#f8f8f8, #f8f8f8);
  --mixin-q_6YsWqxtsyr_border-bottom-color: #dddddd;
  --mixin-q_6YsWqxtsyr_border-bottom-style: solid;
  --mixin-q_6YsWqxtsyr_border-bottom-width: 1px;
  --mixin-q_6YsWqxtsyr_border-left-color: #dddddd;
  --mixin-q_6YsWqxtsyr_border-left-style: solid;
  --mixin-q_6YsWqxtsyr_border-left-width: 1px;
  --mixin-q_6YsWqxtsyr_border-right-color: #dddddd;
  --mixin-q_6YsWqxtsyr_border-right-style: solid;
  --mixin-q_6YsWqxtsyr_border-right-width: 1px;
  --mixin-q_6YsWqxtsyr_border-top-color: #dddddd;
  --mixin-q_6YsWqxtsyr_border-top-style: solid;
  --mixin-q_6YsWqxtsyr_border-top-width: 1px;
  --mixin-q_6YsWqxtsyr_border-bottom-left-radius: 3px;
  --mixin-q_6YsWqxtsyr_border-bottom-right-radius: 3px;
  --mixin-q_6YsWqxtsyr_border-top-left-radius: 3px;
  --mixin-q_6YsWqxtsyr_border-top-right-radius: 3px;
  --mixin-q_6YsWqxtsyr_font-family: "Inconsolata";
  --mixin-q_6YsWqxtsyr_padding-bottom: 1px;
  --mixin-q_6YsWqxtsyr_padding-left: 4px;
  --mixin-q_6YsWqxtsyr_padding-right: 4px;
  --mixin-q_6YsWqxtsyr_padding-top: 1px;
  --mixin-q_6YsWqxtsyr_white-space: pre-wrap;
  --mixin-F7Oh7MbTsnw1_border-left-color: #dddddd;
  --mixin-F7Oh7MbTsnw1_border-left-style: solid;
  --mixin-F7Oh7MbTsnw1_border-left-width: 3px;
  --mixin-F7Oh7MbTsnw1_color: #888888;
  --mixin-F7Oh7MbTsnw1_padding-left: 10px;
  --mixin-F7Oh7MbTsnw1_white-space: pre-wrap;
  --mixin--V3Ybxm1QANw_background: linear-gradient(#f8f8f8, #f8f8f8);
  --mixin--V3Ybxm1QANw_border-bottom-color: #dddddd;
  --mixin--V3Ybxm1QANw_border-bottom-style: solid;
  --mixin--V3Ybxm1QANw_border-bottom-width: 1px;
  --mixin--V3Ybxm1QANw_border-left-color: #dddddd;
  --mixin--V3Ybxm1QANw_border-left-style: solid;
  --mixin--V3Ybxm1QANw_border-left-width: 1px;
  --mixin--V3Ybxm1QANw_border-right-color: #dddddd;
  --mixin--V3Ybxm1QANw_border-right-style: solid;
  --mixin--V3Ybxm1QANw_border-right-width: 1px;
  --mixin--V3Ybxm1QANw_border-top-color: #dddddd;
  --mixin--V3Ybxm1QANw_border-top-style: solid;
  --mixin--V3Ybxm1QANw_border-top-width: 1px;
  --mixin--V3Ybxm1QANw_border-bottom-left-radius: 3px;
  --mixin--V3Ybxm1QANw_border-bottom-right-radius: 3px;
  --mixin--V3Ybxm1QANw_border-top-left-radius: 3px;
  --mixin--V3Ybxm1QANw_border-top-right-radius: 3px;
  --mixin--V3Ybxm1QANw_font-family: "Inconsolata";
  --mixin--V3Ybxm1QANw_padding-bottom: 3px;
  --mixin--V3Ybxm1QANw_padding-left: 6px;
  --mixin--V3Ybxm1QANw_padding-right: 6px;
  --mixin--V3Ybxm1QANw_padding-top: 3px;
  --mixin--V3Ybxm1QANw_white-space: pre-wrap;
  --mixin-MM2MflRJ8osJ_display: flex;
  --mixin-MM2MflRJ8osJ_flex-direction: column;
  --mixin-MM2MflRJ8osJ_align-items: stretch;
  --mixin-MM2MflRJ8osJ_justify-content: flex-start;
  --mixin-MM2MflRJ8osJ_list-style-position: outside;
  --mixin-MM2MflRJ8osJ_padding-left: 40px;
  --mixin-MM2MflRJ8osJ_position: relative;
  --mixin-MM2MflRJ8osJ_list-style-type: disc;
  --mixin-MM2MflRJ8osJ_white-space: pre-wrap;
  --mixin-64xIbNaxUz5C_display: flex;
  --mixin-64xIbNaxUz5C_flex-direction: column;
  --mixin-64xIbNaxUz5C_align-items: stretch;
  --mixin-64xIbNaxUz5C_justify-content: flex-start;
  --mixin-64xIbNaxUz5C_list-style-position: outside;
  --mixin-64xIbNaxUz5C_padding-left: 40px;
  --mixin-64xIbNaxUz5C_position: relative;
  --mixin-64xIbNaxUz5C_list-style-type: decimal;
  --mixin-64xIbNaxUz5C_white-space: pre-wrap;
  --mixin-4CzimzeZHPaI_color: #000000;
  --mixin-4CzimzeZHPaI_font-size: 16px;
  --mixin-4CzimzeZHPaI_font-weight: 600;
  --mixin-4CzimzeZHPaI_line-height: 1.5;
  --mixin-4CzimzeZHPaI_white-space: pre-wrap;
  --mixin-ASao-qfdExlD_color: #000000;
  --mixin-ASao-qfdExlD_font-size: 14px;
  --mixin-ASao-qfdExlD_font-weight: 600;
  --mixin-ASao-qfdExlD_line-height: 1.5;
  --mixin-ASao-qfdExlD_white-space: pre-wrap;
  --mixin-p8KmnJGhFT9z_color: #3291ff;
  --mixin-p8KmnJGhFT9z_white-space: pre-wrap;
  --mixin-6FwJLtabzKoN_white-space: pre-wrap;
  --mixin-dPXot8kZaqTI_white-space: pre-wrap;
}

.plasmic_mixins,
.plasmic_mixins:where(.global_theme_dark) {
  --mixin-YiSJnmPjRqJf_box-shadow: 0px 2px 6px 2px var(--token-zNkG2KujPORc);
  --plasmic-mixin-button-shadow_box-shadow: var(
    --mixin-YiSJnmPjRqJf_box-shadow
  );
  --mixin-YiSJnmPjRqJf_white-space: pre-wrap;
  --plasmic-mixin-button-shadow_white-space: var(
    --mixin-YiSJnmPjRqJf_white-space
  );
  --mixin-yHkZJM5gWDwV_white-space: pre-wrap;
  --plasmic-mixin-lp-dark-bg-3_white-space: var(
    --mixin-yHkZJM5gWDwV_white-space
  );
  --mixin-kbXvIcY111h__white-space: pre-wrap;
  --plasmic-mixin-scal-1-08_white-space: var(--mixin-kbXvIcY111h__white-space);
}

:where(.all) {
  display: block;
  white-space: inherit;
  grid-row: auto;
  grid-column: auto;
  position: relative;
  background: none;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  box-shadow: none;
  box-sizing: border-box;
  text-decoration-line: none;
  margin: 0;
  border-width: 0px;
}
:where(.__wab_expr_html_text *) {
  white-space: inherit;
  grid-row: auto;
  grid-column: auto;
  background: none;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  box-shadow: none;
  box-sizing: border-box;
  text-decoration-line: none;
  margin: 0;
  border-width: 0px;
}

:where(.img) {
  display: inline-block;
}
:where(.__wab_expr_html_text img) {
  white-space: inherit;
}

:where(.li) {
  display: list-item;
}
:where(.__wab_expr_html_text li) {
  white-space: inherit;
}

:where(.span) {
  display: inline;
  position: static;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
}
:where(.__wab_expr_html_text span) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
}

:where(.input) {
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  background-image: linear-gradient(#ffffff, #ffffff);
  padding: 2px;
  border: 1px solid lightgray;
}
:where(.__wab_expr_html_text input) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  background-image: linear-gradient(#ffffff, #ffffff);
  padding: 2px;
  border: 1px solid lightgray;
}

:where(.textarea) {
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  padding: 2px;
  border: 1px solid lightgray;
}
:where(.__wab_expr_html_text textarea) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  padding: 2px;
  border: 1px solid lightgray;
}

:where(.button) {
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  background-image: none;
  align-items: flex-start;
  text-align: center;
  padding: 2px 6px;
  border: 1px solid lightgray;
}
:where(.__wab_expr_html_text button) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  background-image: none;
  align-items: flex-start;
  text-align: center;
  padding: 2px 6px;
  border: 1px solid lightgray;
}

:where(.code) {
  font-family: inherit;
  line-height: inherit;
}
:where(.__wab_expr_html_text code) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
}

:where(.pre) {
  font-family: inherit;
  line-height: inherit;
}
:where(.__wab_expr_html_text pre) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
}

:where(.p) {
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
}
:where(.__wab_expr_html_text p) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
}

:where(.h1) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.__wab_expr_html_text h1) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.h2) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.__wab_expr_html_text h2) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.h3) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.__wab_expr_html_text h3) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.h4) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.__wab_expr_html_text h4) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.h5) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.__wab_expr_html_text h5) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.h6) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.__wab_expr_html_text h6) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.address) {
  font-style: inherit;
}
:where(.__wab_expr_html_text address) {
  white-space: inherit;
  font-style: inherit;
}

:where(.a) {
  color: inherit;
}
:where(.__wab_expr_html_text a) {
  white-space: inherit;
  color: inherit;
}

:where(.ol) {
  list-style-type: none;
  padding: 0;
}
:where(.__wab_expr_html_text ol) {
  white-space: inherit;
  list-style-type: none;
  padding: 0;
}

:where(.ul) {
  list-style-type: none;
  padding: 0;
}
:where(.__wab_expr_html_text ul) {
  white-space: inherit;
  list-style-type: none;
  padding: 0;
}

:where(.select) {
  padding: 2px 6px;
}
:where(.__wab_expr_html_text select) {
  white-space: inherit;
  padding: 2px 6px;
}

.plasmic_default__component_wrapper {
  display: grid;
}
.plasmic_default__inline {
  display: inline;
}
.plasmic_page_wrapper {
  display: flex;
  width: 100%;
  min-height: 100vh;
  align-items: stretch;
  align-self: start;
}
.plasmic_page_wrapper > * {
  height: auto !important;
}
.__wab_expr_html_text {
  white-space: normal;
}
:where(.root_reset) {
  font-family: var(--mixin-IPQz7NEbBDpU_font-family);
  font-size: var(--mixin-IPQz7NEbBDpU_font-size);
  font-weight: var(--mixin-IPQz7NEbBDpU_font-weight);
  font-style: var(--mixin-IPQz7NEbBDpU_font-style);
  color: var(--mixin-IPQz7NEbBDpU_color);
  text-align: var(--mixin-IPQz7NEbBDpU_text-align);
  text-transform: var(--mixin-IPQz7NEbBDpU_text-transform);
  line-height: var(--mixin-IPQz7NEbBDpU_line-height);
  letter-spacing: var(--mixin-IPQz7NEbBDpU_letter-spacing);
  white-space: var(--mixin-IPQz7NEbBDpU_white-space);
}

:where(.root_reset) h1:where(.h1),
h1:where(.root_reset.h1),
:where(.root_reset .__wab_expr_html_text) h1,
:where(.root_reset_tags) h1,
h1:where(.root_reset_tags) {
  color: var(--mixin-ls_PP52o74F__color);
  font-weight: var(--mixin-ls_PP52o74F__font-weight);
  font-size: var(--mixin-ls_PP52o74F__font-size);
  line-height: var(--mixin-ls_PP52o74F__line-height);
  letter-spacing: var(--mixin-ls_PP52o74F__letter-spacing);
}

:where(.root_reset) h2:where(.h2),
h2:where(.root_reset.h2),
:where(.root_reset .__wab_expr_html_text) h2,
:where(.root_reset_tags) h2,
h2:where(.root_reset_tags) {
  color: var(--mixin-vYWA5pvhYCsV_color);
  font-size: var(--mixin-vYWA5pvhYCsV_font-size);
  font-weight: var(--mixin-vYWA5pvhYCsV_font-weight);
  line-height: var(--mixin-vYWA5pvhYCsV_line-height);
}

:where(.root_reset) a:where(.a),
a:where(.root_reset.a),
:where(.root_reset .__wab_expr_html_text) a,
:where(.root_reset_tags) a,
a:where(.root_reset_tags) {
  color: var(--mixin-obEREfZ-hqhV_color);
}

:where(.root_reset) h3:where(.h3),
h3:where(.root_reset.h3),
:where(.root_reset .__wab_expr_html_text) h3,
:where(.root_reset_tags) h3,
h3:where(.root_reset_tags) {
  color: var(--mixin-AV-7oTbRvWHc_color);
  font-size: var(--mixin-AV-7oTbRvWHc_font-size);
  font-weight: var(--mixin-AV-7oTbRvWHc_font-weight);
  line-height: var(--mixin-AV-7oTbRvWHc_line-height);
}

:where(.root_reset) h4:where(.h4),
h4:where(.root_reset.h4),
:where(.root_reset .__wab_expr_html_text) h4,
:where(.root_reset_tags) h4,
h4:where(.root_reset_tags) {
  color: var(--mixin-YbJI1zthX8YU_color);
  font-size: var(--mixin-YbJI1zthX8YU_font-size);
  font-weight: var(--mixin-YbJI1zthX8YU_font-weight);
  line-height: var(--mixin-YbJI1zthX8YU_line-height);
}

:where(.root_reset) code:where(.code),
code:where(.root_reset.code),
:where(.root_reset .__wab_expr_html_text) code,
:where(.root_reset_tags) code,
code:where(.root_reset_tags) {
  background: #f8f8f8;
  font-family: var(--mixin-q_6YsWqxtsyr_font-family);
  border-radius: var(--mixin-q_6YsWqxtsyr_border-top-left-radius)
    var(--mixin-q_6YsWqxtsyr_border-top-right-radius)
    var(--mixin-q_6YsWqxtsyr_border-bottom-right-radius)
    var(--mixin-q_6YsWqxtsyr_border-bottom-left-radius);
  padding: var(--mixin-q_6YsWqxtsyr_padding-top)
    var(--mixin-q_6YsWqxtsyr_padding-right)
    var(--mixin-q_6YsWqxtsyr_padding-bottom)
    var(--mixin-q_6YsWqxtsyr_padding-left);
  border-top: var(--mixin-q_6YsWqxtsyr_border-top-width)
    var(--mixin-q_6YsWqxtsyr_border-top-style)
    var(--mixin-q_6YsWqxtsyr_border-top-color);
  border-right: var(--mixin-q_6YsWqxtsyr_border-right-width)
    var(--mixin-q_6YsWqxtsyr_border-right-style)
    var(--mixin-q_6YsWqxtsyr_border-right-color);
  border-bottom: var(--mixin-q_6YsWqxtsyr_border-bottom-width)
    var(--mixin-q_6YsWqxtsyr_border-bottom-style)
    var(--mixin-q_6YsWqxtsyr_border-bottom-color);
  border-left: var(--mixin-q_6YsWqxtsyr_border-left-width)
    var(--mixin-q_6YsWqxtsyr_border-left-style)
    var(--mixin-q_6YsWqxtsyr_border-left-color);
}

:where(.root_reset) blockquote:where(.blockquote),
blockquote:where(.root_reset.blockquote),
:where(.root_reset .__wab_expr_html_text) blockquote,
:where(.root_reset_tags) blockquote,
blockquote:where(.root_reset_tags) {
  color: var(--mixin-F7Oh7MbTsnw1_color);
  padding-left: var(--mixin-F7Oh7MbTsnw1_padding-left);
  border-left: var(--mixin-F7Oh7MbTsnw1_border-left-width)
    var(--mixin-F7Oh7MbTsnw1_border-left-style)
    var(--mixin-F7Oh7MbTsnw1_border-left-color);
}

:where(.root_reset) pre:where(.pre),
pre:where(.root_reset.pre),
:where(.root_reset .__wab_expr_html_text) pre,
:where(.root_reset_tags) pre,
pre:where(.root_reset_tags) {
  background: #f8f8f8;
  font-family: var(--mixin--V3Ybxm1QANw_font-family);
  border-radius: var(--mixin--V3Ybxm1QANw_border-top-left-radius)
    var(--mixin--V3Ybxm1QANw_border-top-right-radius)
    var(--mixin--V3Ybxm1QANw_border-bottom-right-radius)
    var(--mixin--V3Ybxm1QANw_border-bottom-left-radius);
  padding: var(--mixin--V3Ybxm1QANw_padding-top)
    var(--mixin--V3Ybxm1QANw_padding-right)
    var(--mixin--V3Ybxm1QANw_padding-bottom)
    var(--mixin--V3Ybxm1QANw_padding-left);
  border-top: var(--mixin--V3Ybxm1QANw_border-top-width)
    var(--mixin--V3Ybxm1QANw_border-top-style)
    var(--mixin--V3Ybxm1QANw_border-top-color);
  border-right: var(--mixin--V3Ybxm1QANw_border-right-width)
    var(--mixin--V3Ybxm1QANw_border-right-style)
    var(--mixin--V3Ybxm1QANw_border-right-color);
  border-bottom: var(--mixin--V3Ybxm1QANw_border-bottom-width)
    var(--mixin--V3Ybxm1QANw_border-bottom-style)
    var(--mixin--V3Ybxm1QANw_border-bottom-color);
  border-left: var(--mixin--V3Ybxm1QANw_border-left-width)
    var(--mixin--V3Ybxm1QANw_border-left-style)
    var(--mixin--V3Ybxm1QANw_border-left-color);
}

:where(.root_reset) ul:where(.ul),
ul:where(.root_reset.ul),
:where(.root_reset .__wab_expr_html_text) ul,
:where(.root_reset_tags) ul,
ul:where(.root_reset_tags) {
  display: var(--mixin-MM2MflRJ8osJ_display);
  flex-direction: var(--mixin-MM2MflRJ8osJ_flex-direction);
  align-items: var(--mixin-MM2MflRJ8osJ_align-items);
  justify-content: var(--mixin-MM2MflRJ8osJ_justify-content);
  list-style-position: var(--mixin-MM2MflRJ8osJ_list-style-position);
  padding-left: var(--mixin-MM2MflRJ8osJ_padding-left);
  position: var(--mixin-MM2MflRJ8osJ_position);
  list-style-type: var(--mixin-MM2MflRJ8osJ_list-style-type);
  flex-column-gap: var(--mixin-MM2MflRJ8osJ_flex-column-gap);
}

:where(.root_reset) ol:where(.ol),
ol:where(.root_reset.ol),
:where(.root_reset .__wab_expr_html_text) ol,
:where(.root_reset_tags) ol,
ol:where(.root_reset_tags) {
  display: var(--mixin-64xIbNaxUz5C_display);
  flex-direction: var(--mixin-64xIbNaxUz5C_flex-direction);
  align-items: var(--mixin-64xIbNaxUz5C_align-items);
  justify-content: var(--mixin-64xIbNaxUz5C_justify-content);
  list-style-position: var(--mixin-64xIbNaxUz5C_list-style-position);
  padding-left: var(--mixin-64xIbNaxUz5C_padding-left);
  position: var(--mixin-64xIbNaxUz5C_position);
  list-style-type: var(--mixin-64xIbNaxUz5C_list-style-type);
  flex-column-gap: var(--mixin-64xIbNaxUz5C_flex-column-gap);
}

:where(.root_reset) h5:where(.h5),
h5:where(.root_reset.h5),
:where(.root_reset .__wab_expr_html_text) h5,
:where(.root_reset_tags) h5,
h5:where(.root_reset_tags) {
  color: var(--mixin-4CzimzeZHPaI_color);
  font-size: var(--mixin-4CzimzeZHPaI_font-size);
  font-weight: var(--mixin-4CzimzeZHPaI_font-weight);
  line-height: var(--mixin-4CzimzeZHPaI_line-height);
}

:where(.root_reset) h6:where(.h6),
h6:where(.root_reset.h6),
:where(.root_reset .__wab_expr_html_text) h6,
:where(.root_reset_tags) h6,
h6:where(.root_reset_tags) {
  color: var(--mixin-ASao-qfdExlD_color);
  font-size: var(--mixin-ASao-qfdExlD_font-size);
  font-weight: var(--mixin-ASao-qfdExlD_font-weight);
  line-height: var(--mixin-ASao-qfdExlD_line-height);
}

:where(.root_reset) a:where(.a):hover,
a:where(.root_reset.a):hover,
:where(.root_reset .__wab_expr_html_text) a:hover,
:where(.root_reset_tags) a:hover,
a:where(.root_reset_tags):hover {
  color: var(--mixin-p8KmnJGhFT9z_color);
}

:where(.root_reset) li:where(.li),
li:where(.root_reset.li),
:where(.root_reset .__wab_expr_html_text) li,
:where(.root_reset_tags) li,
li:where(.root_reset_tags) {
}

:where(.root_reset) p:where(.p),
p:where(.root_reset.p),
:where(.root_reset .__wab_expr_html_text) p,
:where(.root_reset_tags) p,
p:where(.root_reset_tags) {
}
