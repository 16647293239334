.hubTitleContainer {
  display: flex;
  width: 100%;
  height: auto;
  position: relative;
  min-width: 0;
}
.hubTitleContainer > :global(.__wab_flex-container) {
  justify-content: center;
  align-items: center;
  align-content: unset;
  min-width: 0;
  margin-left: calc(0px - 0px);
  width: calc(100% + 0px);
}
.hubTitleContainer > :global(.__wab_flex-container) > *,
.hubTitleContainer > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.hubTitleContainer > :global(.__wab_flex-container) > picture > img,
.hubTitleContainer
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 0px;
}
.inputComponent:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: auto;
  display: none;
}
.inputComponentisEditing:global(.__wab_instance) {
  display: flex;
}
.inputComponentisHoveredAsTitle:global(.__wab_instance) {
  width: auto;
}
.svg__tc1DL {
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
  max-width: 80%;
}
.svg___75GFm {
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
  max-width: 80%;
  margin: 2px 0px 0px;
}
.titleContainer {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: auto;
  height: auto;
  max-width: 100%;
  margin-left: calc(0px + 0px) !important;
  margin-top: 0px;
  margin-right: 0px;
  margin-bottom: 0px;
  padding: 0px;
}
.titleContainerisEditing {
  margin-left: calc(0px + 0px) !important;
}
.titleContainerisHoveredAsTitle {
  width: auto;
  margin-left: calc(30px + 0px) !important;
  padding: 0px 20px;
}
.titleContainerisNotShownInList {
  padding-left: 20px;
  padding-right: 20px;
  margin-left: calc(30px + 0px) !important;
}
.hubTitleContainerisNotShownInList:hover .titleContainerisNotShownInList {
  padding-left: 20px;
  padding-right: 20px;
  margin-right: 0px;
  margin-left: calc(30px + 0px) !important;
  justify-content: center;
  align-items: center;
}
.slotTargetHubTitle {
  font-family: "Open Sans", sans-serif;
  font-size: 1.3em;
  font-weight: 600;
  color: var(--token-IG9ne-3Mr);
  text-align: center;
  line-height: 1.4em;
  letter-spacing: 0px;
  white-space: pre-wrap;
}
.hubTitleContainer:hover .slotTargetHubTitle {
  color: var(--token-pMUHtTtb1);
}
.hubTitleContainerisNotShownInList:hover .slotTargetHubTitleisNotShownInList {
  color: var(--token-IG9ne-3Mr);
}
.editTitleButton:global(.__wab_instance) {
  position: relative;
  margin-top: 0.2em;
  transition-property: opacity;
  transition-duration: 0.3s;
  transition-timing-function: ease-in;
  -webkit-transition-property: opacity;
  -webkit-transition-timing-function: ease-in;
  -webkit-transition-duration: 0.3s;
}
.editTitleButtonisHoveredAsTitle:global(.__wab_instance) {
  position: sticky;
  left: auto;
  top: auto;
  flex-grow: 0;
  z-index: 1;
  right: 0px;
  width: auto;
  height: 100%;
  margin-left: calc(0px + 0px) !important;
  margin-right: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
  min-height: 0;
}
.editTitleButtonisNotShownInList:global(.__wab_instance) {
  opacity: 0;
  margin-top: 0em;
  margin-right: 0em;
  margin-bottom: 0em;
  margin-left: calc(0em + 0px) !important;
}
.hubTitleContainerisNotShownInList:hover
  .editTitleButtonisNotShownInList:global(.__wab_instance) {
  margin-top: 0em;
  margin-right: 0px;
  margin-bottom: 0px;
  margin-left: calc(0px + 0px) !important;
  left: auto;
  top: auto;
  z-index: 1;
  position: relative;
  opacity: 1;
}
